import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  @Input() fileType;
  @Input() editMode = false;
  @Output() selectedFiles = new EventEmitter<any[]>();
  @Input() files: any[] = [];
  @Input() oldFile: any = {};
  newFile: any = {};
  constructor(private snackBar: MatSnackBar) { }

  ngOnInit() {
    
  }

  /**
   * on file drop handler
   */
   onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    if(this.editMode){
      this.prepareEditFile(files[0]);
    }else {
      this.prepareFilesList(files);
    }
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
   prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      item.uploaded = false;
      this.files.push(item);
    }
    this.selectedFiles.emit(this.files);
  }

  prepareEditFile(newFile){
    if(this.oldFile.name.toLowerCase() !== newFile.name.toLocaleLowerCase()){
      this.snackBar.open("Please upload same file name, while editing.", "OK", {
        duration: 3000,
      });
      return
    }
    this.newFile.progress = 0;
    this.newFile.uploaded = false;
    this.newFile = newFile;
    this.selectedFiles.emit([this.newFile]);
  }

  prepareFile(file) {
    file.progress = 100;
    file.uploaded = true;
    this.files.push(file);
    this.oldFile = file;
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
   formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

}
