import { MouseEvent } from '@agm/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, SiteOperativeService } from 'src/services-generated';
import { LocationUpdateModel } from 'src/services-generated/model/locationUpdateModel';
import f from 'odata-filter-builder';
import {Moment} from 'moment';
import * as moment from 'moment';
import { InputPageModel, InputPageSectionModel, InputPageService } from 'src/report-services-generated';

@Component({
  selector: 'app-weekly-data',
  templateUrl: './weekly-data.component.html',
  styleUrls: ['./weekly-data.component.scss']
})
export class WeeklyDataComponent implements OnInit {
  loading = false;
  dateRange = new FormControl();
  pageData: InputPageModel= {};
  laggingTypeFields:any[] = [];
  leadingTypeFields:any[] = [];
  requestBody:any= {};
  dateRangeSelected: {startDate: Moment, endDate: Moment} = {
    startDate: moment().add(-1, 'days'),
    endDate: moment()
  }
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  lat = 51.864722;
  long = -6.250650;
  siteOperatives = []
  markers = [];
  siteOperative = new FormControl('', Validators.required);
  error = '';
  firstName = new FormControl();
  sentinelNumber = new FormControl();
  odataQuery = '?$filter=' +  f.and().contains('role/name', 'SiteOperative').toString();
  constructor(private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private inputPageService: InputPageService) { }

  ngOnInit() {
    this.inputPageService.apiInputPageIdGet(1).subscribe(pageResponse => {
      console.log(pageResponse);
      this.pageData = pageResponse;

      this.laggingTypeFields = this.filterSections("Lagging");
      this.leadingTypeFields = this.filterSections("Leading");
    })
  }

  logRequestBody(){
    console.log(this.requestBody)
  }

  filterSections(indicatorType){
    let sections = [];
    this.pageData.sections.forEach(section => {
      let filteredFields = [];
      filteredFields = section.fields.filter(field => {
        return field.indicatorType == indicatorType;
      })
      
      if(filteredFields.length > 0){
        let filteredSection:InputPageSectionModel = {};
        filteredSection.fields = filteredFields;
        filteredSection.name = section.name;
        filteredSection.sequenceNumber = section.sequenceNumber;
        sections.push(filteredSection);
      }
    })
    return sections;
  }

  onSubmit() {
    let fields = [];
    Object.keys(this.requestBody).forEach(fieldId => {
      if(this.requestBody[fieldId]){
        fields.push({
          id: parseInt(fieldId),
          value: this.requestBody[fieldId]
        })
      }
      else{
        fields.push({
          id: parseInt(fieldId),
          value: 0
        })
      }
    });
    let startDateTimeAdjusted = this.dateRangeSelected.startDate.toDate()
    startDateTimeAdjusted.setTime(startDateTimeAdjusted .getTime() - (startDateTimeAdjusted.getTimezoneOffset()*60*1000))
    this.inputPageService.apiInputPageIdRecordPost(1,{
      fields:fields,
      date: startDateTimeAdjusted.toISOString().split('T')[0]
    }).subscribe(fieldSubmitResponse => {
      console.log(fieldSubmitResponse);
      if(fieldSubmitResponse.isSuccessful){
        this.snackBar.open("Success!","Ok",{
          duration:3000
        });
      }
      else{
        this.snackBar.open(fieldSubmitResponse.message,"Ok",{
          duration:3000
        });
      }
    })
  }

  handleFilterChange(){
    let filterString = f.and()
      .contains('firstName' , this.firstName.value ? this.firstName.value : "")
      .contains('siteOperativeDetail/sentinelNumber' , this.sentinelNumber.value? this.sentinelNumber.value : "")
      .toString();
    this.odataQuery = this.odataQuery + ' and ' + filterString
    if(filterString == ""){
      this.odataQuery = "";
    }
    this.ngOnInit();
  }
}
