import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AgmCoreModule } from "@agm/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FlexLayoutModule } from "@angular/flex-layout";
import { AppRoutingModule } from "./app.routing";
import { WorksiteTrackApiModule } from "src/services-generated";
import { MaterialModule } from "./app.material-module";
import { AppComponent } from "./app.component";
import { JwtInterceptor, ErrorInterceptor } from "./_helpers";
import { HomeComponent } from "./home";
import { AdminComponent } from "./admin-pages/admin";
import { LoginComponent } from "./login/login.component";
import { AdminPagesComponent } from "./admin-pages/admin-pages.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { SiteOperativeComponent } from "./admin-pages/site-operative/site-operative.component";
import { SiteOperativeDetailComponent } from "./admin-pages/site-operative/site-operative-detail/site-operative-detail.component";
import { DndDirective } from "./_helpers/dnd.directive";
import { SiteOperativeBulkComponent } from "./admin-pages/site-operative/site-operative-bulk/site-operative-bulk.component";
import { ProgressComponent } from "./progress/progress.component";
import { SiteOperativeListComponent } from "./admin-pages/site-operative/site-operative-list/site-operative-list.component";
import { SafetyDocumentListComponent } from "./admin-pages/safety-documents/safety-document-list/safety-document-list.component";
import { SafetyDocumentDetailComponent } from "./admin-pages/safety-documents/safety-document-detail/safety-document-detail.component";
import { SecurityQuestionDetailComponent } from "./admin-pages/security-questions/security-question-detail/security-question-detail.component";
import { SecurityQuestionListComponent } from "./admin-pages/security-questions/security-question-list/security-question-list.component";
import { SupervisorDetailComponent } from "./admin-pages/supervisor/supervisor-detail/supervisor-detail.component";
import { SupervisorListComponent } from "./admin-pages/supervisor/supervisor-list/supervisor-list.component";
import { AdminDetailComponent } from "./admin-pages/admin/admin-detail/admin-detail.component";
import { AdminListComponent } from "./admin-pages/admin/admin-list/admin-list.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { SupervisorPagesComponent } from "./supervisor-pages/supervisor-pages.component";
import { LiveComponent } from "./supervisor-pages/live/live.component";
import { SupervisorHomeComponent } from "./supervisor-pages/supervisor-home/supervisor-home.component";
import { DashboardComponent } from "./supervisor-pages/dashboard/dashboard.component";
import { HistoryComponent } from "./supervisor-pages/history/history.component";
import { LiveCloseCallComponent } from "./supervisor-pages/live-close-call/live-close-call.component";
import { HistoryCloseCallComponent } from "./supervisor-pages/history-close-call/history-close-call.component";
import { SiteOperativeLoginComponent } from "./supervisor-pages/siteoperative-login/siteoperative-login.component";
import { SiteOperativeLogoutComponent } from "./supervisor-pages/siteoperative-logout/siteoperative-logout.component";
import { DatePipe } from "@angular/common";
import { SiteOperativeProxyListComponent } from "./supervisor-pages/siteoperative-proxy-list/siteoperative-proxy-list.component";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { AssignCloseCallComponent } from "./supervisor-pages/live-close-call/assign-close-call/assign-close-call.component";
import { ResolveCloseCallComponent } from "./supervisor-pages/live-close-call/resolve-close-call/resolve-close-call.component";
import { CloseCallActionComponent } from "./supervisor-pages/live-close-call/close-call-action/close-call-action.component";
import { HistoryLoginComponent } from "./supervisor-pages/history-login/history-login.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ReportHomeComponent } from "./report-pages/report-home/report-home.component";
import { ReportPagesComponent } from "./report-pages/report-pages.component";
import { ReportDashboardComponent } from "./report-pages/dashboard/report-dashboard.component";
import { WeeklyDataComponent } from "./report-pages/weekly-data/weekly-data.component";
import { MonthlyDataComponent } from "./report-pages/monthly-data/monthly-data.component";
import { MomentDatePipe } from "./_helpers/datepipe.timezone";
import { EllipsisPipe } from "./_helpers/ellipsis.pipe";
import { BoolStrPipe } from "./_helpers/boolstr.pipe";
import { LoginAttempFailuresListComponent } from "./supervisor-pages/login-attempt-failures/login-attempt-failures-list.component";
import { ReportsApiModule } from "src/report-services-generated/ReportsApiModule";
import { WeeklyDataViewComponent } from "./report-pages/weekly-data-view/weekly-data-view.component";
import { MonthlyDataViewComponent } from "./report-pages/monthly-data-view/monthly-data-view.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material";
import { CreateDashboardComponent } from "./report-pages/create-dashboard/create-dashboard.component";
import { GridsterModule } from "angular-gridster2";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { ChartsModule } from "ng2-charts";
import { ViewDashboardComponent } from "./report-pages/view-dashboard/view-dashboard.component";
import { CompareSameIndicatorComponent } from "./report-pages/compare-same-indicator/compare-same-indicator.component";
import { CompareDifferentIndicatorComponent } from "./report-pages/compare-different-indicator/compare-different-indicator.component";
import { UmbrellaPackApiModule } from "src/umbrella-pack-services-generated";
import { HrPagesComponent } from "./hr-pages/hr-pages";
import { HrHomeComponent } from "./hr-pages/hr-home/hr-home.component";
import { ApplicantListComponent } from "./hr-pages/applicant/applicant-list/applicant-list.component";
import { ApplicantDetailComponent } from "./hr-pages/applicant/applicant-detail/applicant-detail.component";
import { iOSAppComponent } from "./ios-app/ios-app.component";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { SiteOperativePasswordComponent } from "./admin-pages/site-operative/site-operative-password/site-operative-password.component";
import { UploadComponent } from "./upload/upload.component";
import { SiteOperativeCertificateAddComponent } from "./admin-pages/site-operative/site-operative-certificate-add/site-operative-certificate-add.component";
import { RuleBookDetailComponent } from "./admin-pages/rule-book/rule-book-detail/rule-book-detail.component";
import { RuleBookListComponent } from "./admin-pages/rule-book/rule-book-list/rule-book-list.component";
import { RuleBookUserListComponent } from "./supervisor-pages/rule-book-user-list/rule-book-user-list.component";
import { RuleBookUserDetailsListComponent } from "./supervisor-pages/rule-book-user-details-list/rule-book-user-details-list.component";
import { TagsModalComponent } from "./admin-pages/tags-modal/tags-modal.component";
import { RuleBookTagsComponent } from "./admin-pages/rule-book/rule-book-tags/rule-book-tags.component";
import { TagFormModalComponent } from "./admin-pages/tag-form-modal/tag-form-modal.component";
import { VehicleLogComponent } from "./supervisor-pages/vehicle-log/vehicle-log.component";
import { ViewDetailVehicleComponent } from "./supervisor-pages/view-detail-vehicle/view-detail-vehicle.component";
import { SitFamiliarisationComponent } from "./supervisor-pages/sit-familiarisation/sit-familiarisation.component";
import { SiteFamiliarisationPostComponent } from "./supervisor-pages/site-familiarisation-post/site-familiarisation-post.component";
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';;
import { AddNewSiteComponent } from './supervisor-pages/add-new-site/add-new-site.component'
;
import { SiteListsComponent } from './supervisor-pages/site-lists/site-lists.component'
;
import { AddNewEditSiteComponent } from './supervisor-pages/add-new-edit-site/add-new-edit-site.component'
import { NoticeBoardDetailComponent } from "./admin-pages/notice-board/notice-board-detail/notice-board-detail.component";
import { NoticeBoardDocumentListComponent } from "./admin-pages/notice-board/notice-board-list/notice-board-list.component";
import { SupervisorResetPasswordComponent } from './admin-pages/supervisor/supervisor-reset-password/supervisor-reset-password.component';
import { AdminResetPasswordComponent } from "./admin-pages/admin/admin-reset-password/admin-reset-password.component";
@NgModule({
  imports: [
   NgxMaterialTimepickerModule,
    BrowserModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBI5odAKvR_gb2gw7D68I6FfnswHgJ8JEk",
    }),
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    FlexLayoutModule,
    AppRoutingModule,
    WorksiteTrackApiModule,
    MaterialModule,
    DragDropModule,
    NgxDaterangepickerMd.forRoot(),
    ReportsApiModule,
    MatDatepickerModule,
    MatNativeDateModule,
    GridsterModule,
    MatAutocompleteModule,
    ChartsModule,
    UmbrellaPackApiModule
  ],
  declarations: [
    DndDirective,
    AppComponent,
    HomeComponent,
    AdminComponent,
    LoginComponent,
    ForgotPasswordComponent,
    AdminPagesComponent,
    SiteOperativeComponent,
    SiteOperativeDetailComponent,
    SiteOperativePasswordComponent,
    SiteOperativeBulkComponent,
    SiteOperativeListComponent,
    SafetyDocumentDetailComponent,
    SafetyDocumentListComponent,
    SecurityQuestionDetailComponent,
    SecurityQuestionListComponent,
    SupervisorDetailComponent,
    SupervisorListComponent,
    AdminDetailComponent,
    AdminListComponent,
    PrivacyPolicyComponent,
    ProgressComponent,
    SupervisorPagesComponent,
    LiveComponent,
    SupervisorHomeComponent,
    DashboardComponent,
    HistoryComponent,
    LiveCloseCallComponent,
    HistoryCloseCallComponent,
    SiteOperativeLoginComponent,
    SiteOperativeLogoutComponent,
    SiteOperativeProxyListComponent,
    AssignCloseCallComponent,
    ResolveCloseCallComponent,
    CloseCallActionComponent,
    HistoryLoginComponent,
    ResetPasswordComponent,
    HistoryLoginComponent,
    ReportPagesComponent,
    ReportHomeComponent,
    ReportDashboardComponent,
    WeeklyDataComponent,
    MonthlyDataComponent,
    LoginAttempFailuresListComponent,
    WeeklyDataViewComponent,
    MonthlyDataViewComponent,
    CreateDashboardComponent,
    ViewDashboardComponent,
    CompareSameIndicatorComponent,
    CompareDifferentIndicatorComponent,
    HrPagesComponent,
    HrHomeComponent,
    ApplicantListComponent,
    ApplicantDetailComponent,
    iOSAppComponent,
    EllipsisPipe,
    BoolStrPipe,
    UploadComponent,
    SiteOperativeCertificateAddComponent,
    RuleBookDetailComponent,
    RuleBookListComponent,
    RuleBookUserListComponent,
    RuleBookUserDetailsListComponent,
    RuleBookTagsComponent,
    TagFormModalComponent,
    VehicleLogComponent,
    ViewDetailVehicleComponent,
    SitFamiliarisationComponent,
    SiteFamiliarisationPostComponent,
    TagsModalComponent,
    AddNewSiteComponent,
    SiteListsComponent,
    AddNewEditSiteComponent,
    NoticeBoardDetailComponent,
    NoticeBoardDocumentListComponent,
    MomentDatePipe,
    SupervisorResetPasswordComponent,
    AdminResetPasswordComponent
  ],

  entryComponents: [TagFormModalComponent, TagsModalComponent],

  providers: [
    DatePipe,
    MomentDatePipe,
    EllipsisPipe,
    BoolStrPipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
