import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SiteFamilarisationsService } from 'src/services-generated/api/site-familarisations.service';
import { Observable } from 'rxjs';
import { catchError, map, startWith } from "rxjs/operators";
import { SiteFamiliarisationPostModelResponse } from 'src/services-generated/model/siteFamiliarisationPostResultResponse';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SiteApiService } from 'src/services-generated/api/site-api.service';
import { siteModel } from 'src/services-generated/model/siteModel';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
export interface PeriodicElement {
  name: string;
  location: String;
  secondaryLocation: string;
  postalCode: string;
}
@Component({
  selector: 'app-site-lists',
  templateUrl: './site-lists.component.html',
  styleUrls: ['./site-lists.component.scss']
})

export class SiteListsComponent implements OnInit {
  sites: siteModel[] = [];
  SiteLists: any[] = [];
  dataSource2 = new MatTableDataSource<any>(this.SiteLists);
  siteOperative: any;
  filteredSiteOperatives: Observable<string[]>;
  siteOperatives = [];
  displayedColumns: string[] = ['name', 'location', 'secondaryLocation', 'postalCode', 'action'];
  private _snackbar: any;
  constructor(private siteService: SiteApiService, private router: Router) { }

  ngOnInit() {

    this.siteService.apiSiteFamiliarisationGet().subscribe((SiteOperativeResponse) => {
      console.log("Lists:", SiteOperativeResponse);
      this.SiteLists = SiteOperativeResponse;
      console.log("SiteLists:", this.SiteLists);
      this.dataSource2.data = SiteOperativeResponse;
    });
  }

  DeleteSite(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.siteService.apiSiteIdDelete(id).subscribe(
          (response: any) => {
            console.log(response);
            // Check if the deletion was successful
            if (response.IsSuccessful = true) {
              Swal.fire({
                icon: 'success',
                title: 'Site deleted successfully',
                text: response["Message"],
              }).then((res) => {
                // Fetch the updated table data and update the table
                this.siteService.apiSiteFamiliarisationGet().subscribe(
                  (response: any) => {
                    console.log(response);
                    // Update the table data with the new data
                    this.SiteLists = response;
                  }
                );
              });
            }
            else {
              Swal.fire({
                icon: 'error',
                title: 'Site deleted failed!',
                text: response["Message"],
              })
            }
          }
        );
      }
    })

  }
  EditSiteDetails(element: any) {
    localStorage.setItem('selectedSiteDetails', JSON.stringify(element))
    this.router.navigate(['../supervisor/EditAddSite']);
  }

}
