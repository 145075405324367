import { Component, OnInit } from "@angular/core";
import { FormBuilder,FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountService, AdministratorService } from "src/services-generated";
import { Role } from "@app/_models";
import { MatTableDataSource } from "@angular/material";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ModuleService } from '@app/_services/module.service';
import { UserModule } from "src/services-generated/model/UserModule.Model";
import { UserPasswordResetRequestModel } from "src/services-generated/model/userPasswordResetRequestModel";


@Component({
  selector: "app-admin-reset-password",
  templateUrl: "./admin-reset-password.component.html",
  styleUrls: ["./admin-reset-password.component.scss"],
})
export class AdminResetPasswordComponent implements OnInit {
  loading = false;
  editMode = true;
  adminId = null;
  modules: any[];
  userModules : Array<UserModule> =[];
  adminForm: FormGroup;
  dataSource: MatTableDataSource<any>;
  error = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private adminService: AdministratorService,
    private accountService: AccountService,
    private snackBar: MatSnackBar,

    private moduleService: ModuleService
  ) {}

  ngOnInit() {
    this.adminForm = this.formBuilder.group({
      email: [{value: '', disabled:true}, [Validators.required, Validators.email]],
      phoneNumber: [{value: '', disabled:true}, [Validators.required, Validators.pattern("^[0-9]*$")]],
      firstName: [{value: '', disabled:true}, Validators.required],
      lastName: [{value: '', disabled:true}],
      password: ["", Validators.required],
      role: [Role.Admin],
    });
    if (this.route.snapshot.paramMap.get("id")) {
      this.adminId = this.route.snapshot.paramMap.get("id");
      this.accountService
        .apiAccountIdGet(parseInt(this.adminId))
        .subscribe((adminResponse) => {
          let adminData = adminResponse.result;
          this.adminForm.setValue({
            email: adminData.email,
            phoneNumber: adminData.phoneNumber,
            firstName: adminData.firstName,
            lastName: adminData.lastName,
            password: "",
            role: Role.Admin,
          });
          this.modules.forEach((element) => {
            var um = adminData.userModules.find(
              (x) => x.moduleId == element.id
            );
            element.isRead.setValue(um.isRead);
            element.isWrite.setValue(um.isWrite);
            element.isDownload.setValue(um.isDownload);
          });
        });
      this.editMode = true;
    }
  }


  get f() { return this.adminForm.controls; }

  onSubmit() {

    if (this.adminForm.invalid) {
      return;
  }
  console.log(this.adminForm.value);
  this.loading = true;
  let formValue = this.adminForm.value;
  let body: UserPasswordResetRequestModel = {
    password: formValue.password,
    userId: parseInt(this.adminId)
  }
  this.adminService.apiAdministratorResetUserPasswordPost(body)
  .subscribe(siteOperativeResponse => {
    this.loading = false;
    if(siteOperativeResponse.isSuccessful){
        this.snackBar.open(siteOperativeResponse.message, "OK", {
          duration: 3000,
        });
        this.router.navigate(['/admin']);
    } else{
      this.snackBar.open(siteOperativeResponse.message, "OK", {
        duration: 3000,
      });
    }
  },
  error => {
      console.log(error);
      this.snackBar.open(error, "OK", {
        duration: 3000,
      });
      this.loading = false;
  });

  }
}
