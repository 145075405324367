import { Component, OnInit } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { TypeScriptEmitter } from '@angular/compiler';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from '@app/_models';
import {AccountService, Document} from 'src/services-generated';
import { DocumentService } from 'src/services-generated/api/document.service';
import { RuleBookService } from 'src/services-generated/api/ruleBook.service';
import { MatDialog } from '@angular/material';
import { TagsModalComponent } from '@app/admin-pages/tags-modal/tags-modal.component';

@Component({
  selector: 'app-rule-book-detail',
  templateUrl: './rule-book-detail.component.html',
  styleUrls: ['./rule-book-detail.component.scss']
})
export class RuleBookDetailComponent implements OnInit {

  loading = false;
  files: any[] = [];
  oldFile: any = {};
  newFile: any = {};
  editMode = false;
  documentId = null;
  safetyDocuments = [];
  error = '';
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private documentService: RuleBookService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog) { }

  ngOnInit() {
    if(this.route.snapshot.paramMap.get('id')){
      this.editMode = true;
      this.documentId = this.route.snapshot.paramMap.get('id');
      this.documentService.apiRuleBookIdGet(this.documentId).subscribe(document => {
        this.prepareFile(document);
      })
    }
  }

  openDialog(): void {
    let dialogRef = this.dialog.open(TagsModalComponent, {
      width: '400px',
      data: {
        tags: this.files[0].tags,
        fileId:this.files[0].id
      },
    });
    dialogRef.afterClosed().subscribe(res => {
      if(res){
        this.files[0].tags = res
      }
    })
  }

  prepareFile(file) {
    file.progress = 100;
    file.uploaded = true;
    this.files.push(file);
    this.files = [...this.files];
    this.oldFile = file;
  }

  sendFilesToUpload(files) {
    this.files = [...files];
    this.uploadFiles();
  }

  /**
   * Upload the files
   */
  uploadFiles() {
    if(this.editMode){
      this.documentService.apiRuleBookIdPutForm(this.documentId, this.files[0], 'events', true).subscribe(documentEvent => {
        if (documentEvent.type === HttpEventType.DownloadProgress) {
          console.log("download progress");
          this.newFile.progress = documentEvent.loaded / documentEvent.total;
        }
        if (documentEvent.type === HttpEventType.Response) {
          if (documentEvent.body.isSuccessful) {
            this.newFile.progress = 100;
            this.newFile.uploaded = true;
            this.snackBar.open(documentEvent.body.message, "OK", {
              duration: 3000,
            });
          } else {
            this.newFile.progress = false;
            this.newFile.uploaded = false;
            this.snackBar.open(documentEvent.body.message, "OK", {
              duration: 3000,
            });
          }
        }
      })
    }else {
      this.files.forEach(file => {
        this.documentService.apiRuleBookPostForm(file, 'events', true).subscribe(documentEvent => {
          if (documentEvent.type === HttpEventType.DownloadProgress) {
            console.log("download progress");
            file.progress = documentEvent.loaded / documentEvent.total;
          }
          if (documentEvent.type === HttpEventType.Response) {
            if (documentEvent.body.isSuccessful) {
              file.progress = 100;
              file.uploaded = true;
              this.snackBar.open(documentEvent.body.message, "OK", {
                duration: 3000,
              });
            } else {
              file.progress = false;
              file.uploaded = false;
              this.snackBar.open(documentEvent.body.message, "OK", {
                duration: 3000,
              });
            }
          }
        })
      })
    }
  }


  bulkCreate(){
    console.log("Document upload called")

  }

}
