import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { TagFormModalComponent } from '@app/admin-pages/tag-form-modal/tag-form-modal.component';
import { TagService } from 'src/services-generated/api/tag.service';
import { Tag } from 'src/services-generated/model/tag';
import { TagExecutionResponse } from 'src/services-generated/model/tagExecutionResponse';
import { TagModel } from 'src/services-generated/model/tagModel';

@Component({
  selector: 'app-rule-book-tags',
  templateUrl: './rule-book-tags.component.html',
  styleUrls: ['./rule-book-tags.component.scss']
})
export class RuleBookTagsComponent implements OnInit {
  tags: Tag[] = [];
  constructor(private tagService: TagService, private dialog: MatDialog, private _snackbar: MatSnackBar) { }

  ngOnInit() {
    this.tagService.apiTagGet().subscribe(res => {
      this.tags = res.filter(tag => tag.isActive);
    })
  }

  openTagForm() {
    const dialogRef = this.dialog.open(TagFormModalComponent);
    dialogRef.afterClosed().subscribe((res:TagExecutionResponse) => {
      if (res.result) {
        this.tags.push(res.result);
      }
    });
  }
  openTagEditForm(tag: TagModel, index: number) {
    const dialogRef = this.dialog.open(TagFormModalComponent, { data: tag });
    dialogRef.afterClosed().subscribe((res:TagExecutionResponse) => {
      if (res.result) {
        this.tags[index] = res.result;
      }
    });
  }
  deleteTag(tag: Tag, index: number, event: Event) {
    event.stopPropagation();
    try {
      this.tagService.apiTagIdDelete(tag.id).subscribe(res => {
        this.tags.splice(index, 1);
      })
    }
    catch (e) {
      this._snackbar.open("Something went wrong! Unable to delete")
    }
  }

}
