import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/_services';
import { VehicleLogService } from 'src/services-generated/api/vehicleLog.service';
import { UserModule } from 'src/services-generated/model/UserModule.Model';
import { VehicleLog } from 'src/services-generated/model/vehicleLog';
import { VehicleLogModel } from 'src/services-generated/model/vehicleLogModel';

@Component({
  selector: 'app-vehicle-log',
  templateUrl: './vehicle-log.component.html',
  styleUrls: ['./vehicle-log.component.scss']
})
export class VehicleLogComponent implements OnInit {
  // selected = 'selectedvehilceId';
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  displayedColumns: string[] = ["id", "vehicleRegistration", "weekEnding", "driver", "pickupLocation", "pickupDateTime", "nextServiceDueMileage", "mileage", "vehicleLogDetails","action"];
  vehicleDetails: MatTableDataSource<VehicleLog> = new MatTableDataSource<VehicleLog>();
  userRights: UserModule;
  constructor( private vehicleService:VehicleLogService, private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.userRights = this.authenticationService.getUserRights("vehicle");
 
    this.vehicleService.apiVehicleLogGet().subscribe(response => {
      this.vehicleDetails.data = [...response];
      this.paginator.pageIndex = 0;
      this.vehicleDetails.paginator = this.paginator;
    
    })
  }

  viewDetails(element:any){
    localStorage.setItem('selectedOrder',JSON.stringify(element))
    this.router.navigate([`/supervisor/vehicle-edit1/${element.id}`]);
  }

}
