import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AccountService,
  AdministratorService,
  LoginModel,
  LoginResponseModel,
  PasswordResetRequestModel,
  SiteOperativeService,
  SupervisorService
} from 'src/services-generated';
import {Role} from "@app/_models";

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<LoginResponseModel>;
    public currentUser: Observable<LoginResponseModel>;

    constructor(private http: HttpClient, private administratorService: AdministratorService,
                private supervisorService: SupervisorService,
                private siteOperativeService: SiteOperativeService,
                private accountService: AccountService) {
        this.currentUserSubject = new BehaviorSubject<LoginResponseModel>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): LoginResponseModel {
        return this.currentUserSubject.value;
    }

  getUserRights(moduleName: string){
    return this.currentUserSubject.value['userModules'].find(x => x.moduleName.toLowerCase().includes(moduleName.toLowerCase()));
  }

  login(email: string, password: string, role: string) {
    let loginModel: LoginModel = {
      email: email,
      password: password
    }
    switch (role) {
      case Role.Admin:
        return this.administratorService.apiAdministratorLoginPost(loginModel)
          .pipe(map(user => {
            // login successful if there's a jwt token in the response
            if (user.isSuccessful) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('currentUser', JSON.stringify(user.result));
              this.currentUserSubject.next(user.result);
            }
            return user;
          }));
      case Role.HrAdmin:
        return this.administratorService.apiAdministratorLoginPost(loginModel)
          .pipe(map(user => {
            // login successful if there's a jwt token in the response
            if (user.isSuccessful) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('currentUser', JSON.stringify(user.result));
              this.currentUserSubject.next(user.result);
            }
            return user;
          }));
      default:
        return this.supervisorService.apiSupervisorLoginPost(loginModel)
          .pipe(map(user => {
            // login successful if there's a jwt token in the response
            if (user.isSuccessful) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              if(JSON.parse(atob(user.result.token.split('.')[1])).isReportUser === "True") {
                user.result.role = Role.ReportOperative;
              }
              localStorage.setItem('currentUser', JSON.stringify(user.result));
              this.currentUserSubject.next(user.result);
            }
            return user;
          }));
    }
  }


    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

    forgetPassword(email: string){
      let loginModel: LoginModel = {
        email: email,
      }
      return this.accountService.apiAccountForgotPasswordPost(email)
        .pipe(map(response => {
          console.log("forgetPassword reposnse: ", response);
          return response;
        }));
    }

    resetPassword(body: PasswordResetRequestModel){
      return this.accountService.apiAccountResetPasswordPost(body)
        .pipe(map(response => {
          console.log("resetPassword reposnse: ", response);
          // login successful if there's a jwt token in the response
          return response;
        }));
    }
}
