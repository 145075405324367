import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {CloseCall, CloseCallService, LocationService} from '../../../services-generated';
import {FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {environment} from '@environments/environment';
import {MatSelectChange} from '@angular/material/select';
import f from 'odata-filter-builder';
import * as XLSX from 'xlsx';
import {Observable, Subscription} from 'rxjs';
import {Moment} from 'moment';
import * as moment from 'moment';
import { AuthenticationService } from '@app/_services';
import { UserModule } from 'src/services-generated/model/UserModule.Model';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-history-close-call',
  templateUrl: './history-close-call.component.html',
  styleUrls: ['./history-close-call.component.scss']
})
export class HistoryCloseCallComponent implements OnInit, OnDestroy{
  loading = false;
  stateLoading = false;
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  displayedColumns: string[] = [ "title", "location", "occuredAt", "reportedBy", "reportedAt", "assignedTo", "assignedBy", "assignedAt", "actions", "resolvedBy", "resolvedAt", "resolution"];
  closeCalls: MatTableDataSource<CloseCall> = new MatTableDataSource<CloseCall>();
  apiCloseCallGetCountSubs: Subscription;
  closeCallCount:Number;
  lat = 55.861606;
  lng = -4.250313;
  country = new FormControl();
  state = new FormControl([]);
  city = new FormControl();
  dateRange = new FormControl();
  time = new FormControl();
  title = new FormControl();
  description = new FormControl();
  reportedBy = new FormControl();
  assignedTo = new FormControl();
  dateRangeSelected: {startDate: Moment, endDate: Moment} = {
    startDate: moment().add(-1, 'days'),
    endDate: moment()
  }
  countries = ['UK'];
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  states = [];
  cities = [];
  filteredStates: Observable<string[]>;
  odataQuery = "?$filter=" + f.and().lt('reportedAt', this.dateRangeSelected.endDate.toISOString(), false)
  .gt('reportedAt', this.dateRangeSelected.startDate.toISOString(), false);
  locationOdataQuery = "?$top=1000&$format=json&$orderby=createdAt desc&$filter=" + f.and().ne('city', null, false)
  .ne('state', null, false);
  markers:any[] = [];
  userRights: UserModule;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private closeCallService: CloseCallService,
    private locationService: LocationService,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.country.setValue('UK');
    this.userRights = this.authenticationService.getUserRights("history");
    this.loading = true;
    this.closeCallService.apiCloseCallHistoryGet(this.odataQuery).subscribe((closeCallResponse)=>{
      this.loading = false;
      this.closeCalls.data = [...closeCallResponse];
      console.log(this.closeCalls.data)
      if(closeCallResponse.length > 0) {
        this.markers = closeCallResponse.map(closeCall => {
          return {
            lat: closeCall.location.latitude,
            lng: closeCall.location.longitude,
            label: closeCall.location.id,
            draggable: false,
            title: closeCall.title,
            description: closeCall.description,
            reportedBy: closeCall.reportedBy
          }
        });
        this.lat = this.markers[this.markers.length-1].lat;
        this.lng = this.markers[this.markers.length-1].lng;
        this.paginator.pageIndex = 0;
        this.closeCalls.paginator = this.paginator;
      }
    });
    this.apiCloseCallGetCountSubs = this.closeCallService.apiCloseCallCountGet().subscribe((closeCallCount)=>{
      this.closeCallCount = closeCallCount.createdToday;
    });
    this.filteredStates = this.state.valueChanges.pipe(
      switchMap(async (value) => await this._filter(value))
    );
  }

  private async _filter(value: any): Promise<string[]> {
    if(typeof(value) != 'string') {
      value = "";
    }
    const filterValue = value.toLowerCase();
    this.stateLoading = true;
    this.locationOdataQuery = "?$top=1000&$format=json&$orderby=createdAt desc&$filter=" + f.and().ne('city', null, false)
    .ne('state', null, false) + `and contains(state, '${filterValue}')`
    let locations = await this.locationService.apiLocationGet(this.locationOdataQuery).toPromise();
    this.stateLoading = false;
    if(locations) {
      this.states = [...new Set(locations.map(location => location.state))];
      this.cities = [...new Set(locations.filter(location =>
        {
          if(this.state.value.length != 0){
            return this.state.value.includes(location.state)
          } else {
            return true;
          }
        }
      ).map(location => location.city))];
      return this.states.filter(state => state.toLowerCase().includes(filterValue));
    }
  }

  ngOnDestroy() {
    this.apiCloseCallGetCountSubs.unsubscribe();
  }

  handleStateChange(event: MatSelectChange){
    console.log(this.state.value);
    let filterString = f('and')
      .in('location/state', this.state.value)
      .in('location/city', this.city.value)
      .toString();
    this.odataQuery = '?$filter=' + filterString
    if(filterString == ""){
      this.odataQuery = "";
    }

    this.ngOnInit();
  }

  handleCityChange(event: MatSelectChange){
    console.log(this.city.value);
    let filterString = f('and')
      .in('location/state', this.state.value)
      .in('location/city', this.city.value)
      .toString();
    this.odataQuery = '?$filter=' + filterString
    if(filterString == ""){
      this.odataQuery = "";
    }
    this.ngOnInit();
  }

  handleFilterChange(event: MatSelectChange){
    let filterString = f.and()
      .in('location/state', this.state.value)
      .in('location/city', this.city.value)
      .contains('title' , this.title.value? this.title.value : "")
      .contains('description' , this.description.value? this.description.value : "")
      .lt('reportedAt', this.dateRangeSelected.endDate.toISOString(), false)
      .gt('reportedAt', this.dateRangeSelected.startDate.toISOString(), false);
      if(this.reportedBy.value){
        filterString.contains('reportedBy/email' , this.reportedBy.value ? this.reportedBy.value : "")
      }
      if(this.assignedTo.value){
        filterString.contains('assignedTo/email' , this.assignedTo.value ? this.assignedTo.value : "")
      }

    this.odataQuery = '?$filter=' + filterString.toString()
    if(filterString.toString() == ""){
      this.odataQuery = "";
    }
    this.ngOnInit();
  }

  // mapClicked($event: MouseEvent) {
  //   this.markers.push({
  //     lat: $event.coords.lat,
  //     lng: $event.coords.lng,
  //     label: 'C',
  //     draggable: true
  //   });
  // }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }

  exportTableAsExcel() {
    let exportArray = [];
    this.closeCalls.data.forEach(closeCall => {
        let closeCallToExport = {
          "Title": closeCall.title,
          "Description": closeCall.description,
          "Incident Location Identifier": closeCall.occurenceLocationIdentifier.name,
          "Incident Location": closeCall.occurenceLocationDescription,
          "Incident At": closeCall.occurenceAt,
          "Reported Location": closeCall.location.label,
          "Reported By": closeCall.reportedBy.email,
          "Reported At": closeCall.reportedAt,
          "Assigned To": closeCall.assignedTo ? closeCall.assignedTo.email : '',
          "Assigned At": closeCall.assignedAt,
          "Resolved By": closeCall.resolvedBy ? closeCall.resolvedBy.email : '',
          "Resolved At": closeCall.resolvedAt
        }
        exportArray.push(closeCallToExport);
      });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportArray);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    let currentTimestamp = new Date().toString();
    currentTimestamp = this.datePipe.transform(Date.now(),'yyyyMMdd_mmss');
    XLSX.writeFile(wb,  'closecalls_' + currentTimestamp + '.xlsx');
  }


}
