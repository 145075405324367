import {Component, Input, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import f from 'odata-filter-builder';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService, CloseCall, CloseCallService, SiteOperativeService} from '../../../../services-generated';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-resolve-close-call',
  templateUrl: './resolve-close-call.component.html',
  styleUrls: ['./resolve-close-call.component.scss']
})
export class ResolveCloseCallComponent implements OnInit {
  loading = false;
  closeCallData: CloseCall = {};
  siteOperatives = []
  markers = [];
  siteOperative = new FormControl('', Validators.required);
  error = '';
  resolution = new FormControl('', Validators.required);
  closeCallId = null;
  odataQuery = '?$filter=' +  f.and().contains('role/name', 'SiteOperative').eq('isActive', true).toString();
  constructor(private route: ActivatedRoute,
              private router: Router,
              private siteOperativeService: SiteOperativeService,
              private closeCallService: CloseCallService,
              private accountService: AccountService,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.accountService.apiAccountGet(this.odataQuery).subscribe((accountResponse)=>{
      let siteOperatives = accountResponse.map((siteOperative)=>{
        return {
          id: siteOperative.id,
          name: siteOperative.firstName,
          lastname: siteOperative.lastName,
          email: siteOperative.email,
          phoneNumber: siteOperative.phoneNumber,
          sentinelNumber: siteOperative['siteOperativeDetail'].sentinelNumber,
          homePostCode: siteOperative['siteOperativeDetail'].homePostCode,
          businessDeliveryUnit: siteOperative['siteOperativeDetail'].businessDeliveryUnit,
        }
      })
      this.siteOperatives = siteOperatives;
    });
    if(this.route.snapshot.paramMap.get('id')){
      this.closeCallId = this.route.snapshot.paramMap.get('id');
      let query = '?$filter=id eq ' + this.closeCallId ;
      this.closeCallService.apiCloseCallGet(query).subscribe(closeCall => {
        this.closeCallData =closeCall[0];
      })
    }
  }

  onSubmit() {

    // stop here if values are invalid
    if (this.resolution.invalid) {
      this.resolution.markAsDirty();
      return;
    }
    this.loading = true;
    this.closeCallService.apiCloseCallIdResolvePut(this.closeCallId, this.resolution.value)
      .subscribe(closeCallResolutionResponse => {
          this.loading = false;
          if(closeCallResolutionResponse.isSuccessful){
            this.snackBar.open(closeCallResolutionResponse.message, "OK", {
              duration: 3000,
            });
            this.router.navigate(['/supervisor/dashboard/close-call/live']);
          } else{
            this.snackBar.open(closeCallResolutionResponse.message, "OK", {
              duration: 3000,
            });
          }
        },
        error => {
          this.snackBar.open(error, "OK", {
            duration: 3000,
          });
          this.loading = false;
        });
  }

}
