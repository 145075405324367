import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hr-home',
  templateUrl: './hr-home.component.html',
  styleUrls: ['./hr-home.component.scss']
})
export class HrHomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
