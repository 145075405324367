import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentService } from 'src/services-generated/api/document.service';
import { Document } from 'src/services-generated/model/document';
import { debounce } from 'lodash';

@Component({
  selector: 'app-notice-board-list',
  templateUrl: './notice-board-list.component.html',
  styleUrls: ['./notice-board-list.component.scss']
})
export class NoticeBoardDocumentListComponent implements OnInit {
  searchString = "";
  loading = false;
  files = [];
  error = '';
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private documentService: DocumentService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.documentService.apiDocumentGet("?$orderby=createdAt desc&$filter=isActive eq true and isNoticeBoardDocument eq true").subscribe((documentResponse)=>{
      this.files = documentResponse;
    });
  }

  search(searchString) {
    this.documentService.apiDocumentGet(`?$orderby=createdAt desc&$filter=contains(tolower(name),'${searchString.toLowerCase()}') and isNoticeBoardDocument eq true`).subscribe((documentResponse)=>{
      this.files = documentResponse;
    })
  }

  delete(file: Document){
    this.documentService.apiDocumentIdDelete(file.id).subscribe(deleteResponse =>{
      this.snackBar.open(deleteResponse.message, "OK", {
        duration: 3000,
      });
      if(deleteResponse.isSuccessful) {
        this.documentService.apiDocumentGet("?$orderby=createdAt desc&$filter=isActive eq true and isNoticeBoardDocument eq true").subscribe((documentResponse)=>{
          this.files = documentResponse;
        });
      }
    })
  }

  download(file: Document) {
    var atag = document.createElement('a');
    atag.href = `${this.documentService.basePath}/api/Document/${file.id}/content`
    atag.target = "download"
    atag.download = file.name
    atag.click();
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  edit(file){
    this.router.navigate([`/admin/notice-board-document/edit/${file.id}`]);
  }

}
