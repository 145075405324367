import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {CloseCall, LocationService, UserLocation, UserLocationService} from '../../../services-generated';
import {FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {environment} from '@environments/environment';
import {MatSelectChange} from '@angular/material/select';
import f from 'odata-filter-builder';
import * as XLSX from 'xlsx';
import {Observable, Subscription} from 'rxjs';
import { Moment } from 'moment';
import * as moment from 'moment';
import { AuthenticationService } from '@app/_services';
import { UserModule } from 'src/services-generated/model/UserModule.Model';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnDestroy, AfterViewInit {
  loading = false;
  stateLoading = false;
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  displayedColumns: string[] = ["firstName", "lastName", "email", "phoneNumber", "location", "city", "state", "postalCode", "sentinelNumber", "homePostCode", "businessDeliveryUnit", "createdAt"];
  siteOperatives: MatTableDataSource<UserLocation> = new MatTableDataSource<UserLocation>();
  apiCloseCallGetCountSubs: Subscription;
  siteOperativeCount:Number;
  lat = 55.861606;
  lng = -4.250313;
  country = new FormControl();
  state = new FormControl([]);
  city = new FormControl();
  firstName = new FormControl();
  postalCode = new FormControl();
  sentinelNumber = new FormControl();
  businessDeliveryUnit = new FormControl();
  time = new FormControl();
  dateRangeSelected: {startDate: Moment, endDate: Moment} = {
    startDate: moment().add(-1, 'days'),
    endDate: moment()
  }
  countries = ['UK'];
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  states = [];
  cities = [];
  filteredStates: Observable<string[]>;
  odataQuery = "?$format=json&$orderby=createdAt desc&$filter=" + f.and().lt('createdAt', this.dateRangeSelected.endDate.toISOString(), false)
  .gt('createdAt', this.dateRangeSelected.startDate.toISOString(), false);
  locationOdataQuery = "?$top=1000&$format=json&$orderby=createdAt desc&$filter=" + f.and().ne('city', null, false)
  .ne('state', null, false);
  markers:any[] = [];
  userRights: UserModule;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private userLocationService: UserLocationService,
    private locationService: LocationService,
    private authenticationService: AuthenticationService) { 
      this.firstName.valueChanges
      .pipe(debounceTime(500)) // Adjust the debounce time as needed (in milliseconds)
      .subscribe((newValue) => {
        this.handleFilterChange(newValue)
      });
    }

  ngOnInit() {
    this.country.setValue('UK');
    this.userRights = this.authenticationService.getUserRights("history");
    this.filteredStates = this.state.valueChanges.pipe(
      switchMap(async (value) => await this._filter(value))
    );
  }

  ngAfterViewInit() {
    // this.state.updateValueAndValidity({emitEvent: true});
  }

  private async _filter(value: any): Promise<string[]> {
    if(typeof(value) != 'string') {
      value = "";
    }
    const filterValue = value.toLowerCase();
    this.stateLoading = true;
    this.locationOdataQuery = "?$top=1000&$format=json&$orderby=createdAt desc&$filter=" + f.and().ne('city', null, false)
    .ne('state', null, false) + `and contains(state, '${filterValue}')`
    let locations = await this.locationService.apiLocationGet(this.locationOdataQuery).toPromise();
    this.stateLoading = false;
    if(locations) {
      this.states = [...new Set(locations.map(location => location.state))];
      this.cities = [...new Set(locations.filter(location =>
        {
          if(this.state.value.length != 0){
            return this.state.value.includes(location.state)
          } else {
            return true;
          }
        }
      ).map(location => location.city))];
      return this.states.filter(state => state.toLowerCase().includes(filterValue));
    }
  }

  getList(){
    this.loading = true;
    this.userLocationService.apiUserLocationHistoryGet(this.odataQuery).subscribe((userLocationResponse)=>{
      this.loading = false;
      this.siteOperatives.data = [...userLocationResponse];
      this.markers = userLocationResponse.map(userLocation => {
        return {
          lat: userLocation.location.latitude,
          lng: userLocation.location.longitude,
          label: userLocation.location.id,
          draggable: false,
          title: userLocation.user.email,
          description: userLocation.user.siteOperativeDetail.sentinelNumber,
          reportedBy: userLocation.createdAt
        }
      });
      this.lat = this.markers[this.markers.length-1].lat;
      this.lng = this.markers[this.markers.length-1].lng;
      this.paginator.pageIndex = 0;
      this.siteOperatives.paginator = this.paginator;
    });
  }

  ngOnDestroy() {
  }

  handleStateChange(event: MatSelectChange){
    let filterString = f('and')
      .in('location/state', this.state.value)
      .in('location/city', this.city.value)
      .toString();
    this.odataQuery = '?$format=json&$orderby=createdAt desc&$filter=' + filterString
    if(filterString == ""){
      this.odataQuery = "";
    }

    this.getList();
  }

  handleCityChange(event: MatSelectChange){
    console.log(this.city.value);
    let filterString = f('and')
      .in('location/state', this.state.value)
      .in('location/city', this.city.value)
      .toString();
    this.odataQuery = '?$format=json&$orderby=createdAt desc&$filter=' + filterString
    if(filterString == ""){
      this.odataQuery = "";
    }
    this.getList();
  }

  handleFilterChange(event: MatSelectChange){
    let filterString = f.and()
      .in('location/state', this.state.value)
      .in('location/city', this.city.value)
      // .contains('user/firstName' , this.firstName.value ? this.firstName.value : "")
      .and(
        this.firstName.value && this.firstName.value.split(" ").length > 1 ?
        f.and()
        .contains(x => x.toLower('user/firstName') , this.firstName.value.toLowerCase().split(" ")[0])
        .contains(x => x.toLower('user/lastName') , this.firstName.value.toLowerCase().split(" ")[1]) : 
        f.or()
        .contains(x => x.toLower('user/firstName') , this.firstName.value? this.firstName.value.toLowerCase() : "")
        .contains(x => x.toLower('user/lastName') , this.firstName.value? this.firstName.value.toLowerCase() : "")
      )
      .contains('user/siteOperativeDetail/sentinelNumber' , this.sentinelNumber.value? this.sentinelNumber.value : "")
      .contains('user/siteOperativeDetail/homePostCode' , this.postalCode.value? this.postalCode.value : "")
      .contains(x => x.toLower('user/siteOperativeDetail/businessDeliveryUnit') , this.businessDeliveryUnit.value? this.businessDeliveryUnit.value.toLowerCase() : "")
      .lt('createdAt', this.dateRangeSelected.endDate.toISOString(), false)
      .gt('createdAt', this.dateRangeSelected.startDate.toISOString(), false)
      .toString();
    this.odataQuery = '?$format=json&$orderby=createdAt desc&$filter=' + filterString
    if(filterString == ""){
      this.odataQuery = "";
    }
    this.getList();
  }

  // mapClicked($event: MouseEvent) {
  //   this.markers.push({
  //     lat: $event.coords.lat,
  //     lng: $event.coords.lng,
  //     label: 'C',
  //     draggable: true
  //   });
  // }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }

  exportTableAsExcel() {
    let exportArray = [];
    this.siteOperatives.data.forEach(siteOperative => {
      let siteOperativeToExport = {
        "First Name": siteOperative.user.firstName,
        "Last Name": siteOperative.user.lastName,
        "Email": siteOperative.user.email,
        "Phone Number": siteOperative.user.phoneNumber,
        "Location": siteOperative.location.label,
        "City": siteOperative.location.city,
        "State": siteOperative.location.state,
        "Postal Code": siteOperative.location.postalCode,
        "Sentinel Number": siteOperative.user.siteOperativeDetail.sentinelNumber,
        "Home PostCode": siteOperative.user.siteOperativeDetail.homePostCode,
        "Business Delivery Unit": siteOperative.user.siteOperativeDetail.businessDeliveryUnit,
        "Created At": siteOperative.createdAt
      }
      exportArray.push(siteOperativeToExport);
    });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportArray)
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    let currentTimestamp = new Date().toString();
    currentTimestamp = this.datePipe.transform(Date.now(),'yyyyMMdd_mmss');
    XLSX.writeFile(wb,  'siteoperatives_' + currentTimestamp + '.xlsx');
  }

}
