import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CompactType, GridsterConfig, GridsterItem, GridType } from 'angular-gridster2';
import { Observable, of } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { DashboardService, GraphTypeService, InputPageService } from 'src/report-services-generated';
import { FieldValueService } from 'src/report-services-generated/api/fieldValue.service';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material';


@Component({
  selector: 'app-create-dashboard',
  templateUrl: './create-dashboard.component.html',
  styleUrls: ['./create-dashboard.component.scss']
})
export class CreateDashboardComponent implements OnInit {

  constructor(
    private fieldValueService: FieldValueService,
    private inputPageService: InputPageService,
    private _snackbar: MatSnackBar,
    private dashboardService: DashboardService,
    private graphTypeService: GraphTypeService
  ) { }

  
  options: GridsterConfig;
  dashboard: Array<GridsterItem> = [];
  indicatorOptions = ['One', 'Two', 'Three'];
  filteredOptions: Observable<any>;
  filteredChartOptions: Observable<any>;
  myControl = new FormControl();
  chartFieldControl = new FormControl();
  fields: any[] = [];
  weeklyInputId:number = 1;
  monthlyInputId:number = 2;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  dateRangeSelected: {startDate: moment.Moment, endDate: moment.Moment} = {
    startDate: moment().add(-28, 'days'),
    endDate: moment()
  }

  ngOnInit() {
    this.graphTypeService.apiGraphTypeGet().subscribe(graphTypes => {
      this.chartTypeOptions = graphTypes.map((graphType)=>{return {id: graphType.id, name: graphType.name.toLowerCase()}});
    })
    this.inputPageService.apiInputPageIdGet(this.weeklyInputId).subscribe(res => {
      res.sections.forEach(section => {
        this.fields.push(...section.fields);
      });      
    })
    this.inputPageService.apiInputPageIdGet(this.monthlyInputId).subscribe(res => {
      res.sections.forEach(section => {
        this.fields.push(...section.fields);
      });
    });
    this.options = {
      gridType: GridType.ScrollVertical,
      compactType: CompactType.None,
      minCols: 15,
      maxCols: 15,
      minRows: 1,
      defaultItemCols: 1,
      defaultItemRows: 1,
      draggable: {
        enabled: true,
      },
      resizable: {
        enabled: true,
      },
      maxItemArea: 100
    };
    this.dashboardService.apiDashboardGet().subscribe(dashboardListResponse => {
      if(dashboardListResponse.length > 0) {
        this.dashboardService.apiDashboardIdGet(dashboardListResponse[dashboardListResponse.length-1].id).subscribe(dashboardResponse => {
          let dashboardJSON = dashboardResponse[dashboardListResponse.length-1].dashboardPageDetails.map(dashboardPage => {
            return {
              chartType: {name: dashboardPage.graphType.name.toLowerCase(), id: dashboardPage.graphType.id},
              cols: dashboardPage.columnSpan,
              indicator: [{
                id: dashboardPage.field.id,
                indicatorType: dashboardPage.field.indicatorType,
                name: dashboardPage.field.name,
              }],
              rows: dashboardPage.rowSpan,
              x: dashboardPage.x,
              y: dashboardPage.y,
              dataSet: [],
              chartLabels: [],
              indicatorControl: new FormControl(),
              chartFieldControl: new FormControl()
            }
          });
          if(dashboardJSON){
            dashboardJSON.forEach(element => {
              element.dataSet = [{data:[]}];
            }); 
            dashboardJSON.forEach(item => {
              this.fieldValueService.apiFieldValueGet(item.indicator[0].id,moment(this.dateRangeSelected.startDate).format("yyyy-MM-DD"),moment(this.dateRangeSelected.endDate).format("yyyy-MM-DD")).subscribe(res => {
                let chartDataArray = [];
                let chartLabelArray = [];
                res.forEach(instance => {
                  chartDataArray.push(instance['Value']);
                  chartLabelArray.push(this.getFormattedDate(instance['Date']));
                })
                item.dataSet = [{ data: chartDataArray, label: item.indicator[0].name, fill: false }];
                item.chartLabels = chartLabelArray; 
                
                item.indicatorControl.setValue(item.indicator[0].name);
                item.chartFieldControl.setValue(item.chartType.name);
              })
            })
            this.dashboard = dashboardJSON;
          }
        })
      }
    })
    this.dashboardService.apiDashboardGet().subscribe(res => {
      console.log(res);
    })

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.filteredChartOptions = this.chartFieldControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterChartTypes(value))
    );
}

filterIndicators(event, item) {
  this.filteredOptions = of(this._filter(event.target.value));
}

filterCharts(event, item) {
  this.filteredChartOptions = of(this._filterChartTypes(event.target.value));
}

private _filter(value: string): string[] {
  const filterValue = value.toLowerCase();
  return this.fields.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
}
private _filterChartTypes(value: string): string[] {
  const filterValue = value.toLowerCase();
  return this.chartTypeOptions.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
}

getFormattedDate(dateString: string){
  return moment(new Date(dateString)).format("MM/DD/YYYY");
}
selectIndicator(value,item){
  value = this.fields.filter(field => value == field.name);
  item.indicator = value;
  console.log(value);
  this.fieldValueService.apiFieldValueGet(value[0].id,moment(this.dateRangeSelected.startDate).format("yyyy-MM-DD"),moment(this.dateRangeSelected.endDate).format("yyyy-MM-DD")).subscribe(res => {
    console.log(res);
    let chartDataArray = [];
    let chartLabelArray = [];

    res.forEach(instance => {
      chartDataArray.push(instance['Value']);
      chartLabelArray.push(this.getFormattedDate(instance['Date']));
    })
    item.dataSet = [{ data: chartDataArray, label: value[0].name }];
    item.chartLabels = chartLabelArray;    
  })
}

selectGraphType(value,item){
  item.chartType = this.chartTypeOptions.find(chartType => chartType.name == value);
}


//Chart Settings
chartTypeOptions: any[] = []
public chartData: ChartDataSets[] = [{data: []}];

public chartOptions: (ChartOptions & { annotation ?: any }) = {
  responsive: true,
  scales: {
    yAxes: [{
        ticks: {
            beginAtZero: true
        }
    }]
  }
};

public chartLabels: Label[] = [];
public chartColors: Color[] = [
  {
    borderColor: '#EB4000BF',
    backgroundColor: ['#EB4000BF', '#F0D12FBF', '#19183BBF', '#304585BF', '#2B69B6BF', '#6F9CD340BF'],
  },
];
public chartLegend = true;
public chartType = 'line';
public chartPlugins = [];



//Gridster Helpers
changedOptions() {
  this.options.api.optionsChanged();
}

removeItem(item) {
  this.dashboard.splice(this.dashboard.indexOf(item), 1);
}

addItem() {
  this.dashboard.push({ cols: 5, rows: 4, y: 0, x: 0 ,chartType: {name: "line", id: 1}, indicator: null , dataSet: [{data: []}]});
}

createDashboard(){
  
  let dashboardArray = [];
  this.dashboard.map(item => {
    dashboardArray.push({
      columnSpan: item.cols,
      rowSpan: item.rows,
      y: item.y,
      x: item.x,
      graphTypeId: item.chartType.id,
      fieldId: item.indicator[0].id
    })
  })
  let dashboardBody = {
    name: "Dashboard",
    dashboardPageDetails : dashboardArray
  }
  this.dashboardService.apiDashboardGet().subscribe(dashboardResponse => {
    if(dashboardResponse.length == 0){
      this.dashboardService.apiDashboardPost(dashboardBody).subscribe(dashboardResponse => {
        this._snackbar.open("Dashboard created successfully!","Ok")
      });
    } else {
      this.dashboardService.apiDashboardIdPut(dashboardResponse[dashboardResponse.length-1].id, dashboardBody).subscribe(dashboardUpdateResponse => {
        this._snackbar.open("Dashboard updated successfully!","Ok")
      });
    }
  })
}

handleFilterChange(){
  this.ngOnInit();
}

}
