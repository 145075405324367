import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '@app/_services';
import { UserModule } from 'src/services-generated/model/UserModule.Model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  currentUrl = '';
  liveOpen = false;
  historyOpen = false;
  authenticationOpen = false;
  loginAttemptOpen = false;
  ruleBookDrawerHandler = false;
  liveRights: UserModule;
  historyRights: UserModule;
  authRights: UserModule;
  ruleRights: UserModule;
  constructor(private router: Router,
    private authenticationService: AuthenticationService) {
    router.events.subscribe((val) => {
      // see also 
      if (val instanceof NavigationEnd) {
        this.currentUrl = val.url;
        switch (this.currentUrl) {
          case '/supervisor/dashboard/live':
            {
              this.liveOpen = true;
              break;
            }
          case '/supervisor/dashboard/history':
            {
              this.historyOpen = true;
              break;
            }
          case '/supervisor/dashboard/close-call/live':
            {
              this.liveOpen = true;
              break;
            }
          case '/supervisor/dashboard/close-call/history':
            {
              this.historyOpen = true;
              break;
            }
          case '/supervisor/dashboard/login/history':
            {
              this.historyOpen = true;
              break;
            }
          case '/supervisor/dashboard/proxy-authentication/login':
            {
              this.authenticationOpen = true;
              break;
            }
          case '/supervisor/dashboard/proxy-authentication/logout':
            {
              this.authenticationOpen = true;
              break;
            }
          case '/supervisor/dashboard/proxy-authentication/list':
            {
              this.authenticationOpen = true;
              break;
            }
          case '/supervisor/dashboard/login-attempt/failures':
            {
              this.loginAttemptOpen = true;
              break;
            }
          case '/supervisor/dashboard/rule-book-list':
            {
              this.ruleBookDrawerHandler = true;
              break;
            }
        }
      }
    });
  }

  ngOnInit() {
    this.liveRights = this.authenticationService.getUserRights("live");
    this.historyRights = this.authenticationService.getUserRights("history");
    this.authRights = this.authenticationService.getUserRights("auth");
    this.ruleRights = this.authenticationService.getUserRights("rule");
  }

}
