export * from './dashboard.service';
import { DashboardService } from './dashboard.service';
export * from './field.service';
import { FieldService } from './field.service';
export * from './fieldTarget.service';
import { FieldTargetService } from './fieldTarget.service';
export * from './graphType.service';
import { GraphTypeService } from './graphType.service';
export * from './inputPage.service';
import { InputPageService } from './inputPage.service';
export const APIS = [DashboardService, FieldService, FieldTargetService, GraphTypeService, InputPageService];
