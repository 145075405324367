import { TypeScriptEmitter } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from '@app/_models';
import { Certificate } from 'src/services-generated/model/certificate';
import { AccountService, SiteOperativeService } from 'src/services-generated';
import { CertificateService } from 'src/services-generated/api/certificate.service';
import { UserCertificateModel } from 'src/services-generated/model/userCertificateModel';

@Component({
  selector: 'app-site-operative-detail',
  templateUrl: './site-operative-detail.component.html',
  styleUrls: ['./site-operative-detail.component.scss']
})
export class SiteOperativeDetailComponent implements OnInit {
  loading = false;
  files: any[] = [];
  filteredFiles: any[] = [];
  oldFile: any = {};
  newFile: any = {};
  editMode = false;
  siteOperativeId = null;
  siteOperativeForm: FormGroup;
  error = '';
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private certificateService: CertificateService,
    private siteOperativeService: SiteOperativeService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.siteOperativeForm = this.formBuilder.group({
      sentinelNumber: ['', Validators.required],
      homePostCode: [''],
      businessDeliveryUnit: [''],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      firstName: ['', Validators.required],
      lastName: [''],
      password: ['', Validators.required],
      role: [Role.SiteOperative]
    });
    if(this.route.snapshot.paramMap.get('id')){
      this.siteOperativeId = this.route.snapshot.paramMap.get('id');
      this.accountService.apiAccountSiteOperativeIdGet(parseInt(this.siteOperativeId)).subscribe(siteOperativeResponse => {
        let siteOperativeData = siteOperativeResponse.result;
        this.siteOperativeForm.setValue({
          sentinelNumber: siteOperativeData.sentinelNumber,
          homePostCode: siteOperativeData.homePostCode,
          businessDeliveryUnit: siteOperativeData.businessDeliveryUnit,
          email: siteOperativeData.email,
          phoneNumber: siteOperativeData.phoneNumber,
          firstName: siteOperativeData.firstName,
          lastName: siteOperativeData.lastName,
          password: "PASSWORD",
          role: Role.SiteOperative
        });
      });
      this.editMode = true;
      this.siteOperativeService.apiSiteOperativeSiteOperativeUserIdCertificateGet(this.siteOperativeId).subscribe(response => {
        if(response.isSuccessful){
          this.files = response.result.map(userCertificate => {
            return {...userCertificate.certificate, validity: userCertificate.validity};
          });
          this.filteredFiles = this.files;
        } else {
          this.snackBar.open(response.message, "OK", {
            duration: 3000,
          });
        }
      });
    }
  }

  get f() { return this.siteOperativeForm.controls; }

  onSubmit() {

    // stop here if form is invalid
    if (this.siteOperativeForm.invalid) {
        return;
    }
    console.log(this.siteOperativeForm.value);
    this.loading = true;
    if(this.editMode){
      let formValue = this.siteOperativeForm.value;
      delete formValue.password;
      this.accountService.apiAccountSiteOperativeIdPut(this.siteOperativeId, formValue)
      .subscribe(siteOperativeResponse => {
        this.loading = false;
        if(siteOperativeResponse.isSuccessful){
            this.snackBar.open(siteOperativeResponse.message, "OK", {
              duration: 3000,
            });
            this.router.navigate(['/admin/site-operative']);
        } else{
          this.snackBar.open(siteOperativeResponse.message, "OK", {
            duration: 3000,
          });
        }
      },
      error => {
          console.log(error);
          this.snackBar.open(error, "OK", {
            duration: 3000,
          });
          this.loading = false;
      });
    } else {
      this.accountService.apiAccountSiteOperativePost(this.siteOperativeForm.value)
      .subscribe(siteOperativeResponse => {
        this.loading = false;
        if(siteOperativeResponse.isSuccessful){
            this.snackBar.open(siteOperativeResponse.message, "OK", {
              duration: 3000,
            });
            this.router.navigate(['/admin/site-operative']);
        } else{
          this.snackBar.open(siteOperativeResponse.message, "OK", {
            duration: 3000,
          });
        }
      },
      error => {
          console.log(error);
          this.snackBar.open(error, "OK", {
            duration: 3000,
          });
          this.loading = false;
      });
    }
  }

  updateExpiry($event, file) {
    let validityAdjusted = $event.value;
    validityAdjusted.setTime(validityAdjusted.getTime() - (validityAdjusted.getTimezoneOffset()*60*1000))
    let userCertificateModel: UserCertificateModel = {
      validity: validityAdjusted
    }
    this.siteOperativeService.apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdPut(this.siteOperativeId, file.id, userCertificateModel).subscribe(response => {
      this.snackBar.open(response.message, "OK", {
        duration: 3000,
      });
    })
  }

  search(searchString) {
    this.filteredFiles = this.files.filter((file: Certificate) => {
      return file.name.includes(searchString) || file.size.toString().includes(searchString) || file.createdAt.toString().includes(searchString);
    });
  }

  delete(file: Certificate) {
    this.siteOperativeService.apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdDelete(this.siteOperativeId, file.id).subscribe(response => {
      if(response.isSuccessful) {
        this.files.splice(this.files.findIndex(currentFile => currentFile.id == file.id),1);
      }
      this.snackBar.open(response.message, "OK", {
        duration: 3000,
      });
    });
  }

  download(file: Certificate) {
    this.certificateService.apiCertificateIdContentGet(file.id).subscribe(response => {
      var atag = document.createElement('a');
      atag.target = "download"
      atag.download = file.name
      atag.href = (window["webkitURL"] || window.URL).createObjectURL(response);
      atag.click();
    })
  }

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

}
