import { TypeScriptEmitter } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from '@app/_models';
import {AccountService, LoginResponseModel} from 'src/services-generated';
import {AuthenticationService} from '@app/_services';

@Component({
  selector: 'app-admin-detail',
  templateUrl: './admin-detail.component.html',
  styleUrls: ['./admin-detail.component.scss']
})
export class AdminDetailComponent implements OnInit {
  loading = false;
  editMode = false;
  adminId = null;
  adminForm: FormGroup;
  error = '';
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private authenticationService: AuthenticationService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.adminForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      firstName: ['', Validators.required],
      lastName: [''],
      password: ['', Validators.required],
      role: [Role.Admin]
    });
    if(this.route.snapshot.paramMap.get('id')){
      this.adminId = this.route.snapshot.paramMap.get('id');
      this.accountService.apiAccountIdGet(parseInt(this.adminId)).subscribe(adminResponse => {
        let adminData = adminResponse.result;
        this.adminForm.setValue({
          email: adminData.email,
          phoneNumber: adminData.phoneNumber,
          firstName: adminData.firstName,
          lastName: adminData.lastName,
          password: "PASSWORD",
          role: Role.Admin
        })
      })
      this.editMode = true;
    }
  }

  get f() { return this.adminForm.controls; }

  onSubmit() {

    // stop here if form is invalid
    if (this.adminForm.invalid) {
        return;
    }
    console.log(this.adminForm.value);
    this.loading = true;
    if(this.editMode){
      let formValue = this.adminForm.value;
      delete formValue.password;
      this.accountService.apiAccountIdPut(this.adminId, formValue)
      .subscribe(adminResponse => {
        this.loading = false;
        if(adminResponse.isSuccessful){
            this.snackBar.open(adminResponse.message, "OK", {
              duration: 3000,
            });
            this.router.navigate(['/admin/list']);
        } else{
          this.snackBar.open(adminResponse.message, "OK", {
            duration: 3000,
          });
        }
      },
      error => {
          console.log(error);
          this.snackBar.open(error, "OK", {
            duration: 3000,
          });
          this.loading = false;
      });
    } else {
      this.accountService.apiAccountPost(this.adminForm.value)
      .subscribe(adminResponse => {
        this.loading = false;
        if(adminResponse.isSuccessful){
            this.snackBar.open(adminResponse.message, "OK", {
              duration: 3000,
            });
            this.router.navigate(['/admin/list']);
        } else{
          this.snackBar.open(adminResponse.message, "OK", {
            duration: 3000,
          });
        }
      },
      error => {
          console.log(error);
          this.snackBar.open(error, "OK", {
            duration: 3000,
          });
          this.loading = false;
      });
    }
}

}
