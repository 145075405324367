import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RuleBookService } from 'src/services-generated/api/ruleBook.service';
import { TagService } from 'src/services-generated/api/tag.service';
import { Tag } from 'src/services-generated/model/tag';

@Component({
  selector: 'app-tags-modal',
  templateUrl: './tags-modal.component.html',
  styleUrls: ['./tags-modal.component.scss']
})
export class TagsModalComponent implements OnInit {

  tags = new FormControl('');
  tagList: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<TagsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private rulebookService: RuleBookService,
    private tagService: TagService
  ) { }

  ngOnInit() {
    this.tags.setValue(this.data.tags.split(';'));
    this.tagService.apiTagAllGet().subscribe((res: Tag[]) => {
      this.tagList = res.map(tag => tag.name);
    })
  }

  updateTag() {
    const updatedTags = this.tags.value.join(';')
    this.rulebookService.apiRuleBookIdTagPut(this.data.fileId, updatedTags).subscribe(res => {
      this.dialogRef.close(updatedTags)
    })
  }

}
