/**
 * worksite_track
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ExecutionResponse } from '../model/executionResponse';
import { LocationUpdateModel } from '../model/locationUpdateModel';
import { LoginAttempt } from '../model/loginAttempt';
import { LoginModel } from '../model/loginModel';
import { LoginQuestionResponseModelListExecutionResponse } from '../model/loginQuestionResponseModelListExecutionResponse';
import { LoginResponseModelExecutionResponse } from '../model/loginResponseModelExecutionResponse';
import { SiteOperativeLoginModel } from '../model/siteOperativeLoginModel';
import { SiteOperativeLoginResponseModelExecutionResponse } from '../model/siteOperativeLoginResponseModelExecutionResponse';
import { UserCertificateExecutionResponse } from '../model/userCertificateExecutionResponse';
import { UserCertificateListExecutionResponse } from '../model/userCertificateListExecutionResponse';
import { UserCertificateModel } from '../model/userCertificateModel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { environment } from '../../environments/environment'
import { LoginCount } from '../model/loginCount';


@Injectable()
export class SiteOperativeService {

    public basePath = environment.basePath;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSiteOperativeLocationPost(body?: LocationUpdateModel, observe?: 'body', reportProgress?: boolean): Observable<ExecutionResponse>;
    public apiSiteOperativeLocationPost(body?: LocationUpdateModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExecutionResponse>>;
    public apiSiteOperativeLocationPost(body?: LocationUpdateModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExecutionResponse>>;
    public apiSiteOperativeLocationPost(body?: LocationUpdateModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/plain',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ExecutionResponse>('post',`${this.basePath}/api/site-operative/location`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSiteOperativeLoginGet(observe?: 'body', reportProgress?: boolean): Observable<LoginQuestionResponseModelListExecutionResponse>;
    public apiSiteOperativeLoginGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LoginQuestionResponseModelListExecutionResponse>>;
    public apiSiteOperativeLoginGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LoginQuestionResponseModelListExecutionResponse>>;
    public apiSiteOperativeLoginGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/plain',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<LoginQuestionResponseModelListExecutionResponse>('get',`${this.basePath}/api/site-operative/login`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSiteOperativeLoginPost(body?: SiteOperativeLoginModel, observe?: 'body', reportProgress?: boolean): Observable<SiteOperativeLoginResponseModelExecutionResponse>;
    public apiSiteOperativeLoginPost(body?: SiteOperativeLoginModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SiteOperativeLoginResponseModelExecutionResponse>>;
    public apiSiteOperativeLoginPost(body?: SiteOperativeLoginModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SiteOperativeLoginResponseModelExecutionResponse>>;
    public apiSiteOperativeLoginPost(body?: SiteOperativeLoginModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/plain',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SiteOperativeLoginResponseModelExecutionResponse>('post',`${this.basePath}/api/site-operative/login`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSiteOperativeLogoutPost(observe?: 'body', reportProgress?: boolean): Observable<SiteOperativeLoginResponseModelExecutionResponse>;
    public apiSiteOperativeLogoutPost(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SiteOperativeLoginResponseModelExecutionResponse>>;
    public apiSiteOperativeLogoutPost(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SiteOperativeLoginResponseModelExecutionResponse>>;
    public apiSiteOperativeLogoutPost(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/plain',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SiteOperativeLoginResponseModelExecutionResponse>('post',`${this.basePath}/api/site-operative/logout`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSiteOperativeLoginCountGet(observe?: 'body', reportProgress?: boolean): Observable<LoginCount>;
    public apiSiteOperativeLoginCountGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LoginCount>>;
    public apiSiteOperativeLoginCountGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LoginCount>>;
    public apiSiteOperativeLoginCountGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/plain',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<LoginCount>('get',`${this.basePath}/api/site-operative/login/count`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param date 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSiteOperativeProxyGet(date?: Date, observe?: 'body', reportProgress?: boolean): Observable<Array<LoginAttempt>>;
    public apiSiteOperativeProxyGet(date?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LoginAttempt>>>;
    public apiSiteOperativeProxyGet(date?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LoginAttempt>>>;
    public apiSiteOperativeProxyGet(date?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date.toISOString());
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/plain',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<LoginAttempt>>('get',`${this.basePath}/api/site-operative/proxy`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSiteOperativeProxyHistoryGet(odataQuery: string, observe?: 'body', reportProgress?: boolean): Observable<Array<LoginAttempt>>;
    public apiSiteOperativeProxyHistoryGet(observe?: 'body', reportProgress?: boolean): Observable<Array<LoginAttempt>>;
    public apiSiteOperativeProxyHistoryGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LoginAttempt>>>;
    public apiSiteOperativeProxyHistoryGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LoginAttempt>>>;
    public apiSiteOperativeProxyHistoryGet(odataQuery = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/plain',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<LoginAttempt>>('get',`${this.basePath}/api/site-operative/proxy/history${odataQuery}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSiteOperativeLoginAttemptGet(odataQuery: string, observe?: 'body', reportProgress?: boolean): Observable<Array<LoginAttempt>>;
    public apiSiteOperativeLoginAttemptGet(observe?: 'body', reportProgress?: boolean): Observable<Array<LoginAttempt>>;
    public apiSiteOperativeLoginAttemptGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LoginAttempt>>>;
    public apiSiteOperativeLoginAttemptGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LoginAttempt>>>;
    public apiSiteOperativeLoginAttemptGet(odataQuery = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/plain',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<LoginAttempt>>('get',`${this.basePath}/api/site-operative/login-attempt${odataQuery}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param siteOperativeUserId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSiteOperativeProxyLoginSiteOperativeUserIdPost(siteOperativeUserId: number, body?: LocationUpdateModel, observe?: 'body', reportProgress?: boolean): Observable<SiteOperativeLoginResponseModelExecutionResponse>;
    public apiSiteOperativeProxyLoginSiteOperativeUserIdPost(siteOperativeUserId: number, body?: LocationUpdateModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SiteOperativeLoginResponseModelExecutionResponse>>;
    public apiSiteOperativeProxyLoginSiteOperativeUserIdPost(siteOperativeUserId: number, body?: LocationUpdateModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SiteOperativeLoginResponseModelExecutionResponse>>;
    public apiSiteOperativeProxyLoginSiteOperativeUserIdPost(siteOperativeUserId: number, body?: LocationUpdateModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (siteOperativeUserId === null || siteOperativeUserId === undefined) {
            throw new Error('Required parameter siteOperativeUserId was null or undefined when calling apiSiteOperativeProxyLoginSiteOperativeUserIdPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/plain',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SiteOperativeLoginResponseModelExecutionResponse>('post',`${this.basePath}/api/site-operative/proxy-login/${encodeURIComponent(String(siteOperativeUserId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param siteOperativeUserId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSiteOperativeProxyLogoutSiteOperativeUserIdPost(siteOperativeUserId: number, body?: LocationUpdateModel, observe?: 'body', reportProgress?: boolean): Observable<SiteOperativeLoginResponseModelExecutionResponse>;
    public apiSiteOperativeProxyLogoutSiteOperativeUserIdPost(siteOperativeUserId: number, body?: LocationUpdateModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SiteOperativeLoginResponseModelExecutionResponse>>;
    public apiSiteOperativeProxyLogoutSiteOperativeUserIdPost(siteOperativeUserId: number, body?: LocationUpdateModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SiteOperativeLoginResponseModelExecutionResponse>>;
    public apiSiteOperativeProxyLogoutSiteOperativeUserIdPost(siteOperativeUserId: number, body?: LocationUpdateModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (siteOperativeUserId === null || siteOperativeUserId === undefined) {
            throw new Error('Required parameter siteOperativeUserId was null or undefined when calling apiSiteOperativeProxyLogoutSiteOperativeUserIdPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/plain',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SiteOperativeLoginResponseModelExecutionResponse>('post',`${this.basePath}/api/site-operative/proxy-logout/${encodeURIComponent(String(siteOperativeUserId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSiteOperativeRegisterDevicePost(body?: LoginModel, observe?: 'body', reportProgress?: boolean): Observable<LoginResponseModelExecutionResponse>;
    public apiSiteOperativeRegisterDevicePost(body?: LoginModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LoginResponseModelExecutionResponse>>;
    public apiSiteOperativeRegisterDevicePost(body?: LoginModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LoginResponseModelExecutionResponse>>;
    public apiSiteOperativeRegisterDevicePost(body?: LoginModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/plain',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<LoginResponseModelExecutionResponse>('post',`${this.basePath}/api/site-operative/register-device`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param siteOperativeUserId 
     * @param certificateId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdDelete(siteOperativeUserId: number, certificateId: number, observe?: 'body', reportProgress?: boolean): Observable<UserCertificateExecutionResponse>;
     public apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdDelete(siteOperativeUserId: number, certificateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserCertificateExecutionResponse>>;
     public apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdDelete(siteOperativeUserId: number, certificateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserCertificateExecutionResponse>>;
     public apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdDelete(siteOperativeUserId: number, certificateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (siteOperativeUserId === null || siteOperativeUserId === undefined) {
             throw new Error('Required parameter siteOperativeUserId was null or undefined when calling apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdDelete.');
         }
 
         if (certificateId === null || certificateId === undefined) {
             throw new Error('Required parameter certificateId was null or undefined when calling apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdDelete.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json;odata.metadata=minimal;odata.streaming=true',
             'application/json;odata.metadata=minimal;odata.streaming=false',
             'application/json;odata.metadata=minimal',
             'application/json;odata.metadata=full;odata.streaming=true',
             'application/json;odata.metadata=full;odata.streaming=false',
             'application/json;odata.metadata=full',
             'application/json;odata.metadata=none;odata.streaming=true',
             'application/json;odata.metadata=none;odata.streaming=false',
             'application/json;odata.metadata=none',
             'application/json;odata.streaming=true',
             'application/json;odata.streaming=false',
             'application/json',
             'application/xml',
             'application/prs.odatatestxx-odata',
             'text/plain',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<UserCertificateExecutionResponse>('delete',`${this.basePath}/api/site-operative/${encodeURIComponent(String(siteOperativeUserId))}/certificate/${encodeURIComponent(String(certificateId))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param siteOperativeUserId 
      * @param certificateId 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdGet(siteOperativeUserId: number, certificateId: number, observe?: 'body', reportProgress?: boolean): Observable<UserCertificateExecutionResponse>;
     public apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdGet(siteOperativeUserId: number, certificateId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserCertificateExecutionResponse>>;
     public apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdGet(siteOperativeUserId: number, certificateId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserCertificateExecutionResponse>>;
     public apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdGet(siteOperativeUserId: number, certificateId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (siteOperativeUserId === null || siteOperativeUserId === undefined) {
             throw new Error('Required parameter siteOperativeUserId was null or undefined when calling apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdGet.');
         }
 
         if (certificateId === null || certificateId === undefined) {
             throw new Error('Required parameter certificateId was null or undefined when calling apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json;odata.metadata=minimal;odata.streaming=true',
             'application/json;odata.metadata=minimal;odata.streaming=false',
             'application/json;odata.metadata=minimal',
             'application/json;odata.metadata=full;odata.streaming=true',
             'application/json;odata.metadata=full;odata.streaming=false',
             'application/json;odata.metadata=full',
             'application/json;odata.metadata=none;odata.streaming=true',
             'application/json;odata.metadata=none;odata.streaming=false',
             'application/json;odata.metadata=none',
             'application/json;odata.streaming=true',
             'application/json;odata.streaming=false',
             'application/json',
             'application/xml',
             'application/prs.odatatestxx-odata',
             'text/plain',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<UserCertificateExecutionResponse>('get',`${this.basePath}/api/site-operative/${encodeURIComponent(String(siteOperativeUserId))}/certificate/${encodeURIComponent(String(certificateId))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param siteOperativeUserId 
      * @param certificateId 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdPost(siteOperativeUserId: number, certificateId: number, body?: UserCertificateModel, observe?: 'body', reportProgress?: boolean): Observable<UserCertificateExecutionResponse>;
     public apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdPost(siteOperativeUserId: number, certificateId: number, body?: UserCertificateModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserCertificateExecutionResponse>>;
     public apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdPost(siteOperativeUserId: number, certificateId: number, body?: UserCertificateModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserCertificateExecutionResponse>>;
     public apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdPost(siteOperativeUserId: number, certificateId: number, body?: UserCertificateModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (siteOperativeUserId === null || siteOperativeUserId === undefined) {
             throw new Error('Required parameter siteOperativeUserId was null or undefined when calling apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdPost.');
         }
 
         if (certificateId === null || certificateId === undefined) {
             throw new Error('Required parameter certificateId was null or undefined when calling apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdPost.');
         }
 
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json;odata.metadata=minimal;odata.streaming=true',
             'application/json;odata.metadata=minimal;odata.streaming=false',
             'application/json;odata.metadata=minimal',
             'application/json;odata.metadata=full;odata.streaming=true',
             'application/json;odata.metadata=full;odata.streaming=false',
             'application/json;odata.metadata=full',
             'application/json;odata.metadata=none;odata.streaming=true',
             'application/json;odata.metadata=none;odata.streaming=false',
             'application/json;odata.metadata=none',
             'application/json;odata.streaming=true',
             'application/json;odata.streaming=false',
             'application/json',
             'application/xml',
             'application/prs.odatatestxx-odata',
             'text/plain',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'application/json;odata.metadata=minimal;odata.streaming=true',
             'application/json;odata.metadata=minimal;odata.streaming=false',
             'application/json;odata.metadata=minimal',
             'application/json;odata.metadata=full;odata.streaming=true',
             'application/json;odata.metadata=full;odata.streaming=false',
             'application/json;odata.metadata=full',
             'application/json;odata.metadata=none;odata.streaming=true',
             'application/json;odata.metadata=none;odata.streaming=false',
             'application/json;odata.metadata=none',
             'application/json;odata.streaming=true',
             'application/json;odata.streaming=false',
             'application/json',
             'application/xml',
             'application/prs.odatatestxx-odata',
             'text/json',
             'application/_*+json'
         ];
         const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
         if (httpContentTypeSelected != undefined) {
             headers = headers.set('Content-Type', httpContentTypeSelected);
         }
 
         return this.httpClient.request<UserCertificateExecutionResponse>('post',`${this.basePath}/api/site-operative/${encodeURIComponent(String(siteOperativeUserId))}/certificate/${encodeURIComponent(String(certificateId))}`,
             {
                 body: body,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     /**
      * 
      * 
      * @param siteOperativeUserId 
      * @param certificateId 
      * @param body 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
      public apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdPut(siteOperativeUserId: number, certificateId: number, body?: UserCertificateModel, observe?: 'body', reportProgress?: boolean): Observable<UserCertificateExecutionResponse>;
      public apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdPut(siteOperativeUserId: number, certificateId: number, body?: UserCertificateModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserCertificateExecutionResponse>>;
      public apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdPut(siteOperativeUserId: number, certificateId: number, body?: UserCertificateModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserCertificateExecutionResponse>>;
      public apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdPut(siteOperativeUserId: number, certificateId: number, body?: UserCertificateModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
  
          if (siteOperativeUserId === null || siteOperativeUserId === undefined) {
              throw new Error('Required parameter siteOperativeUserId was null or undefined when calling apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdPost.');
          }
  
          if (certificateId === null || certificateId === undefined) {
              throw new Error('Required parameter certificateId was null or undefined when calling apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdPost.');
          }
  
  
          let headers = this.defaultHeaders;
  
          // to determine the Accept header
          let httpHeaderAccepts: string[] = [
              'application/json;odata.metadata=minimal;odata.streaming=true',
              'application/json;odata.metadata=minimal;odata.streaming=false',
              'application/json;odata.metadata=minimal',
              'application/json;odata.metadata=full;odata.streaming=true',
              'application/json;odata.metadata=full;odata.streaming=false',
              'application/json;odata.metadata=full',
              'application/json;odata.metadata=none;odata.streaming=true',
              'application/json;odata.metadata=none;odata.streaming=false',
              'application/json;odata.metadata=none',
              'application/json;odata.streaming=true',
              'application/json;odata.streaming=false',
              'application/json',
              'application/xml',
              'application/prs.odatatestxx-odata',
              'text/plain',
              'text/json'
          ];
          const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
          if (httpHeaderAcceptSelected != undefined) {
              headers = headers.set('Accept', httpHeaderAcceptSelected);
          }
  
          // to determine the Content-Type header
          const consumes: string[] = [
              'application/json;odata.metadata=minimal;odata.streaming=true',
              'application/json;odata.metadata=minimal;odata.streaming=false',
              'application/json;odata.metadata=minimal',
              'application/json;odata.metadata=full;odata.streaming=true',
              'application/json;odata.metadata=full;odata.streaming=false',
              'application/json;odata.metadata=full',
              'application/json;odata.metadata=none;odata.streaming=true',
              'application/json;odata.metadata=none;odata.streaming=false',
              'application/json;odata.metadata=none',
              'application/json;odata.streaming=true',
              'application/json;odata.streaming=false',
              'application/json',
              'application/xml',
              'application/prs.odatatestxx-odata',
              'text/json',
              'application/_*+json'
          ];
          const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
          if (httpContentTypeSelected != undefined) {
              headers = headers.set('Content-Type', httpContentTypeSelected);
          }
  
          return this.httpClient.request<UserCertificateExecutionResponse>('put',`${this.basePath}/api/site-operative/${encodeURIComponent(String(siteOperativeUserId))}/certificate/${encodeURIComponent(String(certificateId))}`,
              {
                  body: body,
                  withCredentials: this.configuration.withCredentials,
                  headers: headers,
                  observe: observe,
                  reportProgress: reportProgress
              }
          );
      }
 
     /**
      * 
      * 
      * @param siteOperativeUserId 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiSiteOperativeSiteOperativeUserIdCertificateGet(siteOperativeUserId: number, observe?: 'body', reportProgress?: boolean): Observable<UserCertificateListExecutionResponse>;
     public apiSiteOperativeSiteOperativeUserIdCertificateGet(siteOperativeUserId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserCertificateListExecutionResponse>>;
     public apiSiteOperativeSiteOperativeUserIdCertificateGet(siteOperativeUserId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserCertificateListExecutionResponse>>;
     public apiSiteOperativeSiteOperativeUserIdCertificateGet(siteOperativeUserId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (siteOperativeUserId === null || siteOperativeUserId === undefined) {
             throw new Error('Required parameter siteOperativeUserId was null or undefined when calling apiSiteOperativeSiteOperativeUserIdCertificateGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json;odata.metadata=minimal;odata.streaming=true',
             'application/json;odata.metadata=minimal;odata.streaming=false',
             'application/json;odata.metadata=minimal',
             'application/json;odata.metadata=full;odata.streaming=true',
             'application/json;odata.metadata=full;odata.streaming=false',
             'application/json;odata.metadata=full',
             'application/json;odata.metadata=none;odata.streaming=true',
             'application/json;odata.metadata=none;odata.streaming=false',
             'application/json;odata.metadata=none',
             'application/json;odata.streaming=true',
             'application/json;odata.streaming=false',
             'application/json',
             'application/xml',
             'application/prs.odatatestxx-odata',
             'text/plain',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<UserCertificateListExecutionResponse>('get',`${this.basePath}/api/site-operative/${encodeURIComponent(String(siteOperativeUserId))}/certificate`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

}
