import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Module } from 'src/services-generated/model/moduleModel';
import { UserModule } from 'src/services-generated/model/UserModule.Model';
import { environment } from '@environments/environment';
import { BASE_PATH, Configuration } from 'src/services-generated';
@Injectable({
  providedIn: 'root'
})

export class ModuleService {

  public basePath = environment.basePath;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
        this.basePath = basePath;
    }
    if (configuration) {
        this.configuration = configuration;
        this.basePath = basePath || configuration.basePath || this.basePath;
    }
  } 
  public getModules(): Observable<Module[]> {

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
            ? this.configuration.accessToken()
            : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get',`${this.basePath}/api/module/modules`,
        {
            withCredentials: this.configuration.withCredentials,
            headers: headers
        }
    );
  }

}
