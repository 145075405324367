import { Component, OnInit } from '@angular/core';
import { report_data } from '../report-home';
import { AuthenticationService } from '@app/_services/authentication.service';

@Component({
  selector: 'app-report-home',
  templateUrl: './report-home.component.html',
  styleUrls: ['./report-home.component.scss']
})
export class ReportHomeComponent implements OnInit {

  cards = null;

  constructor(private authenticationService: AuthenticationService ) { }

  ngOnInit() {
    const currentUser = this.authenticationService.currentUserValue;
    this.cards = report_data.filter(function(element){
      if(currentUser['userModules'].find(x => x.moduleName == element.title).isRead)
        return element;
    });
  }

}
