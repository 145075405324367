  import { TypeScriptEmitter } from '@angular/compiler';
  import { Component, OnInit, ViewChild } from '@angular/core';
  import { FormBuilder, FormGroup, Validators } from '@angular/forms';
  import { MatPaginator, PageEvent } from '@angular/material/paginator';
  import { MatTableDataSource } from '@angular/material/table';
  import { ActivatedRoute, Router } from '@angular/router';
  import { Role } from '@app/_models';
  import { AccountService, SiteOperativeService, User } from 'src/services-generated';
  import { SiteOperativeModel } from 'src/services-generated/model/siteOperativeModel';

@Component({
  selector: 'app-siteoperative-proxy-list',
  templateUrl: './siteoperative-proxy-list.component.html',
  styleUrls: ['./siteoperative-proxy-list.component.scss']
})
export class SiteOperativeProxyListComponent implements OnInit {
  loading = false;
  // totalSiteOperatives = 30;
  pageSize = 5;
  siteOperativeForm: FormGroup;
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  displayedColumns: string[] = ["type", "firstName", "lastName", "email", "phoneNumber", "sentinelNumber", "createdAt", "createdBy"];
  siteOperatives: MatTableDataSource<any> = new MatTableDataSource<any>();
  error = '';
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private siteOperativeService: SiteOperativeService) { }

  ngOnInit() {
    this.siteOperativeService.apiSiteOperativeProxyGet(new Date()).subscribe((proxyLoginList)=>{
      let siteOperatives = proxyLoginList.map((loginItem)=>{
        return {
          id: loginItem.id,
          firstName: loginItem.user.firstName,
          lastName: loginItem.user.lastName,
          phoneNumber: loginItem.user.phoneNumber,
          isProxyLogin: loginItem.isProxyLogin,
          email: loginItem.user.email,
          sentinelNumber: loginItem.user.siteOperativeDetail.sentinelNumber,
          createdAt: loginItem.createdAt,
          createdBy: loginItem.createdBy.email
        }
      })
      this.siteOperatives.data = siteOperatives;
      this.paginator.pageIndex = 0;
      // this.paginator.length = 30;
      this.siteOperatives.paginator = this.paginator;
    });
  }


}
