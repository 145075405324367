import { Component, OnInit, ViewChild } from '@angular/core';
  import { ActivatedRoute, Router } from '@angular/router';
  import { LoginQuestion, LoginQuestionService, ReportService, SiteOperativeService, UserLocation } from 'src/services-generated';
  import { MatPaginator } from '@angular/material/paginator';
  import { MatTableDataSource } from '@angular/material/table';
  import { CloseCall } from 'src/services-generated/model/closeCall';
  import { CloseCallService } from 'src/services-generated/api/closeCall.service';
  import { FormControl } from '@angular/forms';
  import { LocationService } from 'src/services-generated/api/location.service';
  import { MatSelectChange } from '@angular/material/select';
  import f from 'odata-filter-builder';
import { UserLocationService } from 'src/services-generated/api/userLocation.service';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { environment } from '@environments/environment';
import {Observable, Subscription} from 'rxjs';
import { LoginCount } from 'src/services-generated/model/loginCount';
import { AuthenticationService } from '@app/_services';
import { UserModule } from '../../../services-generated/model/UserModule.Model';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.scss']
})
export class LiveComponent implements OnInit {
    stateLoading = false; 
    @ViewChild(MatPaginator, null) paginator: MatPaginator;
    displayedColumns: string[] = ["certificates", "firstName", "lastName", "email", "phoneNumber", "location", "city", "state", "postalCode", "sentinelNumber", "homePostCode", "businessDeliveryUnit", "createdAt"];
    siteOperatives: MatTableDataSource<UserLocation> = new MatTableDataSource<UserLocation>();
    siteOperativeCount:Number;
    apiCloseCallGetCountSubs: Subscription;
    apiUserLocationGetSubscription: Subscription;
    apiCloseCallServiceSubscription: Subscription;
    loginCountData: LoginCount = {
      loggedInToday : 0,
      loggedInNow : 0,
      loggedOutToday : 0
    };
    lat = 55.861606;
    lng = -4.250313;
    country = new FormControl();
    state = new FormControl([]);
    city = new FormControl();
    firstName = new FormControl();
    postalCode = new FormControl();
    sentinelNumber = new FormControl();
    businessDeliveryUnit = new FormControl();
    countries = ['UK'];
    states = [];
    cities = [];
    filteredStates: Observable<string[]>;
    odataQuery = "";
    markers: any[] = [];
    locationOdataQuery = "?$top=1000&$format=json&$orderby=createdAt desc&$filter=" + f.and().ne('city', null, false)
  .ne('state', null, false);
    userRights: UserModule;
    constructor(
      private route: ActivatedRoute,
      private router: Router,
      private datePipe: DatePipe,
      private userLocationService: UserLocationService,
      private reportService: ReportService,
      private locationService: LocationService,
      private siteOperativeService: SiteOperativeService,
      private authenticationService: AuthenticationService) { }

    ngOnInit() {
      this.country.setValue('UK');
      this.userRights = this.authenticationService.getUserRights("live");
      this.apiUserLocationGetSubscription = this.userLocationService.apiUserLocationGet(this.odataQuery).subscribe((userLocationResponse)=>{
        this.siteOperatives.data = [...userLocationResponse];
        this.markers = userLocationResponse.map(userLocation => {
          return {
            lat: userLocation.location.latitude,
            lng: userLocation.location.longitude,
            label: "",
            draggable: false,
            title: userLocation.user.email,
            description: userLocation.user.siteOperativeDetail.sentinelNumber,
            reportedBy: userLocation.createdAt
          }
        });
        this.lat = this.markers[this.markers.length-1]!= null ? this.markers[this.markers.length-1].lat : 0;
        this.lng = this.markers[this.markers.length-1]!= null ? this.markers[this.markers.length-1].lng : 0;
        this.paginator.pageIndex = 0;
        this.siteOperatives.paginator = this.paginator;
      });
      this.filteredStates = this.state.valueChanges.pipe(
        switchMap(async (value) => await this._filter(value))
      );
      this.siteOperativeService.apiSiteOperativeLoginCountGet().subscribe(countResponse => {
        this.loginCountData = countResponse;
      })
      //in some time get User locations again
      setTimeout(() => {
        if(this.router.url.includes("dashboard/live")){
          this.ngOnInit();
        }
      }, environment.refreshRate);
    }

    ngOnDestroy() {
      this.apiUserLocationGetSubscription.unsubscribe();
    }

    private async _filter(value: any): Promise<string[]> {
      if(typeof(value) != 'string') {
        value = "";
      }
      const filterValue = value.toLowerCase();
      this.stateLoading = true;
      this.locationOdataQuery = "?$top=1000&$format=json&$orderby=createdAt desc&$filter=" + f.and().ne('city', null, false)
      .ne('state', null, false) + `and contains(state, '${filterValue}')`
      let locations = await this.locationService.apiLocationGet(this.locationOdataQuery).toPromise();
      this.stateLoading = false;
      if(locations) {
        this.states = [...new Set(locations.map(location => location.state))];
        this.cities = [...new Set(locations.filter(location =>
          {
            if(this.state.value.length != 0){
              return this.state.value.includes(location.state)
            } else {
              return true;
            }
          }
        ).map(location => location.city))];
        return this.states.filter(state => state.toLowerCase().includes(filterValue));
      }
    }

    handleStateChange(event: MatSelectChange){
      console.log(this.state.value);
      let filterString = f('and')
      .in('location/state', this.state.value)
      .in('location/city', this.city.value)
      .toString();
      this.odataQuery = '?$format=json&$orderby=createdAt desc&$filter=' + filterString
      if(filterString == ""){
        this.odataQuery = "";
      }

    }

    handleCityChange(event: MatSelectChange){
      console.log(this.city.value);
      let filterString = f('and')
      .in('location/state', this.state.value)
      .in('location/city', this.city.value)
      .toString();
      this.odataQuery = '?$format=json&$orderby=createdAt desc&$filter=' + filterString
      if(filterString == ""){
        this.odataQuery = "";
      }
    }

    handleFilterChange(event: MatSelectChange){
      let filterString = f.and()
        .in('location/state', this.state.value)
        .in('location/city', this.city.value)
        // .contains('user/firstName' , this.firstName.value ? this.firstName.value : "")
        .and(f.or()
          .contains('user/firstName' , this.firstName.value? this.firstName.value : "")
          .contains('user/lastName' , this.firstName.value? this.firstName.value : "")
        )
        .contains('user/siteOperativeDetail/sentinelNumber' , this.sentinelNumber.value? this.sentinelNumber.value : "")
        .contains('location/postalCode' , this.postalCode.value? this.postalCode.value : "")
        .contains('user/siteOperativeDetail/businessDeliveryUnit' , this.businessDeliveryUnit.value? this.businessDeliveryUnit.value : "")
        .toString();
      this.odataQuery = '?$format=json&$orderby=createdAt desc&$filter=' + filterString
      if(filterString == ""){
        this.odataQuery = "";
      }
    }

    // mapClicked($event: MouseEvent) {
    //   this.markers.push({
    //     lat: $event.coords.lat,
    //     lng: $event.coords.lng,
    //     label: 'C',
    //     draggable: true
    //   });
    // }

    clickedMarker(label: string, index: number) {
      console.log(`clicked the marker: ${label || index}`)
    }

    exportTableAsExcel() {
      let exportArray = [];
      this.siteOperatives.data.forEach(siteOperative => {
        let siteOperativeToExport = {
          "First Name": siteOperative.user.firstName,
          "Last Name": siteOperative.user.lastName,
          "Email": siteOperative.user.email,
          "Phone Number": siteOperative.user.phoneNumber,
          "Location": siteOperative.location.label,
          "City": siteOperative.location.city,
          "State": siteOperative.location.state,
          "Postal Code": siteOperative.location.postalCode,
          "Sentinel Number": siteOperative.user.siteOperativeDetail.sentinelNumber,
          "Home PostCode": siteOperative.user.siteOperativeDetail.homePostCode,
          "Business Delivery Unit": siteOperative.user.siteOperativeDetail.businessDeliveryUnit,
          "Created At": siteOperative.createdAt
        }
        exportArray.push(siteOperativeToExport);
      });
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportArray);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      /* save to file */
      let currentTimestamp = new Date().toString();
      currentTimestamp = this.datePipe.transform(Date.now(),'yyyyMMdd_mmss');
      XLSX.writeFile(wb,  'siteoperatives_' + currentTimestamp + '.xlsx');
    }

    exportExcelReport(){
      this.reportService.timesheetDateDownloadGet(new Date()).subscribe(response => {
        let date = new Date();
        const blob = new Blob([response], { type: 'application/octet-stream' });
        const url= window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = `timesheet_${date}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      })
    }

    onMapReady(map) {
      map.setOptions({
          fullscreenControl: 'true',
          fullscreenControlOptions: {
              position: 7
          }
    });
  }
  }

