import { MouseEvent } from "@agm/core";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { Role } from "@app/_models";
import { AccountService, SiteOperativeService } from "src/services-generated";
import { LocationService } from "src/services-generated/api/location.service";
import { LocationUpdateModel } from "src/services-generated/model/locationUpdateModel";
import f from "odata-filter-builder";
import { map, startWith } from "rxjs/operators";
import { Observable } from "rxjs";

@Component({
  selector: "app-siteoperative-login",
  templateUrl: "./siteoperative-login.component.html",
  styleUrls: ["./siteoperative-login.component.scss"],
})
export class SiteOperativeLoginComponent implements OnInit {
  loading = false;
  lat = 51.864722;
  long = -6.250650;
  siteOperatives = [];
  filteredSiteOperatives: Observable<string[]>;
  markers = [];
  siteOperative = new FormControl("", Validators.required);
  error = "";
  odataQuery =
    "?$filter=" +
    f
      .and()
      .contains("role/name", "SiteOperative")
      .eq("isActive", true)
      .toString();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private siteOperativeService: SiteOperativeService,
    private accountService: AccountService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.accountService
      .apiAccountGet(this.odataQuery)
      .subscribe((accountResponse) => {
        console.log("accountResponse:", accountResponse);
        let siteOperatives = accountResponse.map((siteOperative) => {
          return {
            id: siteOperative["id"],
            sentinelNumber:
              siteOperative["siteOperativeDetail"] != null
                ? siteOperative["siteOperativeDetail"]["sentinelNumber"]
                : "",
            email: siteOperative["email"],
          };
        });
        this.siteOperatives = siteOperatives;
      });
    this.filteredSiteOperatives = this.siteOperative.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.siteOperatives.filter((siteOperative) => {
      if (siteOperative.email && siteOperative.sentinelNumber) {
        return (
          siteOperative.email.toLowerCase().indexOf(filterValue) === 0 ||
          siteOperative.sentinelNumber.toLowerCase().indexOf(filterValue) === 0
        );
      } else {
        return false;
      }
    });
  }

  onSubmit() {
    console.log(this.siteOperative.value);
    // stop here if values are invalid
    if (this.siteOperative.invalid) {
      this.siteOperative.markAsDirty();
      return;
    }
    let location: LocationUpdateModel = {
      latitude: this.markers[0].lat,
      longitude: this.markers[0].lng,
    };
    let selectedSiteOperative = this.siteOperatives.find((siteOperative) => {
      return siteOperative.email == this.siteOperative.value;
    });
    this.loading = true;
    this.siteOperativeService
      .apiSiteOperativeProxyLoginSiteOperativeUserIdPost(
        selectedSiteOperative.id,
        location
      )
      .subscribe(
        (siteOperativeResponse) => {
          this.loading = false;
          if (siteOperativeResponse.isSuccessful) {
            this.snackBar.open(siteOperativeResponse.message, "OK", {
              duration: 3000,
            });
            this.router.navigate([
              "/supervisor/dashboard/proxy-authentication/list",
            ]);
          } else {
            this.snackBar.open(siteOperativeResponse.message, "OK", {
              duration: 3000,
            });
          }
        },
        (error) => {
          console.log(error);
          this.snackBar.open(error, "OK", {
            duration: 3000,
          });
          this.loading = false;
        }
      );
  }

  mapClicked($event: MouseEvent) {
    this.markers = [];
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      label: "L",
      draggable: true,
    });
  }
}
