export * from './account.service';
import { AccountService } from './account.service';
export * from './administrator.service';
import { AdministratorService } from './administrator.service';
export * from './closeCall.service';
import { CloseCallService } from './closeCall.service';
export * from './document.service';
import { DocumentService } from './document.service';
export * from './location.service';
import { LocationService } from './location.service';
export * from './loginQuestion.service';
import { LoginQuestionService } from './loginQuestion.service';
export * from './mobileNotification.service';
import { MobileNotificationService } from './mobileNotification.service';
export * from './siteOperative.service';
import { SiteOperativeService } from './siteOperative.service';
export * from './supervisor.service';
import { SupervisorService } from './supervisor.service';
export * from './userLocation.service';
import { UserLocationService } from './userLocation.service';
export * from './report.service';
import { ReportService } from './report.service';
export const APIS = [AccountService, AdministratorService, CloseCallService, DocumentService, LocationService, LoginQuestionService, MobileNotificationService, SiteOperativeService, SupervisorService, UserLocationService, ReportService];
