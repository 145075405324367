import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import * as XLSX from 'xlsx';
import { RuleBookService } from 'src/services-generated/api/ruleBook.service';
import { UserRuleBookModel } from 'src/services-generated/api/userRuleBookModel';


@Component({
  selector: 'app-rule-book-user-details-list',
  templateUrl: './rule-book-user-details-list.component.html',
  styleUrls: ['./rule-book-user-details-list.component.scss']
})
export class RuleBookUserDetailsListComponent implements OnInit {

  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  displayedColumns: string[] = ["name", "email", "isRuleBookViewed", "phone", "role"];
  ruleBookUsers: MatTableDataSource<UserRuleBookModel> = new MatTableDataSource<UserRuleBookModel>();
  ruleBookUsersList: UserRuleBookModel[];
  roles:string[] = ["All","Administrator","SiteOperative","Supervisor"]
  id:number;
  ruleBookName:string;
  isViewedMenu:string[] = ["All","True","False"]
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private ruleBookService: RuleBookService) { }

  ngOnInit() {
    this.id = parseInt(this.route.snapshot.paramMap.get('id')) || 1;
    this.route.queryParams.subscribe((queryParam) => {
      this.ruleBookName = queryParam.name;
    });
    this.ruleBookService.apiRuleBookIdConfirmViewedGet(this.id).subscribe(response => {
      this.ruleBookUsers.data = [...response.result];
      this.ruleBookUsersList = [...response.result];
      this.paginator.pageIndex = 0;
      this.ruleBookUsers.paginator = this.paginator;
    })
  }

  onRoleChange(tag:string){
    if(tag.toLowerCase() == "all"){
      return this.ruleBookUsers.data = this.ruleBookUsersList; 
    }
    this.ruleBookUsers.data = this.ruleBookUsersList.filter(ruleBook => {
      return ruleBook.role.toLowerCase().includes(tag.toLowerCase());
    }) 
  }
  onUsernameChange(value:string){
    if(value.toLowerCase() == ""){
      return this.ruleBookUsers.data = this.ruleBookUsersList; 
    }
    this.ruleBookUsers.data = this.ruleBookUsersList.filter(ruleBook => {
      return ruleBook.firstName.toLowerCase().includes(value.toLowerCase());
    }) 
  }

  onIsViewedChange(input:string){
    if(input.toLowerCase() == "all"){
      return this.ruleBookUsers.data = this.ruleBookUsersList; 
    }
    this.ruleBookUsers.data = this.ruleBookUsersList.filter(ruleBook => {
      if(input.toLowerCase() == "true"){
        return ruleBook.isRuleBookViewed;
      }
      else{
        return !ruleBook.isRuleBookViewed;
      }
    }) 
  }
  openRuleBookUserList(id){
    this.router.navigate([`/supervisor/dashboard/rule-book-list/${id}`])
  }

  exportTableAsExcel() {
    let exportArray = [];
    this.ruleBookUsers.data.forEach(userRuleBook => {
      let userRuleBookToExport = {
        "name": userRuleBook.firstName + (userRuleBook.lastName ? " " + userRuleBook.lastName : ""),
        "email": userRuleBook.email,
        "isRuleBookViewed": userRuleBook.isRuleBookViewed,
        "phone": userRuleBook.phoneNumber,
        "role": userRuleBook.role
      }
      exportArray.push(userRuleBookToExport);
    });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportArray)
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, this.ruleBookName);
    /* save to file */
    let currentTimestamp = new Date().toString();
    currentTimestamp = this.datePipe.transform(Date.now(),'yyyyMMdd_mmss');
    XLSX.writeFile(wb,  'ruleBooks_' + currentTimestamp + '.xlsx');
  }
}
