import { MouseEvent } from '@agm/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, SiteOperativeService } from 'src/services-generated';
import { LocationUpdateModel } from 'src/services-generated/model/locationUpdateModel';
import f from 'odata-filter-builder';
import {Moment} from 'moment';
import * as moment from 'moment';
import { InputPageModel, InputPageSectionModel, InputPageService } from 'src/report-services-generated';

@Component({
  selector: 'app-monthly-data-view',
  templateUrl: './monthly-data-view.component.html',
  styleUrls: ['./monthly-data-view.component.scss']
})
export class MonthlyDataViewComponent implements OnInit {

  loading = false;
  dateRange = new FormControl();
  pageData: InputPageModel= {};
  laggingTypeFields:any[] = [];
  leadingTypeFields:any[] = [];
  requestBody:any= {};
  dateRangeSelected: {startDate: Moment, endDate: Moment} = {
    startDate: moment().add(-1, 'days'),
    endDate: moment()
  }
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  lat = 51.864722;
  long = -6.250650;
  siteOperatives = []
  markers = [];
  siteOperative = new FormControl('', Validators.required);
  error = '';
  firstName = new FormControl();
  sentinelNumber = new FormControl();
  restrictUpdate = false;
  odataQuery = '?$filter=' +  f.and().contains('role/name', 'SiteOperative').toString();
  constructor(private route: ActivatedRoute,
    private router: Router,
    private siteOperativeService: SiteOperativeService,
    private accountService: AccountService,
    private snackBar: MatSnackBar,
    private inputPageService: InputPageService) { }

    ngOnInit() {
      let startDateTimeAdjusted = this.dateRangeSelected.startDate.toDate();
      startDateTimeAdjusted.setTime(startDateTimeAdjusted.getTime() - (startDateTimeAdjusted.getTimezoneOffset()*60*1000));
      this.inputPageService.apiInputPageIdRecordDateGet(2,startDateTimeAdjusted).subscribe(pageResponse => {
        console.log(pageResponse);
        this.pageData = pageResponse;
        this.pageData.sections.forEach(section => {
          section.fieldValues.forEach(field => {
            this.requestBody[field.id] = field.value
          });
        });
        this.laggingTypeFields = this.filterSections("Lagging");
        this.leadingTypeFields = this.filterSections("Leading");
        
      })
      console.log(this.laggingTypeFields);
      if(Math.abs(moment(this.dateRangeSelected.startDate.toDate()).diff(moment())/(1000*60*60*24)) > 62 ) {
        this.restrictUpdate = true;
      } else {
        this.restrictUpdate = false; 
      }
    }
  
    logRequestBody(){
      console.log(this.requestBody)
    }
  
    filterSections(indicatorType){
      let sections = [];
      this.pageData.sections.forEach(section => {
        let filteredFields = [];
        filteredFields = section.fieldValues.filter(field => {
          return field.indicatorType == indicatorType;
        })
        
        if(filteredFields.length > 0){
          let filteredSection:InputPageSectionModel = {};
          filteredSection.fieldValues = filteredFields;
          filteredSection.name = section.name;
          filteredSection.sequenceNumber = section.sequenceNumber;
          sections.push(filteredSection);
        }
      })
      return sections;
    }
  
    onSubmit() {
      let fields = [];
      Object.keys(this.requestBody).forEach(fieldId => {
        if(this.requestBody[fieldId]){
          fields.push({
            id: parseInt(fieldId),
            value: this.requestBody[fieldId]
          })
        }
        else{
          fields.push({
            id: parseInt(fieldId),
            value: 0
          })
        }
      });
      let startDateTimeAdjusted = this.dateRangeSelected.startDate.toDate()
      startDateTimeAdjusted.setTime(startDateTimeAdjusted.getTime() - (startDateTimeAdjusted.getTimezoneOffset()*60*1000))
      this.inputPageService.apiInputPageIdRecordPut(2,{
        fields:fields,
        date: startDateTimeAdjusted.toISOString().split('T')[0]
      }).subscribe(fieldSubmitResponse => {
        console.log(fieldSubmitResponse);
        if(fieldSubmitResponse.isSuccessful){
          this.snackBar.open("Success!","Ok",{
            duration:3000
          });
        }
        else{
          this.snackBar.open(fieldSubmitResponse.message,"Ok",{
            duration:3000
          });
        }
      })
    }
    
  
    handleFilterChange(){
      this.laggingTypeFields = [];
      this.leadingTypeFields = [];
      this.ngOnInit();
    }
}
