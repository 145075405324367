import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {CloseCall, LocationService, UserLocation, UserLocationService} from '../../../services-generated';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import * as XLSX from 'xlsx';
import { RuleBookService } from 'src/services-generated/api/ruleBook.service';
import { RuleBook } from 'src/services-generated/model/ruleBook';
import { AuthenticationService } from '@app/_services';
import { UserModule } from 'src/services-generated/model/UserModule.Model';

@Component({
  selector: 'app-rule-book-user-list',
  templateUrl: './rule-book-user-list.component.html',
  styleUrls: ['./rule-book-user-list.component.scss']
})
export class RuleBookUserListComponent implements OnInit {
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  displayedColumns: string[] = ["name", "size", "tags", "isActive", "createdBy"];
  ruleBooks: MatTableDataSource<RuleBook> = new MatTableDataSource<RuleBook>();
  Tags:string[] = ["All","General","Repair","Overhaul","Safety"];
  isActiveMenu:string[] = ["All","True","False"];
  userRights: UserModule;
  ruleBookList: RuleBook[];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private ruleBookService: RuleBookService,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.userRights = this.authenticationService.getUserRights("rule");
    this.ruleBookService.apiRuleBookGet().subscribe(response => {
      this.ruleBooks.data = [...response];
      this.ruleBookList = [...response];
      this.paginator.pageIndex = 0;
      this.ruleBooks.paginator = this.paginator;
    })
  }

  onTagChange(tag:string){
    if(tag.toLowerCase() == "all"){
      return this.ruleBooks.data = this.ruleBookList; 
    }
    this.ruleBooks.data = this.ruleBookList.filter(ruleBook => {
      return ruleBook.tags.toLowerCase().includes(tag.toLowerCase());
    }) 
  }
  onRuleBookNameChange(value:string){
    if(value.toLowerCase() == ""){
      return this.ruleBooks.data = this.ruleBookList; 
    }
    this.ruleBooks.data = this.ruleBookList.filter(ruleBook => {
      return ruleBook.name.toLowerCase().includes(value.toLowerCase());
    }) 
  }

  onIsActiveChange(input:string){
    if(input.toLowerCase() == "all"){
      return this.ruleBooks.data = this.ruleBookList; 
    }
    this.ruleBooks.data = this.ruleBookList.filter(ruleBook => {
      if(input.toLowerCase() == "true"){
        return ruleBook.isActive;
      }
      else{
        return !ruleBook.isActive;
      }
    }) 
  }

  openRuleBookUserList(id, name){
    this.router.navigate([`/supervisor/dashboard/rule-book-list/${id}`], { queryParams: { name: name } });
  }

  exportTableAsExcel() {
    let exportArray = [];
    this.ruleBooks.data.forEach(ruleBook => {
      let ruleBookToExport = {
        "Name": ruleBook.name,
        "Size": ruleBook.size,
        "Tags": ruleBook.tags,
        "Is Active": ruleBook.isActive,
        "Created By": ruleBook.createdBy == null ? "N/A" : ruleBook.createdBy.firstName + (ruleBook.createdBy.lastName ? " " + ruleBook.createdBy.lastName : ""),
      }
      exportArray.push(ruleBookToExport);
    });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportArray)
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Rule Books');
    /* save to file */
    let currentTimestamp = new Date().toString();
    currentTimestamp = this.datePipe.transform(Date.now(),'yyyyMMdd_mmss');
    XLSX.writeFile(wb,  'ruleBooks_' + currentTimestamp + '.xlsx');
  }
}
