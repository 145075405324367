/**
 * worksite_track
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

 import { Inject, Injectable, Optional }                      from '@angular/core';
 import { HttpClient, HttpHeaders, HttpParams,
          HttpResponse, HttpEvent }                           from '@angular/common/http';
 import { CustomHttpUrlEncodingCodec }                        from '../encoder';
 
 import { Observable }                                        from 'rxjs';
 
 
 import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
 import { Configuration }                                     from '../configuration';
 import { environment } from '../../environments/environment'
 
 @Injectable()
 export class ReportService {
 
    public basePath = environment.basePath;
     public defaultHeaders = new HttpHeaders();
     public configuration = new Configuration();
 
     constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
         if (basePath) {
             this.basePath = basePath;
         }
         if (configuration) {
             this.configuration = configuration;
             this.basePath = basePath || configuration.basePath || this.basePath;
         }
     }
 
     /**
      * @param consumes string[] mime-types
      * @return true: consumes contains 'multipart/form-data', false: otherwise
      */
     private canConsumeForm(consumes: string[]): boolean {
         const form = 'multipart/form-data';
         for (const consume of consumes) {
             if (form === consume) {
                 return true;
             }
         }
         return false;
     }
 
 
     /**
      * 
      * 
      * @param date 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public timesheetDateDownloadGet(date: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public timesheetDateDownloadGet(date: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public timesheetDateDownloadGet(date: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public timesheetDateDownloadGet(date: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (date === null || date === undefined) {
             throw new Error('Required parameter date was null or undefined when calling timesheetDateDownloadGet.');
         }

         if (date !== undefined && date !== null) {
            date = <any>date.toISOString();
        }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
         headers = headers.set('Accept', "application/octet-stream");
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
         let options = {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        }
         return this.httpClient.get(`${this.basePath}/timesheet/${date}/download`,
             {...options, responseType: 'blob'},
         );
     }

     /**
      * 
      * 
      * @param date 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public documentTypeDownloadGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
     public documentTypeDownloadGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public documentTypeDownloadGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public documentTypeDownloadGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
         headers = headers.set('Accept', "application/octet-stream");
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
         let options = {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        }
         return this.httpClient.get(`${this.basePath}/safety-document/viewed/download`,
             {...options, responseType: 'blob'},
         );
     }
 
 }
 