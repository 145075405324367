import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CompactType, GridsterConfig, GridsterItem, GridType } from 'angular-gridster2';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { DashboardService, GraphTypeService, InputPageService } from 'src/report-services-generated';
import { FieldValueService } from 'src/report-services-generated/api/fieldValue.service';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import * as moment from 'moment';
import { FieldCommentService } from 'src/report-services-generated/api/fieldComment.service';
import { FieldCommentModel } from 'src/report-services-generated/model/fieldCommentModel';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-view-dashboard',
  templateUrl: './view-dashboard.component.html',
  styleUrls: ['./view-dashboard.component.scss']
})
export class ViewDashboardComponent implements OnInit {
 
  constructor(
    private fieldValueService: FieldValueService,
    private inputPageService: InputPageService,
    private dashboardService: DashboardService,
    private fieldCommentService: FieldCommentService,
    private _snackBar: MatSnackBar

  ) { }

  options: GridsterConfig;
  dashboard: Array<GridsterItem>;
  filteredOptions: Observable<any>;
  myControl = new FormControl();
  fields: any[] = [];
  weeklyInputId:number = 1;
  monthlyInputId:number = 2;
  commentList: any[] = [];
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  dateRangeSelected: {startDate: moment.Moment, endDate: moment.Moment} = {
    startDate: moment().add(-28, 'days'),
    endDate: moment()
  }


  ngOnInit() {
    this.inputPageService.apiInputPageIdGet(this.weeklyInputId).subscribe(res => {
      res.sections.forEach(section => {
        this.fields.push(...section.fields);
      });      
    })
    this.inputPageService.apiInputPageIdGet(this.monthlyInputId).subscribe(res => {
      res.sections.forEach(section => {
        this.fields.push(...section.fields);
      });
    });
    this.getComment();
    this.options = {
      gridType: GridType.ScrollVertical,
      compactType: CompactType.None,
      minCols: 15,
      maxCols: 15,
      minRows: 1,
      defaultItemCols: 1,
      defaultItemRows: 1,
      maxItemArea: 100
    };
    this.dashboardService.apiDashboardGet().subscribe(dashboardListResponse => {
      if(dashboardListResponse.length > 0) {
        this.dashboardService.apiDashboardIdGet(dashboardListResponse[dashboardListResponse.length-1].id).subscribe(dashboardResponse => {
          let dashboardJSON = dashboardResponse[dashboardListResponse.length-1].dashboardPageDetails.map(dashboardPage => {
            
            return {
              chartType: {name: dashboardPage.graphType.name.toLowerCase(), id: dashboardPage.graphType.id},
              cols: dashboardPage.columnSpan,
              indicator: [{
                id: dashboardPage.field.id,
                indicatorType: dashboardPage.field.indicatorType,
                name: dashboardPage.field.name,
              }],
              rows: dashboardPage.rowSpan,
              x: dashboardPage.x,
              y: dashboardPage.y,
              dataSet: [],
              chartLabels: [],
              isCommentBoxActive: false,
              fieldComment: this.commentList.find(comment => comment.fieldId == dashboardPage.fieldId),
              comment: this.commentList.find(comment => comment.fieldId == dashboardPage.fieldId) == undefined ? "" :  this.commentList.find(comment => comment.fieldId == dashboardPage.fieldId).comment,
              severity: this.commentList.find(comment => comment.fieldId == dashboardPage.fieldId) == undefined ? "" :  this.commentList.find(comment => comment.fieldId == dashboardPage.fieldId).severity
            }
          });
          if(dashboardJSON){
            dashboardJSON.forEach(element => {
              element.dataSet = [{data:[]}];
            }); 
            dashboardJSON.forEach(item => {
              this.fieldValueService.apiFieldValueGet(item.indicator[0].id,moment(this.dateRangeSelected.startDate).format("yyyy-MM-DD"),moment(this.dateRangeSelected.endDate).format("yyyy-MM-DD")).subscribe(res => {
                let chartDataArray = [];
                let chartLabelArray = [];
                res.forEach(instance => {
                  chartDataArray.push(instance['Value']);
                  chartLabelArray.push(this.getFormattedDate(instance['Date']));
                })
                item.dataSet = [{ data: chartDataArray, label: item.indicator[0].name, fill: false }];
                item.chartLabels = chartLabelArray; 
                
              })
            })
            this.dashboard = dashboardJSON;
          }
        })
      }
    })
    
}

autoGrow(element) {
  element.style.height = (element.scrollHeight)+"px";
}
getSeverityColor(item){
  if (item.comment) {
    switch (item.severity) {
      case 'low': return 'green' ;
      case 'medium': return '#ffbf00';
      case 'high': return 'red';
      default: return 'black';
    }
  }
  else{
    return 'black'
  }
}
getFormattedDate(dateString: string){
  return moment(new Date(dateString)).format("MM/DD/YYYY");
}
toggleComment(item){
  item.isCommentBoxActive = !item.isCommentBoxActive;
}
selectSeverity(severity,item){
  item.severity = severity.value;
}
postComment(item,comment){
  let commentBody: FieldCommentModel;
  let startDateTimeAdjusted = this.dateRangeSelected.startDate.toDate()
  startDateTimeAdjusted.setTime(startDateTimeAdjusted .getTime() - (startDateTimeAdjusted.getTimezoneOffset()*60*1000));
  let endDateTimeAdjusted = this.dateRangeSelected.startDate.toDate()
  endDateTimeAdjusted.setTime(startDateTimeAdjusted .getTime() - (startDateTimeAdjusted.getTimezoneOffset()*60*1000));
  commentBody = {
    fieldId: item.indicator[0].id,
    startDate: startDateTimeAdjusted.toISOString().split('T')[0],
    endDate: endDateTimeAdjusted.toISOString().split('T')[0],
    severity: item.severity,
    comment: comment['value']
  }

  this.fieldCommentService.apiFieldCommentPost(commentBody).subscribe(commentPostResponse => {
    item.comment = comment['value'];
    this._snackBar.open("Posted Comment Successfully!","OK",{
      duration: 3000
    })
    if(item.fieldComment) { 
      item.fieldComment.severity = item.severity;
    }
    item.isCommentBoxActive = false;
  })
}
getComment(){
  let startDateTimeAdjusted = this.dateRangeSelected.startDate.toDate()
  startDateTimeAdjusted.setTime(startDateTimeAdjusted .getTime() - (startDateTimeAdjusted.getTimezoneOffset()*60*1000));
  let endDateTimeAdjusted = this.dateRangeSelected.startDate.toDate()
  endDateTimeAdjusted.setTime(startDateTimeAdjusted .getTime() - (startDateTimeAdjusted.getTimezoneOffset()*60*1000));
  this.fieldCommentService.dateRangeGet(startDateTimeAdjusted.toISOString().split('T')[0],endDateTimeAdjusted.toISOString().split('T')[0]).subscribe(comments => {
    this.commentList = comments;
  })
}


//Chart Settings
chartTypeOptions = ['line','pie','bar']
public chartData: ChartDataSets[] = [{data: []}];

public chartOptions: (ChartOptions & { annotation ?: any }) = {
  responsive: true,
  scales: {
    yAxes: [{
        ticks: {
            beginAtZero: true
        }
    }]
}
};

public chartLabels: Label[] = [];
public chartColors: Color[] = [
  {
    borderColor: '#EB4000BF',
    backgroundColor: ['#EB4000BF', '#F0D12FBF', '#19183BBF', '#304585BF', '#2B69B6BF', '#6F9CD340BF'],
  },
];
public chartLegend = true;
public chartType = 'line';
public chartPlugins = [];



handleFilterChange(){
  this.ngOnInit();
}
}
