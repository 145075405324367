import { normalizePassiveListenerOptions } from '@angular/cdk/platform';
import { TypeScriptEmitter } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from '@app/_models';
import { AccountService, LoginQuestionModel, LoginQuestionService } from 'src/services-generated';

@Component({
  selector: 'app-security-question-detail',
  templateUrl: './security-question-detail.component.html',
  styleUrls: ['./security-question-detail.component.scss']
})
export class SecurityQuestionDetailComponent implements OnInit {
  i = null;
  loading = false;
  questionForm: FormGroup;
  error = '';
  editMode = false;
  loginQuestionId = null;
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private loginQuestionService: LoginQuestionService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.questionForm = this.formBuilder.group({
      isActive: true,
      question: ['', Validators.required],
      answers: new FormArray([]),
    });
    if(this.route.snapshot.paramMap.get('id')){
      this.editMode = true;
      this.loginQuestionId = this.route.snapshot.paramMap.get('id');
      this.loginQuestionService.apiLoginQuestionIdGet(parseInt(this.loginQuestionId)).subscribe(loginQuestionResponse => {
        console.log(loginQuestionResponse);
        let answers = loginQuestionResponse.answers.split(';').map(answer => {
          this.addAnswer();
          if(answer == loginQuestionResponse.correctAnswer){
              return {
                  "answer": answer,
                  "isCorrect": true
              }
          } else {
              return {
                  "answer": answer,
                  "isCorrect": false
              }
          }
      });
      this.questionForm.setValue({
          isActive: loginQuestionResponse.isActive,
          question: loginQuestionResponse.question,
          answers: answers
        });
      })
    } else {
      this.addAnswer();
      this.addAnswer();
    }
  }

  get f() { return this.questionForm.controls; }
  get answers() { return this.f.answers as FormArray; }

  addAnswer() {
    if(this.answers.length > 3) {
      return;
    }
    this.answers.push(this.formBuilder.group({
      answer: ['', Validators.required],
      isCorrect: false
    }));
  }

  removeAnswer() {
    if(this.answers.length > 2) {
      this.answers.removeAt(this.answers.length - 1)
    }
  }

  removeOtherCorrect(correctAnswer){
    this.answers.controls.forEach(answerGroup => {
      if(answerGroup['controls'].answer.value != correctAnswer){
        answerGroup['controls'].isCorrect.setValue(false);
      }
    });
  }

  onSubmit() {

    // stop here if form is invalid
    if (this.questionForm.invalid) {
        return;
    }
    console.log(this.questionForm.value);
    this.loading = true;
    let answers = "";
    this.questionForm.value.answers.forEach(answerObject => {
      answers += answerObject.answer + ";"
    });
    answers = answers.substring(0,answers.length-1)
    let loginQuestion: LoginQuestionModel = {
      question: this.questionForm.value.question,
      correctAnswer: this.questionForm.value.answers.find(answer => {return answer.isCorrect==true}).answer,
      answers: answers,
      isActive: this.questionForm.value.isActive
    }
    if(this.editMode){
      this.loginQuestionService.apiLoginQuestionIdPut(this.loginQuestionId, loginQuestion)
      .subscribe(loginQuestionResponse => {
              this.loading = false;
              if(loginQuestionResponse.isSuccessful){
                  this.snackBar.open(loginQuestionResponse.message, "OK", {
                    duration: 3000,
                  });
                  this.router.navigate(['/admin/security-question']);
              } else{
                this.snackBar.open(loginQuestionResponse.message, "OK", {
                  duration: 3000,
                });
              }
          },
          error => {
              console.log(error);
              this.snackBar.open(error, "OK", {
                duration: 3000,
              });
              this.loading = false;
          });
    } else{
      this.loginQuestionService.apiLoginQuestionPost(loginQuestion)
      .subscribe(loginQuestionResponse => {
              this.loading = false;
              if(loginQuestionResponse.isSuccessful){
                  this.snackBar.open(loginQuestionResponse.message, "OK", {
                    duration: 3000,
                  });
                  this.router.navigate(['/admin/security-question']);
              } else{
                this.snackBar.open(loginQuestionResponse.message, "OK", {
                  duration: 3000,
                });
              }
          },
          error => {
              console.log(error);
              this.snackBar.open(error, "OK", {
                duration: 3000,
              });
              this.loading = false;
          });
    }
}

}
