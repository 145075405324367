import { TypeScriptEmitter } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from '@app/_models';
import { ModuleService } from '@app/_services/module.service';
import { AccountService, User } from 'src/services-generated';
import { UserModule } from 'src/services-generated/model/UserModule.Model';
import { Module } from 'src/services-generated/model/moduleModel';

@Component({
  selector: 'app-supervisor-detail',
  templateUrl: './supervisor-detail.component.html',
  styleUrls: ['./supervisor-detail.component.scss']
})
export class SupervisorDetailComponent implements OnInit {
  loading = false;
  editMode = false;
  supervisorId = null;
  modules: any[];
  userModules : Array<UserModule> =[];
  supervisorForm: FormGroup;
  dataSource: MatTableDataSource<any>;
  error = '';
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private snackBar: MatSnackBar,
    private moduleService: ModuleService) { 
  
    }

  ngOnInit() {
    this.getModulelist();
  
    this.supervisorForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      firstName: ['', Validators.required],
      lastName: [''],
      password: ['', Validators.required],
      role: [Role.Supervisor]
    });
    if(this.route.snapshot.paramMap.get('id')){
      this.supervisorId = this.route.snapshot.paramMap.get('id');
      this.accountService.apiAccountIdGet(parseInt(this.supervisorId)).subscribe(supervisorResponse => {
        let supervisorData = supervisorResponse.result;
        this.supervisorForm.setValue({
          email: supervisorData.email,
          phoneNumber: supervisorData.phoneNumber,
          firstName: supervisorData.firstName,
          lastName: supervisorData.lastName,
          password: "PASSWORD",
          role: Role.Supervisor,
        });
        this.modules.forEach(element => {
          var um = supervisorData.userModules.find(x => x.moduleId == element.id);
          element.isRead.setValue(um.isRead);
          element.isWrite.setValue(um.isWrite);
          element.isDownload.setValue(um.isDownload);          
        });
      })
      this.editMode = true;
    }
  }

  toggleAllCheckboxes(event, data){
    var module = this.modules.find(x => x.id == data.id);
    module.isRead.setValue(event.checked);
    module.isWrite.setValue(event.checked);
    module.isDownload.setValue(event.checked);
  }

  checkRead(event, data){
    if(event.checked){
      var module = this.modules.find(x => x.id == data.id);
      module.isRead.setValue(event.checked);
    }
  }

  uncheckWriteDownload(event, data){
    if(!event.checked){
      var module = this.modules.find(x => x.id == data.id);
      module.isWrite.setValue(event.checked);
    module.isDownload.setValue(event.checked);
    }
  }

  get f() { return this.supervisorForm.controls; }

  onSubmit() {

    // stop here if form is invalid
    if (this.supervisorForm.invalid) {
        return;
    }
    this.loading = true;
    if(this.editMode){
      let formValue = this.supervisorForm.value;
      delete formValue.password;
      var data = {
        ...formValue,
        userModules: this.modules.map(function(item) {
          return {
            moduleId: item.id,
            isRead: item.isRead.value,
            isWrite: item.isWrite.value,
            isDownload: item.isDownload.value
          };          
        })
      }
      this.accountService.apiAccountIdPut(this.supervisorId, data)
      .subscribe(supervisorResponse => {
        this.loading = false;
        if(supervisorResponse.isSuccessful){
            this.snackBar.open(supervisorResponse.message, "OK", {
              duration: 3000,
            });
            this.router.navigate(['/admin/supervisor']);
        } else{
          this.snackBar.open(supervisorResponse.message, "OK", {
            duration: 3000,
          });
        }
      },
      error => {
          console.log(error);
          this.snackBar.open(error, "OK", {
            duration: 3000,
          });
          this.loading = false;
      });

    } 

  
    else {
      var data = {
        ...this.supervisorForm.value,
        userModules: this.modules.map(function(item) {
          return {
            moduleId: item.id,
            isRead: item.isRead.value,
            isWrite: item.isWrite.value,
            isDownload: item.isDownload.value
          };          
        })
      }
      this.accountService.apiAccountPost(data)
      .subscribe(supervisorResponse => {
        this.loading = false;
        if(supervisorResponse.isSuccessful){
            this.snackBar.open(supervisorResponse.message, "OK", {
              duration: 3000,
            });
            this.router.navigate(['/admin/supervisor']);
        } else{
          this.snackBar.open(supervisorResponse.message, "OK", {
            duration: 3000,
          });
        }
      },
      error => {
          console.log(error);
          this.snackBar.open(error, "OK", {
            duration: 3000,
          });
          this.loading = false;
      });
    }
  }
  getModulelist() {
    this.moduleService.getModules()
    .subscribe(response => {
      this.modules = response.map(item => ({
        ...item,
        isRead: new FormControl(false),
        isWrite: new FormControl(false),
        isDownload: new FormControl(false)
      }));
    });
  }
}
