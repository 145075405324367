import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GridsterConfig, GridsterItem, GridType, CompactType } from 'angular-gridster2'; 


@Component({
  selector: 'app-report-dashboard',
  templateUrl: './report-dashboard.component.html',
  styleUrls: ['./report-dashboard.component.scss']
})
export class ReportDashboardComponent implements OnInit {
  currentUrl = '';
  liveOpen = false;
  historyOpen = false;
  authenticationOpen = false;
  dashboardOpen:boolean = false;
  compareOpen:boolean = false;
  constructor(private router: Router) {
    router.events.subscribe((val) => {
      // see also 
      if(val instanceof NavigationEnd){
        this.currentUrl = val.url;
        switch (this.currentUrl) {
          case '/supervisor/dashboard/live':
            {
              this.liveOpen = true;
              break;
            }
          case '/supervisor/dashboard/history':
            {
              this.historyOpen = true;
              break;
            }
          case '/supervisor/dashboard/close-call/live':
            {
              this.liveOpen = true;
              break;
            }
          case '/supervisor/dashboard/close-call/history':
            {
              this.historyOpen = true;
              break;
            }
          case '/supervisor/dashboard/login/history':
            {
              this.historyOpen = true;
              break;
            }
          case '/supervisor/dashboard/proxy-authentication/login':
            {
              this.authenticationOpen = true;
              break;
            }
          case '/supervisor/dashboard/proxy-authentication/logout':
            {
              this.authenticationOpen = true;
              break;
            }
          case '/supervisor/dashboard/proxy-authentication/list':
            {
              this.authenticationOpen = true;
              break;
            }
          case '/report/dashboard/create':
            {
              this.dashboardOpen = true;
              break;
            }
          case '/report/dashboard/compare-same':
            {
              this.compareOpen = true;
              break;
            }
          case '/report/dashboard/compare-different':
            {
              this.compareOpen = true;
              break;
            }
        }
      }
    });
   }

  ngOnInit() {}
}
