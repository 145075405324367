/**
 * worksite_track
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { SiteFamiliarisationModel } from '../model/siteFamiliarisation';
import { siteFamiliarisationGetListExecutionResponse } from '../model/siteFamiliarisationGetResponseModel';
import { SiteFamiliarisationPostModel } from '../model/siteFamiliarisationPostModel';
import { SiteFamiliarisationPostModelResponse } from '../model/siteFamiliarisationPostResultResponse';
import { SiteFamiliarisationQuestionsssModelResponse } from '../model/siteFamiliarisationQuestionREsponseModel';
import { SiteModelPost } from '../model/SiteModelPost';
import { sitePostModelResponse } from '../model/sitePostModelResponse';
import { siteResponse } from '../model/siteResponse';
import { BASE_PATH } from '../variables';
@Injectable({
  providedIn: 'root'
})
export class SiteApiService {
  protected basePath = environment.basePath;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) { }


  /**
       * 
       * 
       * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
       * @param reportProgress flag to report request and response progress.
       */
  public apiSiteFamiliarisationGet(observe?: 'body', reportProgress?: boolean): Observable<Array<SiteFamiliarisationPostModelResponse>>;
  public apiSiteFamiliarisationGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SiteFamiliarisationPostModelResponse>>>;
  public apiSiteFamiliarisationGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SiteFamiliarisationPostModelResponse>>>;
  public apiSiteFamiliarisationGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;

    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    let httpHeaderAccepts: string[] = [
      'application/json;odata.metadata=minimal;odata.streaming=true',
      'application/json;odata.metadata=minimal;odata.streaming=false',
      'application/json;odata.metadata=minimal',
      'application/json;odata.metadata=full;odata.streaming=true',
      'application/json;odata.metadata=full;odata.streaming=false',
      'application/json;odata.metadata=full',
      'application/json;odata.metadata=none;odata.streaming=true',
      'application/json;odata.metadata=none;odata.streaming=false',
      'application/json;odata.metadata=none',
      'application/json;odata.streaming=true',
      'application/json;odata.streaming=false',
      'application/json',
      'application/xml',
      'application/prs.odatatestxx-odata',
      'text/plain',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    const consumes: string[] = [
    ];

    return this.httpClient.request<Array<SiteFamiliarisationPostModelResponse>>('get', `${this.basePath}/api/Site`,

      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
   * 
   * 
   * @param id 
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiSiteIdDelete(id: number, observe?: 'body', reportProgress?: boolean): Observable<siteResponse>;
  public apiSiteIdDelete(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<siteResponse>>;
  public apiSiteIdDelete(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<siteResponse>>;
  public apiSiteIdDelete(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiSiteIdDelete.');
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;odata.metadata=minimal;odata.streaming=true',
      'application/json;odata.metadata=minimal;odata.streaming=false',
      'application/json;odata.metadata=minimal',
      'application/json;odata.metadata=full;odata.streaming=true',
      'application/json;odata.metadata=full;odata.streaming=false',
      'application/json;odata.metadata=full',
      'application/json;odata.metadata=none;odata.streaming=true',
      'application/json;odata.metadata=none;odata.streaming=false',
      'application/json;odata.metadata=none',
      'application/json;odata.streaming=true',
      'application/json;odata.streaming=false',
      'application/json',
      'application/xml',
      'application/prs.odatatestxx-odata',
      'text/plain',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<siteResponse>('delete', `${this.basePath}/api/Site/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * 
   * 
   * @param body 
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiAddNewSitePost(body?: SiteModelPost, observe?: 'body', reportProgress?: boolean): Observable<sitePostModelResponse>;
  public apiAddNewSitePost(body?: SiteModelPost, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<sitePostModelResponse>>;
  public apiAddNewSitePost(body?: SiteModelPost, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<sitePostModelResponse>>;
  public apiAddNewSitePost(body?: SiteModelPost, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    debugger

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;odata.metadata=minimal;odata.streaming=true',
      'application/json;odata.metadata=minimal;odata.streaming=false',
      'application/json;odata.metadata=minimal',
      'application/json;odata.metadata=full;odata.streaming=true',
      'application/json;odata.metadata=full;odata.streaming=false',
      'application/json;odata.metadata=full',
      'application/json;odata.metadata=none;odata.streaming=true',
      'application/json;odata.metadata=none;odata.streaming=false',
      'application/json;odata.metadata=none',
      'application/json;odata.streaming=true',
      'application/json;odata.streaming=false',
      'application/json',
      'application/xml',
      'application/prs.odatatestxx-odata',
      'text/plain',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json;odata.metadata=minimal;odata.streaming=true',
      'application/json;odata.metadata=minimal;odata.streaming=false',
      'application/json;odata.metadata=minimal',
      'application/json;odata.metadata=full;odata.streaming=true',
      'application/json;odata.metadata=full;odata.streaming=false',
      'application/json;odata.metadata=full',
      'application/json;odata.metadata=none;odata.streaming=true',
      'application/json;odata.metadata=none;odata.streaming=false',
      'application/json;odata.metadata=none',
      'application/json;odata.streaming=true',
      'application/json;odata.streaming=false',
      'application/json',
      'application/xml',
      'application/prs.odatatestxx-odata',
      'text/json',
      'application/_*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<sitePostModelResponse>('post', `${this.basePath}/api/Site`,
      {
        body: `"${body}"`,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
  /**
    * 
    * 
  
    * @param body 
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
  public apiSitePost(body?: SiteModelPost, observe?: 'body', reportProgress?: boolean): Observable<sitePostModelResponse>;
  public apiSitePost(body?: SiteModelPost, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<sitePostModelResponse>>;
  public apiSitePost(body?: SiteModelPost, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<sitePostModelResponse>>;
  public apiSitePost(body?: SiteModelPost, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;odata.metadata=minimal;odata.streaming=true',
      'application/json;odata.metadata=minimal;odata.streaming=false',
      'application/json;odata.metadata=minimal',
      'application/json;odata.metadata=full;odata.streaming=true',
      'application/json;odata.metadata=full;odata.streaming=false',
      'application/json;odata.metadata=full',
      'application/json;odata.metadata=none;odata.streaming=true',
      'application/json;odata.metadata=none;odata.streaming=false',
      'application/json;odata.metadata=none',
      'application/json;odata.streaming=true',
      'application/json;odata.streaming=false',
      'application/json',
      'application/xml',
      'application/prs.odatatestxx-odata',
      'text/plain',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json;odata.metadata=minimal;odata.streaming=true',
      'application/json;odata.metadata=minimal;odata.streaming=false',
      'application/json;odata.metadata=minimal',
      'application/json;odata.metadata=full;odata.streaming=true',
      'application/json;odata.metadata=full;odata.streaming=false',
      'application/json;odata.metadata=full',
      'application/json;odata.metadata=none;odata.streaming=true',
      'application/json;odata.metadata=none;odata.streaming=false',
      'application/json;odata.metadata=none',
      'application/json;odata.streaming=true',
      'application/json;odata.streaming=false',
      'application/json',
      'application/xml',
      'application/prs.odatatestxx-odata',
      'text/json',
      'application/_*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<sitePostModelResponse>('post', `${this.basePath}/api/Site`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
     * 
     * 
     * @param Id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public apiSiteEditIdPut(Id: number, body?: SiteModelPost, observe?: 'body', reportProgress?: boolean): Observable<sitePostModelResponse>;
  public apiSiteEditIdPut(Id: number, body?: SiteModelPost, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<sitePostModelResponse>>;
  public apiSiteEditIdPut(Id: number, body?: SiteModelPost, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<sitePostModelResponse>>;
  public apiSiteEditIdPut(Id: number, body?: SiteModelPost, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (Id === null || Id === undefined) {
      throw new Error('Required parameter Id was null or undefined when calling apiSiteEditIdPut.');
    }




    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;odata.metadata=minimal;odata.streaming=true',
      'application/json;odata.metadata=minimal;odata.streaming=false',
      'application/json;odata.metadata=minimal',
      'application/json;odata.metadata=full;odata.streaming=true',
      'application/json;odata.metadata=full;odata.streaming=false',
      'application/json;odata.metadata=full',
      'application/json;odata.metadata=none;odata.streaming=true',
      'application/json;odata.metadata=none;odata.streaming=false',
      'application/json;odata.metadata=none',
      'application/json;odata.streaming=true',
      'application/json;odata.streaming=false',
      'application/json',
      'application/xml',
      'application/prs.odatatestxx-odata',
      'text/plain',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json;odata.metadata=minimal;odata.streaming=true',
      'application/json;odata.metadata=minimal;odata.streaming=false',
      'application/json;odata.metadata=minimal',
      'application/json;odata.metadata=full;odata.streaming=true',
      'application/json;odata.metadata=full;odata.streaming=false',
      'application/json;odata.metadata=full',
      'application/json;odata.metadata=none;odata.streaming=true',
      'application/json;odata.metadata=none;odata.streaming=false',
      'application/json;odata.metadata=none',
      'application/json;odata.streaming=true',
      'application/json;odata.streaming=false',
      'application/json',
      'application/xml',
      'application/prs.odatatestxx-odata',
      'text/json',
      'application/_*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<sitePostModelResponse>('put', `${this.basePath}/api/Site/${encodeURIComponent(String(Id))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
