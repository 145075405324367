import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-view-detail-vehicle',
  templateUrl: './view-detail-vehicle.component.html',
  styleUrls: ['./view-detail-vehicle.component.scss']
})
export class ViewDetailVehicleComponent implements OnInit {
  vehicleDetail:any[]=[];
  vehicledetails:any;
  vehicledetailsId:any;
  VehicleLogDetail= new FormGroup({
    id: new FormControl(''),
    vehicleRegistration: new FormControl(''),
    weekEnding: new FormControl(''),
    driver: new FormControl(''),
    pickupLocation: new FormControl(''),
    pickupDateTime: new FormControl(''),
    nextServiceDueMileage: new FormControl(''),
    mileage: new FormControl(''),
    vehicleLogDetails: new FormControl(''),

  })
  constructor() { }

  ngOnInit() {
    debugger
    this.vehicledetails= JSON.parse(localStorage.getItem('selectedvehicle') as any)
    console.log('details',this.vehicledetails)
    this.vehicledetailsId =this.vehicledetails.id
    this.VehicleLogDetail= new FormGroup({
      vehicledetailsId: new FormControl(this.vehicledetails['vehicledetailsId']),
      vehicleRegistration: new FormControl(this.vehicledetails['vehicleRegistration']),
      weekEnding: new FormControl(this.vehicledetails['weekEnding']),
      driver: new FormControl(this.vehicledetails['driver']),
      pickupLocation: new FormControl(this.vehicledetails['pickupLocation']),
      pickupDateTime: new FormControl(this.vehicledetails['pickupDateTime']),
      nextServiceDueMileage: new FormControl(this.vehicledetails['nextServiceDueMileage']),
      mileage: new FormControl(this.vehicledetails['mileage']),
      vehicleLogDetails: new FormControl(this.vehicledetails['vehicleLogDetails']),
    })
  }

}
