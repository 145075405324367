import { TypeScriptEmitter } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { AccountService } from 'src/services-generated';
import { SiteOperativeModel } from 'src/services-generated/model/siteOperativeModel';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-site-operative-bulk',
  templateUrl: './site-operative-bulk.component.html',
  styleUrls: ['./site-operative-bulk.component.scss']
})
export class SiteOperativeBulkComponent implements OnInit {
  loading = false;
  files: any[] = [];
  displayedColumns: string[] = [ "firstName", "lastName", "email", "phoneNumber", "sentinelNumber", "homePostCode", "businessDeliveryUnit"];
  siteOperativeForm: FormGroup;
  siteOperativesToUpload: MatTableDataSource<SiteOperativeModel> = new MatTableDataSource<SiteOperativeModel>();
  error = '';
  excelToKeyMapping = {
    "Sentinel Number"  : "sentinelNumber",
    "Home Post Code"  : "homePostCode",
    "Business Delivery Unit"  : "businessDeliveryUnit",
    "Email"  : "email",
    "Phone Number"  : "phoneNumber",
    "First Name"  : "firstName",
    "Last Name"  : "lastName",
    "Password"  : "password",
  }
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  get f() { return this.siteOperativeForm.controls; }

  sendFilesToUpload(files) {
    this.files = files;
    this.convertExcelToJson();
  }

  convertExcelToJson() {
    let excelFile = this.files[0];
    excelFile.uploaded = true;
    excelFile.progress = 100;
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = reader.result;
      let workBook = XLSX.read(data, { type: 'binary' });
      let jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      let siteOperatives = jsonData['SiteOperatives'];
      try {
        siteOperatives.forEach(siteOperative => {
          let siteOperativeToUpload: SiteOperativeModel = {};
          Object.keys(siteOperative).forEach(key => {
            siteOperativeToUpload[this.excelToKeyMapping[key]] = siteOperative[key].toString();
          })
          this.siteOperativesToUpload.data.push(siteOperativeToUpload);
        });
      } catch (error) {
        console.log(error);
      }
    this.siteOperativesToUpload.data = this.siteOperativesToUpload.data;
    }
    reader.readAsBinaryString(excelFile);
  }

  bulkCreate(){
    if(this.siteOperativesToUpload.data.length > 0) {
      this.accountService.apiAccountSiteOperativeBulkPost(this.siteOperativesToUpload.data).subscribe(bulkPostResponse => {
          this.loading = false;
          this.snackBar.open(bulkPostResponse.message, "OK", {
            duration: 3000,
          });
          if (bulkPostResponse.isSuccessful) {
            this.router.navigate(['/admin/site-operative']);
          }
        },
        error => {
          console.log(error);
          this.snackBar.open(error, "OK", {
            duration: 3000,
          });
          this.loading = false;
        });
    }else{
      this.snackBar.open("No records found", "OK", {
        duration: 2000,
      });
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

}
