/**
 * pack_digitization
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Applicant } from '../model/applicant';
import { DiversityMonitoring } from '../model/diversityMonitoring';
import { HVSRequestModel } from '../model/hVSRequestModel';
import { MedicalHistory } from '../model/medicalHistory';
import { PersonaldataRequestModel } from '../model/personaldataRequestModel';
import { PreHealthDeclaration } from '../model/preHealthDeclaration';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { environment } from '@environments/environment';


@Injectable()
export class ApplicantService {

    protected basePath = environment.umbrellaPackBasePath;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param formId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicantCheckForValidFormIdGet(formId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiApplicantCheckForValidFormIdGet(formId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiApplicantCheckForValidFormIdGet(formId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiApplicantCheckForValidFormIdGet(formId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (formId !== undefined && formId !== null) {
            queryParameters = queryParameters.set('formId', <any>formId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Applicant/CheckForValidFormId`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param fileName 
     * @param documentNumber 
     * @param documentTypeId 
     * @param formFile 
     * @param formId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicantFileUploadPostForm(fileName?: string, documentNumber?: string, documentTypeId?: number, formFile?: Blob, formId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiApplicantFileUploadPostForm(fileName?: string, documentNumber?: string, documentTypeId?: number, formFile?: Blob, formId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiApplicantFileUploadPostForm(fileName?: string, documentNumber?: string, documentTypeId?: number, formFile?: Blob, formId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiApplicantFileUploadPostForm(fileName?: string, documentNumber?: string, documentTypeId?: number, formFile?: Blob, formId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (formId !== undefined && formId !== null) {
            queryParameters = queryParameters.set('formId', <any>formId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (fileName !== undefined) {
            formParams = formParams.append('FileName', <any>fileName) as any || formParams;
        }
        if (documentNumber !== undefined) {
            formParams = formParams.append('DocumentNumber', <any>documentNumber) as any || formParams;
        }
        if (documentTypeId !== undefined) {
            formParams = formParams.append('DocumentTypeId', <any>documentTypeId) as any || formParams;
        }
        if (formFile !== undefined) {
            formParams = formParams.append('FormFile', <any>formFile) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Applicant/FileUpload`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Sample calls:        {          \&quot;Has_Agreed_Safety_Handbook\&quot;: \&quot;api/Applicant?$filter&#x3D;HasAgreedSaftyHandbook eq true\&quot;          \&quot;Has_Not_Agreed_Safety_Handbook\&quot;: \&quot;api/Applicant?$filter&#x3D;HasAgreedSaftyHandbook eq false\&quot;          \&quot;Between_Date_Range\&quot;: \&quot;api/Applicant?$filter&#x3D;CreatedAt gt 2021-04-22T00:00:00Z and CreatedAt lt 2021-04-23T00:00:00Z\&quot;          \&quot;Column_Selection\&quot;: \&quot;api/Applicant?$select&#x3D;Id,FormId,HasAgreedSafetyHandbook,FormStatus,HasAgreedCompanyTerms,HasAgreedContractOfSponsorship&amp;$expand&#x3D;UmbrellaCompany($select&#x3D;Id,Name)&amp;$expand&#x3D;PersonalData($select&#x3D;FirstName,LastName)\&quot;      }
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicantGet(odataString: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Applicant>>;
    public apiApplicantGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Applicant>>>;
    public apiApplicantGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Applicant>>>;
    public apiApplicantGet(odataString = '', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/plain',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Applicant>>('get',`${this.basePath}/api/Applicant${odataString}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param formId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicantGetDocumentListGet(formId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiApplicantGetDocumentListGet(formId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiApplicantGetDocumentListGet(formId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiApplicantGetDocumentListGet(formId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (formId !== undefined && formId !== null) {
            queryParameters = queryParameters.set('formId', <any>formId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Applicant/GetDocumentList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicantIdGet(id: number, odataString: string, observe?: 'body', reportProgress?: boolean): Observable<Applicant>;
    public apiApplicantIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Applicant>>;
    public apiApplicantIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Applicant>>;
    public apiApplicantIdGet(id: number, odataString = '', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiApplicantIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/plain',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Applicant>('get',`${this.basePath}/api/Applicant/${encodeURIComponent(String(id))}${odataString}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param formId 
     * @param hasAgreedContractOfSponsorship 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicantPostContractOfSponsorshipStatusPost(formId?: string, hasAgreedContractOfSponsorship?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiApplicantPostContractOfSponsorshipStatusPost(formId?: string, hasAgreedContractOfSponsorship?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiApplicantPostContractOfSponsorshipStatusPost(formId?: string, hasAgreedContractOfSponsorship?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiApplicantPostContractOfSponsorshipStatusPost(formId?: string, hasAgreedContractOfSponsorship?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (formId !== undefined && formId !== null) {
            queryParameters = queryParameters.set('formId', <any>formId);
        }
        if (hasAgreedContractOfSponsorship !== undefined && hasAgreedContractOfSponsorship !== null) {
            queryParameters = queryParameters.set('hasAgreedContractOfSponsorship', <any>hasAgreedContractOfSponsorship);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Applicant/PostContractOfSponsorshipStatus`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param formId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicantPostDiversitymonitoringPost(body?: DiversityMonitoring, formId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiApplicantPostDiversitymonitoringPost(body?: DiversityMonitoring, formId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiApplicantPostDiversitymonitoringPost(body?: DiversityMonitoring, formId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiApplicantPostDiversitymonitoringPost(body?: DiversityMonitoring, formId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (formId !== undefined && formId !== null) {
            queryParameters = queryParameters.set('formId', <any>formId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Applicant/PostDiversitymonitoring`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param formId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicantPostHVSdataPost(body?: HVSRequestModel, formId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiApplicantPostHVSdataPost(body?: HVSRequestModel, formId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiApplicantPostHVSdataPost(body?: HVSRequestModel, formId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiApplicantPostHVSdataPost(body?: HVSRequestModel, formId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (formId !== undefined && formId !== null) {
            queryParameters = queryParameters.set('formId', <any>formId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Applicant/PostHVSdata`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param formId 
     * @param hasAgreedCompanyTerms 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicantPostHasAgreedCompanyTermsPost(formId?: string, hasAgreedCompanyTerms?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiApplicantPostHasAgreedCompanyTermsPost(formId?: string, hasAgreedCompanyTerms?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiApplicantPostHasAgreedCompanyTermsPost(formId?: string, hasAgreedCompanyTerms?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiApplicantPostHasAgreedCompanyTermsPost(formId?: string, hasAgreedCompanyTerms?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (formId !== undefined && formId !== null) {
            queryParameters = queryParameters.set('formId', <any>formId);
        }
        if (hasAgreedCompanyTerms !== undefined && hasAgreedCompanyTerms !== null) {
            queryParameters = queryParameters.set('hasAgreedCompanyTerms', <any>hasAgreedCompanyTerms);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Applicant/PostHasAgreedCompanyTerms`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param formId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicantPostMedicalHistoryPost(body?: MedicalHistory, formId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiApplicantPostMedicalHistoryPost(body?: MedicalHistory, formId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiApplicantPostMedicalHistoryPost(body?: MedicalHistory, formId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiApplicantPostMedicalHistoryPost(body?: MedicalHistory, formId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (formId !== undefined && formId !== null) {
            queryParameters = queryParameters.set('formId', <any>formId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Applicant/PostMedicalHistory`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param formId 
     * @param umbrellaCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicantPostPersonaldataPost(body?: PersonaldataRequestModel, formId?: string, umbrellaCompanyId?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiApplicantPostPersonaldataPost(body?: PersonaldataRequestModel, formId?: string, umbrellaCompanyId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiApplicantPostPersonaldataPost(body?: PersonaldataRequestModel, formId?: string, umbrellaCompanyId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiApplicantPostPersonaldataPost(body?: PersonaldataRequestModel, formId?: string, umbrellaCompanyId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (formId !== undefined && formId !== null) {
            queryParameters = queryParameters.set('formId', <any>formId);
        }
        if (umbrellaCompanyId !== undefined && umbrellaCompanyId !== null) {
            queryParameters = queryParameters.set('umbrellaCompanyId', <any>umbrellaCompanyId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Applicant/PostPersonaldata`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param formId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicantPostPrehealthdeclarationPost(body?: PreHealthDeclaration, formId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiApplicantPostPrehealthdeclarationPost(body?: PreHealthDeclaration, formId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiApplicantPostPrehealthdeclarationPost(body?: PreHealthDeclaration, formId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiApplicantPostPrehealthdeclarationPost(body?: PreHealthDeclaration, formId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (formId !== undefined && formId !== null) {
            queryParameters = queryParameters.set('formId', <any>formId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Applicant/PostPrehealthdeclaration`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param formId 
     * @param hasAgreedSaftyHandbook 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiApplicantPostSafeyHandBookStatusPost(formId?: string, hasAgreedSaftyHandbook?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiApplicantPostSafeyHandBookStatusPost(formId?: string, hasAgreedSaftyHandbook?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiApplicantPostSafeyHandBookStatusPost(formId?: string, hasAgreedSaftyHandbook?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiApplicantPostSafeyHandBookStatusPost(formId?: string, hasAgreedSaftyHandbook?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (formId !== undefined && formId !== null) {
            queryParameters = queryParameters.set('formId', <any>formId);
        }
        if (hasAgreedSaftyHandbook !== undefined && hasAgreedSaftyHandbook !== null) {
            queryParameters = queryParameters.set('hasAgreedSaftyHandbook', <any>hasAgreedSaftyHandbook);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Applicant/PostSafeyHandBookStatus`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
