import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentService } from 'src/services-generated/api/document.service';
import { Document } from 'src/services-generated/model/document';
import { debounce } from 'lodash';
import { RuleBookService } from 'src/services-generated/api/ruleBook.service';
import { environment } from '@environments/environment';


@Component({
  selector: 'app-rule-book-list',
  templateUrl: './rule-book-list.component.html',
  styleUrls: ['./rule-book-list.component.scss']
})
export class RuleBookListComponent implements OnInit {

  searchString = "";
  loading = false;
  files = [];
  filteredFiles = [];
  tagField = new FormControl('');
  error = '';
  Tags:string[] = ["All","General","Repair","Overhaul","Safety"];

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private documentService: RuleBookService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.documentService.apiRuleBookGet().subscribe((documentResponse)=>{
      this.files = documentResponse;
      this.filteredFiles = documentResponse;
    });
  }

  search(searchString:string) {
    searchString = searchString.toLowerCase();
    if(searchString == ""){
      this.ngOnInit();
    }
    this.filteredFiles = this.files.filter((file) => {
      if(file && file.name && file.tags){
        return file.name.toLowerCase().includes(searchString) || file.tags.toLowerCase().includes(searchString);
      }
    })
  }

  delete(file: Document){
    this.documentService.apiRuleBookIdDelete(file.id).subscribe(deleteResponse =>{
      this.snackBar.open(deleteResponse.message, "OK", {
        duration: 3000,
      });
      if(deleteResponse.isSuccessful) {
        this.documentService.apiRuleBookGet().subscribe((documentResponse)=>{
          this.files = documentResponse;
        });
      }
    })
  }

  onTagChange(tag:string){
    if(tag.toLowerCase() == "all"){
      return this.filteredFiles = this.files; 
    }
    this.filteredFiles = this.files.filter(ruleBook => {
      return ruleBook.tags.toLowerCase().includes(tag.toLowerCase());
    }) 
  }

  download(file: Document) {
    var atag = document.createElement('a');
    atag.href = `${environment.basePath}/api/RuleBook/${file.id}/content`
    atag.target = "download"
    atag.download = file.name
    atag.click();
  }

  filterTag(tag:string){
    console.log(tag);
    this.tagField.setValue(tag);
    tag = tag.toLowerCase();
    this.filteredFiles = this.files.filter((file) => {
      if(file && file.tags){
        return file.tags.toLowerCase().includes(tag);
      }
    })
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  edit(file){
    this.router.navigate([`/admin/rule-book/edit/${file.id}`]);
  }

}
