import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { ReportService } from 'src/services-generated';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
  })
export class AdminComponent implements OnInit {
    loading = false;

    constructor(private reportService: ReportService) { }

    ngOnInit() {
    }

    exportExcelReport(){
      this.reportService.documentTypeDownloadGet().subscribe(response => {
        let date = new Date();
        const blob = new Blob([response], { type: 'application/octet-stream' });
        const url= window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = `document_report_${date}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      })
    }
}