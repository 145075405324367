import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-ios-app',
  templateUrl: './ios-app.component.html',
  styleUrls: ['./ios-app.component.scss']
})
export class iOSAppComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

}
