import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteOperativeService } from 'src/services-generated';
import { CertificateService } from 'src/services-generated/api/certificate.service';
import { UserCertificateModel } from 'src/services-generated/model/userCertificateModel';

@Component({
  selector: 'app-site-operative-certificate-add',
  templateUrl: './site-operative-certificate-add.component.html',
  styleUrls: ['./site-operative-certificate-add.component.scss']
})
export class SiteOperativeCertificateAddComponent implements OnInit {
  loading = false;
  files: any[] = [];
  oldFile: any = {};
  newFile: any = {};
  editMode = false;
  siteOperativeId = null;
  error = '';

  constructor(private route: ActivatedRoute,
    private router: Router,
    private certificateService: CertificateService,
    private siteOperativeService: SiteOperativeService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    if(this.route.snapshot.paramMap.get('id')){
      this.siteOperativeId = this.route.snapshot.paramMap.get('id');
    }
  }

  sendFilesToUpload(files) {
    this.files = [...files];
    this.uploadFiles();
  }

  uploadFiles() {
    this.files.forEach(file => {
      if(file.uploaded != true){
        this.certificateService.apiCertificatePostForm(file, 'events', true).subscribe(documentEvent => {
          if (documentEvent.type === HttpEventType.DownloadProgress) {
            console.log("download progress");
            file.progress = documentEvent.loaded / documentEvent.total;
          }
          if (documentEvent.type === HttpEventType.Response) {
            if (documentEvent.body.isSuccessful) {
              file.progress = 100;
              file.uploaded = true;
              this.snackBar.open(documentEvent.body.message, "OK", {
                duration: 3000,
              });
              let userCertificateModel: UserCertificateModel = {
                validity: new Date()
              }
              this.siteOperativeService.apiSiteOperativeSiteOperativeUserIdCertificateCertificateIdPost(this.siteOperativeId, documentEvent.body.result.id, userCertificateModel).subscribe( response => {
                console.log(response);
              })
            } else {
              file.progress = false;
              file.uploaded = false;
              this.snackBar.open(documentEvent.body.message, "OK", {
                duration: 3000,
              });
            }
          }
        })
      }
    })
  }

}
