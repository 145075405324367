import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '@app/_services';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Role} from "@app/_models";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
  })
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    roles = [
      {value: Role.Supervisor, viewValue: Role.Supervisor},
      {value: Role.Admin, viewValue: Role.Admin}
    ];
    loading = false;
    submitted = false;
    returnUrl: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private snackBar: MatSnackBar
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required]],
            password: ['', Validators.required],
            role: [Role.Supervisor, Validators.required]
        });
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    clearEmail(){
        this.f.email.setValue('');
    }

    keyDownFunction(event) {
        if (event.keyCode === 13) {
            this.onSubmit();
        }
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.email.value, this.f.password.value, this.f.role.value)
            .subscribe(loginResponse => {
                this.loading = false;
                this.snackBar.open(loginResponse.message, "OK", {
                    duration: 3000,
                });
                if(loginResponse.isSuccessful){
                    this.router.navigate([this.returnUrl]);
                }
            },
            error => {
                console.log(error);
                this.snackBar.open(error, "OK", {
                    duration: 3000,
                });
                this.loading = false;
            });
    }

  changeRole(role){

  }
}
