/**
 * worksite_track
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CloseCall } from '../model/closeCall';
import { CloseCallActionExecutionResponse } from '../model/closeCallActionExecutionResponse';
import { CloseCallExecutionResponse } from '../model/closeCallExecutionResponse';
import { CloseCallModel } from '../model/closeCallModel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { environment } from '../../environments/environment'
import {CloseCallCount} from '../model/closeCallCount';


@Injectable()
export class CloseCallService {

    public basePath = environment.basePath;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiCloseCallCountGet(observe?: 'body', reportProgress?: boolean): Observable<CloseCallCount>;
  public apiCloseCallCountGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CloseCallCount>>;
  public apiCloseCallCountGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CloseCallCount>>;
  public apiCloseCallCountGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json;odata.metadata=minimal;odata.streaming=true',
      'application/json;odata.metadata=minimal;odata.streaming=false',
      'application/json;odata.metadata=minimal',
      'application/json;odata.metadata=full;odata.streaming=true',
      'application/json;odata.metadata=full;odata.streaming=false',
      'application/json;odata.metadata=full',
      'application/json;odata.metadata=none;odata.streaming=true',
      'application/json;odata.metadata=none;odata.streaming=false',
      'application/json;odata.metadata=none',
      'application/json;odata.streaming=true',
      'application/json;odata.streaming=false',
      'application/json',
      'application/xml',
      'application/prs.odatatestxx-odata',
      'text/plain',
      'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<CloseCallCount>('get',`${this.basePath}/api/close-call/count`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCloseCallGet(odataQuery: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CloseCall>>;
    public apiCloseCallGet(observe?: 'body', reportProgress?: boolean): Observable<Array<CloseCall>>;
    public apiCloseCallGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CloseCall>>>;
    public apiCloseCallGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CloseCall>>>;
    public apiCloseCallGet(odataQuery = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/plain',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        return this.httpClient.request<Array<CloseCall>>('get',`${this.basePath}/api/close-call${odataQuery}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCloseCallHistoryGet(odataQuery: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CloseCall>>;
    public apiCloseCallHistoryGet(observe?: 'body', reportProgress?: boolean): Observable<Array<CloseCall>>;
    public apiCloseCallHistoryGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CloseCall>>>;
    public apiCloseCallHistoryGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CloseCall>>>;
    public apiCloseCallHistoryGet(odataQuery = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/plain',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CloseCall>>('get',`${this.basePath}/api/close-call/history${odataQuery}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
     /**
     *
     *
     * @param id
     * @param action
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCloseCallIdActionPost(id: number, action?: string, observe?: 'body', reportProgress?: boolean): Observable<CloseCallActionExecutionResponse>;
    public apiCloseCallIdActionPost(id: number, action?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CloseCallActionExecutionResponse>>;
    public apiCloseCallIdActionPost(id: number, action?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CloseCallActionExecutionResponse>>;
    public apiCloseCallIdActionPost(id: number, action?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiCloseCallIdActionPost.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (action !== undefined && action !== null) {
            queryParameters = queryParameters.set('action', <any>action);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/plain',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CloseCallActionExecutionResponse>('post',`${this.basePath}/api/close-call/${encodeURIComponent(String(id))}/action`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param assignedToUserId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCloseCallIdAssignPut(id: number, assignedToUserId?: number, observe?: 'body', reportProgress?: boolean): Observable<CloseCallExecutionResponse>;
    public apiCloseCallIdAssignPut(id: number, assignedToUserId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CloseCallExecutionResponse>>;
    public apiCloseCallIdAssignPut(id: number, assignedToUserId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CloseCallExecutionResponse>>;
    public apiCloseCallIdAssignPut(id: number, assignedToUserId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiCloseCallIdAssignPut.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (assignedToUserId !== undefined && assignedToUserId !== null) {
            queryParameters = queryParameters.set('assignedToUserId', <any>assignedToUserId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/plain',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CloseCallExecutionResponse>('put',`${this.basePath}/api/close-call/${encodeURIComponent(String(id))}/assign`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCloseCallIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<CloseCall>;
    public apiCloseCallIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CloseCall>>;
    public apiCloseCallIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CloseCall>>;
    public apiCloseCallIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiCloseCallIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/plain',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CloseCall>('get',`${this.basePath}/api/close-call/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param resolution
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCloseCallIdResolvePut(id: number, resolution?: string, observe?: 'body', reportProgress?: boolean): Observable<CloseCallExecutionResponse>;
    public apiCloseCallIdResolvePut(id: number, resolution?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CloseCallExecutionResponse>>;
    public apiCloseCallIdResolvePut(id: number, resolution?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CloseCallExecutionResponse>>;
    public apiCloseCallIdResolvePut(id: number, resolution?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiCloseCallIdResolvePut.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (resolution !== undefined && resolution !== null) {
            queryParameters = queryParameters.set('resolution', <any>resolution);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/plain',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CloseCallExecutionResponse>('put',`${this.basePath}/api/close-call/${encodeURIComponent(String(id))}/resolve`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCloseCallPost(body?: CloseCallModel, observe?: 'body', reportProgress?: boolean): Observable<CloseCallExecutionResponse>;
    public apiCloseCallPost(body?: CloseCallModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CloseCallExecutionResponse>>;
    public apiCloseCallPost(body?: CloseCallModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CloseCallExecutionResponse>>;
    public apiCloseCallPost(body?: CloseCallModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/plain',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CloseCallExecutionResponse>('post',`${this.basePath}/api/close-call`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
