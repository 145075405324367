import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {LoginResponseModel, User} from 'src/services-generated';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthenticationService} from '@app/_services';
import { Applicant, ApplicantService, FormService } from 'src/umbrella-pack-services-generated'; 
import { HelperService } from 'src/umbrella-pack-services-generated/api/helper.service';
import { ReportService } from 'src/umbrella-pack-services-generated/api/report.service';
import { environment } from "src/environments/environment";
import { Packs } from 'src/enums/packs';


@Component({
  selector: 'app-applicant-list',
  templateUrl: './applicant-list.component.html',
  styleUrls: ['./applicant-list.component.scss']
})
export class ApplicantListComponent implements OnInit {
  loading = false;
  packName = "";
  pageSize = 5;
  companies = [{id: 0, name: "- Any -"}];
  safetyHandbookChoices = ["- Any -", "Yes", "No"];
  formStatuses = ["- Any -", "new", "partial", "completed"];
  filterForm: FormGroup;
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  displayedColumns: string[] = [ "formId", "formStatus", "company", "name", "lastname", "email", "mobileNo", "hasAgreedCompanyTerms", "hasAgreedContractOfSponsorship", "hasAgreedSafetyHandbook", "action"];
  applicants: MatTableDataSource<Applicant> = new MatTableDataSource<Applicant>();
  error = '';
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private applicantService: ApplicantService,
    private formService: FormService,
    private helperService: HelperService,
    private reportService: ReportService,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.packName = this.route.snapshot.paramMap.get('packName') || "";
    if(this.packName != "umbrella_pack") {
      this.displayedColumns = [ "formId", "formStatus", "name", "lastname", "email", "mobileNo", "hasAgreedCompanyTerms", "hasAgreedContractOfSponsorship", "hasAgreedSafetyHandbook", "action"];
    }
    this.filterForm = this.formBuilder.group({
      formId: [''],
      formStatus: [''],
      hasAgreedCompanyTerms: [''],
      hasAgreedContractOfSponsorship: [''],
      hasAgreedSafetyHandbook: [''],
      company: [''],
      name: [''],
    });

    this.helperService.apiHelperGetUmbrellaCompaniesGet()
    .subscribe(response => {
      let umbrellaCompanies = response['result'];
      umbrellaCompanies.forEach(company => {
        this.companies.push(company);
      });
    })
    this.applicantService.apiApplicantGet(`?$select=Id,FormId&$expand=FormStatusDetails($select=Id,PackId,HasAgreedSafetyHandbook,FormStatus,HasAgreedCompanyTerms,HasAgreedContractOfSponsorship,UmbrellaCompanyId)&$expand=PersonalDatas($select=FirstName,LastName,Email,MobileNo,HomePhone,PackId)&$filter=PersonalDatas/any(PersonalData: PersonalData/PackId eq ${Packs[this.packName]})`).subscribe((applicantResponse)=>{
      let applicants = applicantResponse.map((applicant)=>{
        let personalDataForPack = applicant.PersonalDatas.find(personalData => {
          return personalData.PackId == Packs[this.packName];
        })
        let formStatusDetailForPack = applicant.FormStatusDetails.find(formStatusDetail => {
          return formStatusDetail.PackId == Packs[this.packName];
        })
        let company = this.companies.find(company => {
          return company.id == formStatusDetailForPack.UmbrellaCompanyId;
        });
        return {
          id: applicant['Id'],
          formId: applicant['FormId'],
          formStatus: formStatusDetailForPack['FormStatus'] ? formStatusDetailForPack['FormStatus'] : '',
          hasAgreedCompanyTerms: formStatusDetailForPack['HasAgreedCompanyTerms'] ? formStatusDetailForPack['HasAgreedCompanyTerms'] : '',
          hasAgreedContractOfSponsorship: formStatusDetailForPack['HasAgreedContractOfSponsorship'] ? formStatusDetailForPack['HasAgreedContractOfSponsorship'] : '',
          hasAgreedSafetyHandbook: formStatusDetailForPack['HasAgreedSafetyHandbook'] ? formStatusDetailForPack['HasAgreedSafetyHandbook'] : '',
          company: company? company.name : '',
          firstName: personalDataForPack['FirstName'] ? personalDataForPack['FirstName'] : '',
          lastName: personalDataForPack['LastName'] ? personalDataForPack['LastName'] : '',
          email: personalDataForPack['Email'] ? personalDataForPack['Email'] : '',
          mobileNo: personalDataForPack['MobileNo'] ? personalDataForPack['MobileNo'] : ''
        }
      })
      this.applicants.data = applicants;
      this.paginator.pageIndex = 0;
      this.applicants.paginator = this.paginator;
    });
  }

  get f() { return this.filterForm.controls; }

  delete(applicant: Applicant){
    if(confirm(`Are you sure to delete ${applicant.formId}?`)) {
      this.formService.apiFormFormIdDelete(applicant.formId).subscribe(
        (response) => {
          if(response.isSuccessful)
          {
            this.onFilter();
          }
          else {
            alert(response.message);
          }
        }
      )
    }
  }

  downloadPDF(applicant: Applicant){
    var atag = document.createElement('a');
    atag.href = `${environment.umbrellaPackUIBasePath}/applicant/${this.packName}/${applicant.formId}/export`
    atag.target = "_blank"
    atag.click();
  }

  download(applicant: Applicant){
    var atag = document.createElement('a');
    atag.href = `${this.reportService.basePath}/api/Report/${applicant.formId}/download`
    atag.target = "download"
    atag.download = `${applicant.formId}.zip`
    atag.click();
  }

  view(applicant: Applicant){
    this.router.navigate([`/hr/${this.packName}/applicant/${applicant.id}/view`]);
  }

  onFilter() {
    let filterString = `&$filter=PersonalDatas/any(PersonalData: PersonalData/PackId eq ${Packs[this.packName]})`;
    let companyFilter = this.filterForm.get('company').value;
    if(companyFilter != undefined && companyFilter != null && companyFilter != 0)
    {
      filterString += `and FormStatusDetails/any(FormStatusDetail: FormStatusDetail/UmbrellaCompanyId eq ${companyFilter})`
    }


    let safetyHandbookFilter = this.filterForm.get('hasAgreedSafetyHandbook').value;
    if(safetyHandbookFilter != undefined && safetyHandbookFilter.trim() != '' && safetyHandbookFilter.trim() != '- Any -')
    {
      if(safetyHandbookFilter == "Yes")
      {
        filterString += `and FormStatusDetails/any(FormStatusDetail: FormStatusDetail/HasAgreedSafetyHandbook eq true)`
      }
      else {
        filterString += `and FormStatusDetails/any(FormStatusDetail: FormStatusDetail/HasAgreedSafetyHandbook eq false)`
      }
    }

    let formStatusFilter = this.filterForm.get('formStatus').value;
    if(formStatusFilter != undefined && formStatusFilter.trim() != '' && formStatusFilter.trim() != '- Any -')
    {
      filterString += `and FormStatusDetails/any(FormStatusDetail: FormStatusDetail/formStatus eq '${formStatusFilter}')`
    }

    let formNameFilter = this.filterForm.get('name').value;
    if(formNameFilter != undefined && formNameFilter.trim() != '')
    {
      formNameFilter = formNameFilter.toLowerCase();
      filterString += `and PersonalDatas/any(PersonalData: Contains(tolower(PersonalData/FirstName),'${formNameFilter}') or Contains(tolower(PersonalData/LastName),'${formNameFilter}'))`// or Contains(tolower(PersonalData/LastName),'${formNameFilter}')`
    }


    this.applicantService.apiApplicantGet(`?$select=Id,FormId&$expand=FormStatusDetails($select=Id,PackId,HasAgreedSafetyHandbook,FormStatus,HasAgreedCompanyTerms,HasAgreedContractOfSponsorship,UmbrellaCompanyId)&$expand=PersonalDatas($select=FirstName,LastName,Email,MobileNo,HomePhone,PackId)${filterString}`).subscribe((applicantResponse)=>{
      let applicants = applicantResponse.map((applicant)=>{
        let personalDataForPack = applicant.PersonalDatas.find(personalData => {
          return personalData.PackId == Packs[this.packName];
        })
        let formStatusDetailForPack = applicant.FormStatusDetails.find(formStatusDetail => {
          return formStatusDetail.PackId == Packs[this.packName];
        })
        
        let company = this.companies.find(company => {
          if(formStatusDetailForPack){
            return company.id == formStatusDetailForPack.UmbrellaCompanyId;
          } else {
            return false;
          }
        });
        return {
          id: applicant['Id'],
          formId: applicant['FormId'],
          formStatus: formStatusDetailForPack ? formStatusDetailForPack['FormStatus'] : '',
          hasAgreedCompanyTerms: formStatusDetailForPack ? formStatusDetailForPack['HasAgreedCompanyTerms'] : '',
          hasAgreedContractOfSponsorship: formStatusDetailForPack ? formStatusDetailForPack['HasAgreedContractOfSponsorship'] : '',
          hasAgreedSafetyHandbook: formStatusDetailForPack ? formStatusDetailForPack['HasAgreedSafetyHandbook'] : '',
          company: company? company.name : '',
          firstName: personalDataForPack ? personalDataForPack['FirstName'] : '',
          lastName: personalDataForPack ? personalDataForPack['LastName'] : '',
          email: personalDataForPack ? personalDataForPack['Email'] : '',
          mobileNo: personalDataForPack ? personalDataForPack['MobileNo'] : ''
        }
      })
      this.applicants.data = applicants;
      this.paginator.pageIndex = 0;
      this.applicants.paginator = this.paginator;
    });
  }

}
