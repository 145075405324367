export const report_data = 
[
    {
      "imageSrc": "assets/images/weekly_data.svg",
      "title": "Weekly Report",
      "routerLink": "/report/dashboard/weekly/",
      "content": ""
    },
    {
      "imageSrc": "assets/images/monthly_data.svg",
      "title": "Monthly Report",
      "routerLink": "/report/dashboard/monthly/",
      "content": ""
    },
    {
      "imageSrc": "assets/images/proxy_auth.png",
      "title": "Rule Book",
      "routerLink": "/supervisor/dashboard/rule-book-list",
      "content": ""
    },
    {
      "imageSrc": "assets/images/live.png",
      "title": "Live",
      "routerLink": "/supervisor/dashboard/live/",
      "content": "This is your main dashboard of the app which identifies the current operatives logged in to the app and their current location on the map."
    },
    {
      "imageSrc": "assets/images/dashboard.svg",
      "title": "Dashboard",
      "routerLink": "/report/dashboard/view",
      "content": ""
    },
    {
      "imageSrc": "assets/images/comparison.svg",
      "title": "Compare Indicators",
      "routerLink": "/report/dashboard/compare-same",
      "content": ""
    },
    {
      "imageSrc": "assets/images/history.png",
      "title": "History",
      "routerLink": "/supervisor/dashboard/history/",
      "content": "This is the report running function of the App."
    },
    {
      "imageSrc": "assets/images/close_call.png",
      "title": "Close Call",
      "routerLink": "/supervisor/dashboard/close-call/live",
      "content": "This screen allows you to manage the process of close calls."
    },
    {
      "imageSrc": "assets/images/proxy_auth.png",
      "title": "Site Operative Authentication",
      "routerLink": "/supervisor/dashboard/proxy-authentication/login",
      "content": "This area of the App allows the Supervisor to log a site operative in or out of their site locations if the site operative is unable to do this via the App."
    },
    {
      "imageSrc": "assets/images/weekly_data.svg",
      "title": "Vehicle log",
      "routerLink": "/supervisor/vehicle-log/",
      "content": ""
    },
    {
      "imageSrc": "assets/images/weekly_data.svg",
      "title": "Site Familiarisation",
      "routerLink": "/supervisor/sitefamiliarisation",
      "content": ""
    }
  ];
  