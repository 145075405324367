import { TypeScriptEmitter } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from '@app/_models';
import {AccountService, Document, LoginResponseModel, User} from 'src/services-generated';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserModel} from '../../../../services-generated/model/userModel';
import {AuthenticationService} from '@app/_services';

@Component({
  selector: 'app-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.scss']
})
export class AdminListComponent implements OnInit {
  loading = false;
  // totalSiteOperatives = 30;
  pageSize = 5;
  adminForm: FormGroup;
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  displayedColumns: string[] = [ "name", "lastname", "email", "phoneNumber", "action"];
  admins: MatTableDataSource<User> = new MatTableDataSource<User>();
  error = '';
  currentUser: LoginResponseModel;
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private authenticationService: AuthenticationService,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.accountService.apiAccountGet(`?$filter=(role/name eq 'Administrator' and isActive eq true)&$skip=1`).subscribe((accountResponse)=>{
      let admins = accountResponse.map((admin)=>{
        return {
          id: admin.id,
          firstName: admin.firstName,
          lastName: admin.lastName,
          email: admin.email,
          phoneNumber: admin.phoneNumber
        }
      })
      this.admins.data = admins;
      this.paginator.pageIndex = 0;
      // this.paginator.length = 30;
      this.admins.paginator = this.paginator;
    });
    this.currentUser = this.authenticationService.currentUserValue;
    console.log(this.currentUser, "this.currentUser")
  }

  get f() { return this.adminForm.controls; }

  delete(user: User){
    console.log("user:", user);
    let userDelete = {
      isActive : false,
    }
    this.accountService.apiAccountIdPut(user.id, userDelete).subscribe(deleteResponse =>{
      this.snackBar.open(deleteResponse.message, "OK", {
        duration: 3000,
      });
      if(deleteResponse.isSuccessful) {
        this.accountService.apiAccountGet(`?$filter=(role/name eq 'Administrator' and isActive eq true)&$skip=1`).subscribe((accountResponse)=>{
          let admins = accountResponse.map((admin)=>{
            return {
              id: admin.id,
              firstName: admin.firstName,
              lastName: admin.lastName,
              email: admin.email,
              phoneNumber: admin.phoneNumber
            }
          })
          this.admins.data = admins;
          this.paginator.pageIndex = 0;
          // this.paginator.length = 30;
          this.admins.paginator = this.paginator;
        });
      }
    })
  }

  edit(admin: User){
    console.log("admin:", admin);
    this.router.navigate([`/admin/edit/${admin.id}`]);
  }
  // handlePageChange(event: PageEvent) {
  //   // stop here if form is invalid
  //   this.accountService.apiAccountGet(`?$filter=(role/name eq 'SiteOperative')&$top=${event.pageSize}&$skip=${event.pageSize*(event.pageIndex)}`).subscribe((accountResponse)=>{
  //     let admins = accountResponse.map((admin)=>{
  //       return {
  //         name: admin.name,
  //         lastname: admin.name,
  //         email: admin.email,
  //         phoneNumber: admin.phoneNumber,
  //         sentinelNumber: admin['adminDetail'].sentinelNumber,
  //         homePostCode: admin['adminDetail'].homePostCode,
  //         businessDeliveryUnit: admin['adminDetail'].businessDeliveryUnit,
  //       }
  //     })
  //     this.admins.data = admins;
  //     this.paginator.pageIndex = 0;
  //     this.paginator.length = 30;
  //   });
  // }

  resetPassword(admin: User){
    console.log("admin:", admin);
    this.router.navigate([`/admin/reset-password/${admin.id}`]);
  }                       

}
