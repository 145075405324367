import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home';
import { AdminComponent } from './admin-pages/admin';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_helpers';
import { Role } from './_models';
import { AdminPagesComponent } from './admin-pages/admin-pages.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SiteOperativePasswordComponent } from './admin-pages/site-operative/site-operative-password/site-operative-password.component';
import { SiteOperativeDetailComponent } from './admin-pages/site-operative/site-operative-detail/site-operative-detail.component';
import { SiteOperativeBulkComponent } from './admin-pages/site-operative/site-operative-bulk/site-operative-bulk.component';
import { SiteOperativeListComponent } from './admin-pages/site-operative/site-operative-list/site-operative-list.component';
import { SafetyDocumentDetailComponent } from './admin-pages/safety-documents/safety-document-detail/safety-document-detail.component';
import { SafetyDocumentListComponent } from './admin-pages/safety-documents/safety-document-list/safety-document-list.component';
import { SecurityQuestionDetailComponent } from './admin-pages/security-questions/security-question-detail/security-question-detail.component';
import { SecurityQuestionListComponent } from './admin-pages/security-questions/security-question-list/security-question-list.component';
import { SupervisorDetailComponent } from './admin-pages/supervisor/supervisor-detail/supervisor-detail.component';
import { SupervisorResetPasswordComponent } from './admin-pages/supervisor/supervisor-reset-password/supervisor-reset-password.component';
import { SupervisorListComponent } from './admin-pages/supervisor/supervisor-list/supervisor-list.component';
import { AdminDetailComponent } from './admin-pages/admin/admin-detail/admin-detail.component';
import { AdminResetPasswordComponent } from './admin-pages/admin/admin-reset-password/admin-reset-password.component';
import { AdminListComponent } from './admin-pages/admin/admin-list/admin-list.component';
import { PrivacyPolicyComponent } from "@app/privacy-policy/privacy-policy.component";
import { SupervisorPagesComponent } from "@app/supervisor-pages/supervisor-pages.component";
import { SupervisorHomeComponent } from "@app/supervisor-pages/supervisor-home/supervisor-home.component";
import { LiveComponent } from "@app/supervisor-pages/live/live.component";
import { DashboardComponent } from './supervisor-pages/dashboard/dashboard.component';
import { HistoryComponent } from './supervisor-pages/history/history.component';
import { LiveCloseCallComponent } from './supervisor-pages/live-close-call/live-close-call.component';
import { HistoryCloseCallComponent } from './supervisor-pages/history-close-call/history-close-call.component';
import { SiteOperativeLoginComponent } from './supervisor-pages/siteoperative-login/siteoperative-login.component';
import { SiteOperativeLogoutComponent } from './supervisor-pages/siteoperative-logout/siteoperative-logout.component';
import { SiteOperativeProxyListComponent } from './supervisor-pages/siteoperative-proxy-list/siteoperative-proxy-list.component';
import { AssignCloseCallComponent } from '@app/supervisor-pages/live-close-call/assign-close-call/assign-close-call.component';
import { ResolveCloseCallComponent } from '@app/supervisor-pages/live-close-call/resolve-close-call/resolve-close-call.component';
import { CloseCallActionComponent } from './supervisor-pages/live-close-call/close-call-action/close-call-action.component';
import { HistoryLoginComponent } from './supervisor-pages/history-login/history-login.component';
import { ReportPagesComponent } from './report-pages/report-pages.component';
import { ReportHomeComponent } from './report-pages/report-home/report-home.component';
import { ReportDashboardComponent } from './report-pages/dashboard/report-dashboard.component';
import { WeeklyDataComponent } from './report-pages/weekly-data/weekly-data.component';
import { MonthlyDataComponent } from './report-pages/monthly-data/monthly-data.component';
import { LoginAttempFailuresListComponent } from './supervisor-pages/login-attempt-failures/login-attempt-failures-list.component';
import { WeeklyDataViewComponent } from './report-pages/weekly-data-view/weekly-data-view.component';
import { MonthlyDataViewComponent } from './report-pages/monthly-data-view/monthly-data-view.component';
import { CreateDashboardComponent } from './report-pages/create-dashboard/create-dashboard.component';
import { ViewDashboardComponent } from './report-pages/view-dashboard/view-dashboard.component';
import { CompareSameIndicatorComponent } from './report-pages/compare-same-indicator/compare-same-indicator.component';
import { CompareDifferentIndicatorComponent } from './report-pages/compare-different-indicator/compare-different-indicator.component';
import { HrPagesComponent } from './hr-pages/hr-pages';
import { HrHomeComponent } from './hr-pages/hr-home/hr-home.component';
import { ApplicantListComponent } from './hr-pages/applicant/applicant-list/applicant-list.component';
import { ApplicantDetailComponent } from './hr-pages/applicant/applicant-detail/applicant-detail.component';
import { iOSAppComponent } from './ios-app/ios-app.component';
import { SiteOperativeCertificateAddComponent } from './admin-pages/site-operative/site-operative-certificate-add/site-operative-certificate-add.component';
import { RuleBookDetailComponent } from './admin-pages/rule-book/rule-book-detail/rule-book-detail.component';
import { RuleBookListComponent } from './admin-pages/rule-book/rule-book-list/rule-book-list.component';
import { RuleBookUserListComponent } from './supervisor-pages/rule-book-user-list/rule-book-user-list.component';
import { RuleBookUserDetailsListComponent } from './supervisor-pages/rule-book-user-details-list/rule-book-user-details-list.component';
import { RuleBookTagsComponent } from './admin-pages/rule-book/rule-book-tags/rule-book-tags.component';
import { VehicleLogComponent } from './supervisor-pages/vehicle-log/vehicle-log.component';
import { ViewDetailVehicleComponent } from './supervisor-pages/view-detail-vehicle/view-detail-vehicle.component';
import { SitFamiliarisationComponent } from './supervisor-pages/sit-familiarisation/sit-familiarisation.component';
import { SiteFamiliarisationPostComponent } from './supervisor-pages/site-familiarisation-post/site-familiarisation-post.component';
import { AddNewSiteComponent } from './supervisor-pages/add-new-site/add-new-site.component';
import { SiteListsComponent } from './supervisor-pages/site-lists/site-lists.component';
import { AddNewEditSiteComponent } from './supervisor-pages/add-new-edit-site/add-new-edit-site.component';
import { NoticeBoardDocumentListComponent } from './admin-pages/notice-board/notice-board-list/notice-board-list.component';
import { NoticeBoardDetailComponent } from './admin-pages/notice-board/notice-board-detail/notice-board-detail.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password/:email/:token',
    component: ResetPasswordComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'ios-app',
    component: iOSAppComponent
  },
  {
    path: 'admin',
    component: AdminPagesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.Supervisor, Role.ReportOperative] },
    children: [
      { path: '', component: AdminComponent },
      { path: 'add', component: AdminDetailComponent },
      { path: 'edit/:id', component: AdminDetailComponent },
      { path: 'list', component: AdminListComponent },
      { path: 'site-operative/add', component: SiteOperativeDetailComponent },
      { path: 'site-operative/edit/:id', component: SiteOperativeDetailComponent },
      { path: 'site-operative/edit/:id/certificate-add', component: SiteOperativeCertificateAddComponent },
      { path: 'site-operative/reset-password/:id', component: SiteOperativePasswordComponent },
      { path: 'site-operative/add/bulk', component: SiteOperativeBulkComponent },
      { path: 'site-operative', component: SiteOperativeListComponent },
      { path: 'supervisor/add', component: SupervisorDetailComponent },
      { path: 'supervisor/edit/:id', component: SupervisorDetailComponent },
      { path: 'supervisor/reset-password/:id', component: SupervisorResetPasswordComponent },
      { path: 'supervisor', component: SupervisorListComponent },
      { path: 'safety-document/add', component: SafetyDocumentDetailComponent },
      { path: 'safety-document/edit/:id', component: SafetyDocumentDetailComponent },
      { path: 'safety-document', component: SafetyDocumentListComponent },
      { path: 'notice-board/add', component: NoticeBoardDetailComponent },
      { path: 'notice-board/edit/:id', component: NoticeBoardDetailComponent },
      { path: 'notice-board', component: NoticeBoardDocumentListComponent },
      { path: 'rule-book/add', component: RuleBookDetailComponent },
      { path: 'rule-book-tags', component: RuleBookTagsComponent },
      { path: 'rule-book/edit/:id', component: RuleBookDetailComponent },
      { path: 'rule-book', component: RuleBookListComponent },
      { path: 'security-question/add', component: SecurityQuestionDetailComponent },
      { path: 'security-question/edit/:id', component: SecurityQuestionDetailComponent },
      { path: 'security-question', component: SecurityQuestionListComponent },
      { path: 'reset-password/:id', component: AdminResetPasswordComponent }
      
    ]
  },
  {
    path: 'supervisor',
    component: SupervisorPagesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Supervisor, Role.ReportOperative] },
    children: [
      { path: '', component: SupervisorHomeComponent },
      { path: 'vehicle-log', component: VehicleLogComponent },
      { path: 'sitefamiliarisation', component: SitFamiliarisationComponent },
      { path: 'sitefamiliarisationPost', component: SiteFamiliarisationPostComponent },
      { path: 'sitefamiliarisationPost/:id', component: SiteFamiliarisationPostComponent },
      { path: 'AddSite', component: AddNewSiteComponent },
      { path: 'EditAddSite', component: AddNewEditSiteComponent },
      { path: 'SiteList', component: SiteListsComponent },
      { path: 'vehicle-edit1', component: ViewDetailVehicleComponent },
      { path: 'vehicle-edit1/:id', component: ViewDetailVehicleComponent },
      {
        path: 'dashboard',
        component: DashboardComponent,
        children: [
          { path: 'live', component: LiveComponent },
          { path: 'rule-book-list', component: RuleBookUserListComponent },
          { path: 'rule-book-list/:id', component: RuleBookUserDetailsListComponent },
          { path: 'history', component: HistoryComponent },
          { path: 'vehicle-edit', component: ViewDetailVehicleComponent },
          { path: 'vehicle-edit/:id', component: ViewDetailVehicleComponent },
          { path: 'close-call/live', component: LiveCloseCallComponent },
          { path: 'close-call/history', component: HistoryCloseCallComponent },
          { path: 'login/history', component: HistoryLoginComponent },
          { path: 'close-call/assign/:id', component: AssignCloseCallComponent },
          { path: 'close-call/resolve/:id', component: ResolveCloseCallComponent },
          { path: 'close-call/action/:id', component: CloseCallActionComponent },
          { path: 'proxy-authentication/list', component: SiteOperativeProxyListComponent },
          { path: 'proxy-authentication/login', component: SiteOperativeLoginComponent },
          { path: 'proxy-authentication/logout', component: SiteOperativeLogoutComponent },
          { path: 'login-attempt/failures', component: LoginAttempFailuresListComponent }
        ]
      },
    ]
  },
  {
    path: 'report',
    component: ReportPagesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ReportOperative] },
    children: [
      { path: '', component: ReportHomeComponent },
      {
        path: 'dashboard',
        component: ReportDashboardComponent,
        children: [
          { path: 'create', component: CreateDashboardComponent },
          { path: 'view', component: ViewDashboardComponent },
          { path: 'weekly', component: WeeklyDataComponent },
          { path: 'monthly', component: MonthlyDataComponent },
          { path: 'weekly-view', component: WeeklyDataViewComponent },
          { path: 'monthly-view', component: MonthlyDataViewComponent },
          { path: 'compare-same', component: CompareSameIndicatorComponent },
          { path: 'compare-different', component: CompareDifferentIndicatorComponent }
        ]
      },
    ]
  },
  {
    path: 'hr',
    component: HrPagesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.HrAdmin] },
    children: [
      { path: '', component: HrHomeComponent },
      { path: ':packName/applicant', component: ApplicantListComponent },
      { path: ':packName/applicant/new', component: ApplicantDetailComponent },
      { path: ':packName/applicant/:id/view', component: ApplicantDetailComponent }
    ]
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

export const AppRoutingModule = RouterModule.forRoot(routes);
