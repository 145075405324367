import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import f from 'odata-filter-builder';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService, CloseCall, CloseCallService, LocationUpdateModel, SiteOperativeService} from '../../../../services-generated';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MouseEvent} from '@agm/core';
import {User} from '../../../../services-generated/model/user';
import { Role } from '@app/_models';

@Component({
  selector: 'app-assign-close-call',
  templateUrl: './assign-close-call.component.html',
  styleUrls: ['./assign-close-call.component.scss']
})
export class AssignCloseCallComponent implements OnInit, OnDestroy {
  loading = false;
  closeCallData: CloseCall = {};
  subQueryParam;
  assignedToId = 0;
  siteOperatives = [];
  supervisors = [];
  markers = [];
  siteOperative = new FormControl('', Validators.required);
  supervisor = new FormControl('', Validators.required);
  error = '';
  firstName = new FormControl();
  sentinelNumber = new FormControl();
  closeCallId = null;
  imageUrls:string[]=[];
  imageIndexView:number=0;
  odataQuery = '?$filter=' +  f.and().contains('role/name', Role.SiteOperative).eq('isActive', true).toString();
  odataSupervisorQuery = '?$filter=' +  f.and().contains('role/name', Role.Supervisor).toString();
  constructor(private route: ActivatedRoute,
              private router: Router,
              private siteOperativeService: SiteOperativeService,
              private closeCallService: CloseCallService,
              private accountService: AccountService,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.accountService.apiAccountGet(this.odataQuery).subscribe((accountResponse)=>{
      let siteOperatives = accountResponse.map((siteOperative)=>{
        return {
          id: siteOperative.id,
          name: siteOperative.firstName,
          lastname: siteOperative.lastName,
          email: siteOperative.email,
          phoneNumber: siteOperative.phoneNumber,
          sentinelNumber: siteOperative['siteOperativeDetail'].sentinelNumber,
          homePostCode: siteOperative['siteOperativeDetail'].homePostCode,
          businessDeliveryUnit: siteOperative['siteOperativeDetail'].businessDeliveryUnit,
        }
      })
      this.siteOperatives = siteOperatives;
    });
    this.accountService.apiAccountGet(this.odataSupervisorQuery).subscribe((accountResponse)=>{
      let supervisors = accountResponse.map((supervisor)=>{
        return {
          id: supervisor.id,
          name: supervisor.firstName,
          lastname: supervisor.lastName,
          email: supervisor.email,
          phoneNumber: supervisor.phoneNumber,
        }
      })
      this.supervisors = supervisors;
    });
    if(this.route.snapshot.paramMap.get('id')){
      this.closeCallId = this.route.snapshot.paramMap.get('id');
      let query = '?$filter=id eq ' + this.closeCallId ;
      this.closeCallService.apiCloseCallGet(query).subscribe(closeCall => {
        this.closeCallData =closeCall[0];
        this.imageUrls=this.extractImageUrls(this.closeCallData.photoUrls);
        this.assignedToId = this.closeCallData.assignedToId || 0;
        // TODO - Fetch roleId values or return role object in API response 
        if(this.closeCallData.assignedTo && this.closeCallData.assignedTo.roleId == 2){
          this.supervisor.setValue(this.assignedToId);
        } else {
          this.siteOperative.setValue(this.assignedToId);
        }
      })
    }
  }

  ngOnDestroy() {
  }

  extractImageUrls(inputString: string): string[] {
    const regex = /\/Photos\/\d+image\.jpg/g;
    if(inputString !=null){
      const matches = inputString.match(regex);
      if (matches) {
        return matches;
      } else {
        return [];
      }
    }else{
      return [];
    }
    
  }
  nextImage() {
    this.imageIndexView= (this.imageIndexView +1);
  }

  previousImage() {
    this.imageIndexView= (this.imageIndexView -1);
  }

  // downloadAll(imageUrls){
  //   imageUrls = imageUrls.map(x => 'https://tracker.sw-gr.com' + x).join(",");
  //   console.log(imageUrls);
  //   var file = new Blob([imageUrls], { type: 'application/zip' });
  //   const url = window.URL.createObjectURL(file);
  //   window.open(url);
  //   //saveAs(file, 'images.zip');
  // }

  onSubmit() {
    // stop here if values are invalid
    if (this.siteOperative.invalid && this.supervisor.invalid) {
      this.siteOperative.markAsDirty();
      return;
    }
    let assigneeId = this.siteOperative.value || this.supervisor.value
    this.loading = true;
    this.closeCallService.apiCloseCallIdAssignPut(this.closeCallId, assigneeId)
      .subscribe(closeCallAssignResponse => {
          this.loading = false;
          if(closeCallAssignResponse.isSuccessful){
            this.snackBar.open(closeCallAssignResponse.message, "OK", {
              duration: 3000,
            });
            this.router.navigate(['/supervisor/dashboard/close-call/live']);
          } else{
            this.snackBar.open(closeCallAssignResponse.message, "OK", {
              duration: 3000,
            });
          }
        },
        error => {
          console.log(error);
          this.snackBar.open(error, "OK", {
            duration: 3000,
          });
          this.loading = false;
        });
  }

  handleFilterChange(){
    let filterString = f.and()
      .contains('siteOperativeDetail/sentinelNumber' , this.sentinelNumber.value? this.sentinelNumber.value : "")
      .and(f.or()
      .contains('firstName' , this.firstName.value ? this.firstName.value : "")
      .contains('lastName' , this.firstName.value ? this.firstName.value : ""))
      .toString();
    this.odataQuery = this.odataQuery + ' and ' + filterString
    if(filterString == ""){
      this.odataQuery = "";
    }
    this.ngOnInit();
  }

  handleAssigneeSelection(selection: string) {
    if(selection == Role.SiteOperative){
      this.supervisor.setValue(null);
    } else {
      this.siteOperative.setValue(null);
    }
  }

}
