import { TypeScriptEmitter } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from '@app/_models';
import {AccountService, LoginQuestion, LoginQuestionModel, LoginQuestionService} from 'src/services-generated';
import { SiteOperativeModel } from 'src/services-generated/model/siteOperativeModel';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-security-question-list',
  templateUrl: './security-question-list.component.html',
  styleUrls: ['./security-question-list.component.scss']
})
export class SecurityQuestionListComponent implements OnInit {
  loading = false;
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  displayedColumns: string[] = [ "action", "question", "answers", "correctAnswer", "isActive"];
  isActiveValue = true;
  loginQuestions: MatTableDataSource<LoginQuestion> = new MatTableDataSource<LoginQuestion>();
  error = '';
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private loginQuestionService: LoginQuestionService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.loginQuestionService.apiLoginQuestionGet(`?$filter=isActive eq ${this.isActiveValue}`).subscribe((loginQuestionResponse)=>{
      this.loginQuestions.data = loginQuestionResponse;
      this.paginator.pageIndex = 0;
      this.loginQuestions.paginator = this.paginator;
    });
  }

  delete(securityQuestion: LoginQuestionModel){
    securityQuestion.isActive = false
    this.loginQuestionService.apiLoginQuestionIdPut(securityQuestion.id, securityQuestion ).subscribe(deleteResponse =>{
      this.snackBar.open(deleteResponse.message, "OK", {
        duration: 3000,
      });
      if(deleteResponse.isSuccessful) {
        this.loginQuestionService.apiLoginQuestionGet(`?$filter=isActive eq ${this.isActiveValue}`).subscribe((loginQuestionResponse)=>{
          this.loginQuestions.data = loginQuestionResponse;
        });
      }
    })
  }

  undelete(securityQuestion: LoginQuestionModel){
    securityQuestion.isActive = true
    this.loginQuestionService.apiLoginQuestionIdPut(securityQuestion.id, securityQuestion ).subscribe(deleteResponse =>{
      this.snackBar.open(deleteResponse.message, "OK", {
        duration: 3000,
      });
      if(deleteResponse.isSuccessful) {
        this.loginQuestionService.apiLoginQuestionGet(`?$filter=isActive eq ${this.isActiveValue}`).subscribe((loginQuestionResponse)=>{
          this.loginQuestions.data = loginQuestionResponse;
        });
      }
    })
  }

  edit(securityQuestion){
    this.router.navigate([`/admin/security-question/edit/${securityQuestion.id}`]);
  }

}
