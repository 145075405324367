import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/_services';
import { report_data } from '@app/report-pages/report-home';

@Component({
  selector: 'app-supervisor-home',
  templateUrl: './supervisor-home.component.html',
  styleUrls: ['./supervisor-home.component.scss']
})
export class SupervisorHomeComponent implements OnInit {
  cards: { imageSrc: string; title: string; routerLink: string; content: string; }[];

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit() {
    const currentUser = this.authenticationService.currentUserValue;
    this.cards = report_data.filter(function(element){
      if(element.routerLink.includes("supervisor") && currentUser['userModules'].find(x => x.moduleName == element.title).isRead)
        return element;
    });
  }

}
