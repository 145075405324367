import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CompactType, GridsterConfig, GridsterItem, GridType } from 'angular-gridster2';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { InputPageService } from 'src/report-services-generated';
import { FieldValueService } from 'src/report-services-generated/api/fieldValue.service';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material';


@Component({
  selector: 'app-compare-different-indicator',
  templateUrl: './compare-different-indicator.component.html',
  styleUrls: ['./compare-different-indicator.component.scss']
})
export class CompareDifferentIndicatorComponent implements OnInit {

  constructor(
    private fieldValueService: FieldValueService,
    private inputPageService: InputPageService,
    private _snackbar: MatSnackBar
  ) { }
  leftIndicator = [{ "id": 0, "name": ""}];
  rightIndicator = [{ "id": 0, "name": ""}];
  indicatorOptions = ['One', 'Two', 'Three'];
  leftChart = { "dataSet": [], "chartLabels": []};
  rightChart = { "dataSet": [], "chartLabels": []};
  leftFilteredOptions: Observable<any>;
  rightFilteredOptions: Observable<any>;
  filteredChartOptions: Observable<any>;
  myLeftControl = new FormControl();
  myRightControl = new FormControl();
  chartFieldControl = new FormControl();
  leftFields: any[] = [];
  rightFields: any[] = [];
  weeklyInputId:number = 1;
  monthlyInputId:number = 2;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  dateRangeSelected: {startDate: moment.Moment, endDate: moment.Moment} = {
    startDate: moment().add(-28, 'days'),
    endDate: moment()
  }

  ngOnInit() {
    this.inputPageService.apiInputPageIdGet(this.weeklyInputId).subscribe(res => {
      res.sections.forEach(section => {
        this.leftFields.push(...section.fields);
        this.rightFields.push(...section.fields);
      });      
    })
    this.inputPageService.apiInputPageIdGet(this.monthlyInputId).subscribe(res => {
      res.sections.forEach(section => {
        this.leftFields.push(...section.fields);
        this.rightFields.push(...section.fields);
      });
    });
    
    this.fieldValueService.apiFieldValueGet(this.leftIndicator[0].id,moment(this.dateRangeSelected.startDate).format("yyyy-MM-DD"),moment(this.dateRangeSelected.endDate).format("yyyy-MM-DD")).subscribe(res => {
      let chartDataArray = [];
      let chartLabelArray = [];
      res.forEach(instance => {
        chartDataArray.push(instance['Value']);
        chartLabelArray.push(this.getFormattedDate(instance['Date']));
      })
      this.leftChart.dataSet = [{ data: chartDataArray, label: this.leftIndicator[0].name, fill: false }];
      this.leftChart.chartLabels = chartLabelArray; 
      
      this.myLeftControl.setValue(this.leftIndicator[0].name);
      this.chartFieldControl.setValue(this.chartType);
      console.log(this.leftChart);
    });
    this.fieldValueService.apiFieldValueGet(this.rightIndicator[0].id,moment(this.dateRangeSelected.startDate).format("yyyy-MM-DD"),moment(this.dateRangeSelected.endDate).format("yyyy-MM-DD")).subscribe(res => {
      let chartDataArray = [];
      let chartLabelArray = [];
      res.forEach(instance => {
        chartDataArray.push(instance['Value']);
        chartLabelArray.push(this.getFormattedDate(instance['Date']));
      })
      this.rightChart.dataSet = [{ data: chartDataArray, label: this.rightIndicator[0].name, fill: false }];
      this.rightChart.chartLabels = chartLabelArray; 
      
      this.myRightControl.setValue(this.rightIndicator[0].name);
      this.chartFieldControl.setValue(this.chartType);
    });
    this.leftFilteredOptions = this.myLeftControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterLeft(value))
    );
    this.rightFilteredOptions = this.myRightControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterRight(value))
    );
    this.filteredChartOptions = this.chartFieldControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterChartTypes(value))
    );
}

private _filterLeft(value: string): string[] {
  const filterValue = value.toLowerCase();
  return this.leftFields.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
}

private _filterRight(value: string): string[] {
  const filterValue = value.toLowerCase();
  return this.rightFields.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
}

private _filterChartTypes(value: string): string[] {
  const filterValue = value.toLowerCase();
  return this.chartTypeOptions.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
}

getFormattedDate(dateString: string){
  return moment(new Date(dateString)).format("MM/DD/YYYY");
}

selectLeftIndicator(value){
  value = this.leftFields.filter(field => value == field.name);
  this.leftIndicator = value;
  this.ngOnInit();
}

selectRightIndicator(value){
  value = this.rightFields.filter(field => value == field.name);
  this.rightIndicator = value;
  this.ngOnInit();
}

selectGraphType(value){
  this.chartType = value;
  this.ngOnInit();
}


//Chart Settings
chartTypeOptions = ['line','pie','bar']
public chartData: ChartDataSets[] = [{data: [], fill: false}];

public chartOptions: (ChartOptions & { annotation ?: any }) = {
  responsive: true,
  scales: {
    yAxes: [{
        ticks: {
            beginAtZero: true
        }
    }]
  }
};

public chartLabels: Label[] = [];
public chartColors: Color[] = [
  {
    borderColor: '#EB4000BF',
    backgroundColor: ['#EB4000BF', '#F0D12FBF', '#19183BBF', '#304585BF', '#2B69B6BF', '#6F9CD340BF'],
  },
];
public chartLegend = true;
public chartType = 'line';
public chartPlugins = [];


handleFilterChange(){
  this.ngOnInit();
}

}
