import { Pipe } from '@angular/core';

@Pipe({
  name: 'boolstr'
})
export class BoolStrPipe {
  private static defaultArgs = ["Yes", "No"]

  transform(val, args) {
    if (args === undefined || args.length != 2) {
      args = BoolStrPipe.defaultArgs;
    }

    if (val) {
      return args[0];
    } else {
      return args[1];
    }
  }
}