import { TypeScriptEmitter } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from '@app/_models';
import { AccountService, AdministratorService, PasswordResetRequestModel } from 'src/services-generated';
import { UserPasswordResetRequestModel } from 'src/services-generated/model/userPasswordResetRequestModel';

@Component({
  selector: 'app-site-operative-password',
  templateUrl: './site-operative-password.component.html',
  styleUrls: ['./site-operative-password.component.scss']
})
export class SiteOperativePasswordComponent implements OnInit {
  loading = false;
  editMode = false;
  siteOperativeId = null;
  siteOperativeForm: FormGroup;
  error = '';
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private adminService: AdministratorService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.siteOperativeForm = this.formBuilder.group({
      sentinelNumber: [{value: '', disabled:true}, Validators.required],
      homePostCode: [{value: '', disabled:true}],
      businessDeliveryUnit: [{value: '', disabled:true}],
      email: [{value: '', disabled:true}, [Validators.required, Validators.email]],
      phoneNumber: [{value: '', disabled:true}, [Validators.required, Validators.pattern("^[0-9]*$")]],
      firstName: [{value: '', disabled:true}, Validators.required],
      lastName: [{value: '', disabled:true}],
      password: [{value: '', disabled:false}, Validators.required],
      role: [Role.SiteOperative]
    });
    if(this.route.snapshot.paramMap.get('id')){
      this.siteOperativeId = this.route.snapshot.paramMap.get('id');
      this.accountService.apiAccountSiteOperativeIdGet(parseInt(this.siteOperativeId)).subscribe(siteOperativeResponse => {
        let siteOperativeData = siteOperativeResponse.result;
        this.siteOperativeForm.setValue({
          sentinelNumber: siteOperativeData.sentinelNumber,
          homePostCode: siteOperativeData.homePostCode,
          businessDeliveryUnit: siteOperativeData.businessDeliveryUnit,
          email: siteOperativeData.email,
          phoneNumber: siteOperativeData.phoneNumber,
          firstName: siteOperativeData.firstName,
          lastName: siteOperativeData.lastName,
          password: "********",
          role: Role.SiteOperative
        })
      })
      this.editMode = false;
    }
  }

  get f() { return this.siteOperativeForm.controls; }

  onSubmit() {

    // stop here if form is invalid
    if (this.siteOperativeForm.invalid) {
        return;
    }
    console.log(this.siteOperativeForm.value);
    this.loading = true;
    let formValue = this.siteOperativeForm.value;
    let body: UserPasswordResetRequestModel = {
      password: formValue.password,
      userId: parseInt(this.siteOperativeId)
    }
    this.adminService.apiAdministratorResetUserPasswordPost(body)
    .subscribe(siteOperativeResponse => {
      this.loading = false;
      if(siteOperativeResponse.isSuccessful){
          this.snackBar.open(siteOperativeResponse.message, "OK", {
            duration: 3000,
          });
          this.router.navigate(['/admin/site-operative']);
      } else{
        this.snackBar.open(siteOperativeResponse.message, "OK", {
          duration: 3000,
        });
      }
    },
    error => {
        console.log(error);
        this.snackBar.open(error, "OK", {
          duration: 3000,
        });
        this.loading = false;
    });
}

}
