import { TypeScriptEmitter } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from '@app/_models';
import {AccountService, User, UserModel} from 'src/services-generated';
import { SiteOperativeModel } from 'src/services-generated/model/siteOperativeModel';
import {MatSnackBar} from '@angular/material';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-site-operative-list',
  templateUrl: './site-operative-list.component.html',
  styleUrls: ['./site-operative-list.component.scss']
})
export class SiteOperativeListComponent implements OnInit {
  selected = 'selectedvehilceId';
  loading = false;
  // totalSiteOperatives = 30;
  pageSize = 5;
  isActiveValue = true;
  siteOperativeForm: FormGroup;
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  displayedColumns: string[] = ["action", "name", "lastname", "email", "phoneNumber", "sentinelNumber", "homePostCode", "businessDeliveryUnit"];
  siteOperatives: MatTableDataSource<User> = new MatTableDataSource<User>();
  error = '';
  constructor(private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.accountService.apiAccountGet(`?$filter=(role/name eq 'SiteOperative' and isActive eq ${this.isActiveValue})`).subscribe((accountResponse)=>{
      let siteOperatives = accountResponse.map((siteOperative)=>{
        return {
          id: siteOperative['id'],
          isActive: siteOperative['isActive'],
          name: siteOperative['firstName'],
          lastname: siteOperative['lastName'],
          email: siteOperative['email'],
          phoneNumber: siteOperative['phoneNumber'],
          sentinelNumber: siteOperative['siteOperativeDetail'] ? siteOperative['siteOperativeDetail']['sentinelNumber'] : "",
          homePostCode: siteOperative['siteOperativeDetail'] ? siteOperative['siteOperativeDetail']['homePostCode'] : "",
          businessDeliveryUnit: siteOperative['siteOperativeDetail'] ? siteOperative['siteOperativeDetail']['businessDeliveryUnit'] : ""
        }
      })
      this.siteOperatives.data = siteOperatives;
      this.paginator.pageIndex = 0;
      // this.paginator.length = 30;
      this.siteOperatives.paginator = this.paginator;
    });
    this.siteOperatives.filterPredicate = function(data, filter: string): boolean {
      if(data.lastname && data.sentinelNumber && data.name) {
        return data.lastname.toLowerCase().includes(filter) || data.sentinelNumber.toLowerCase().includes(filter)
        || data.name.toLowerCase().includes(filter);
      } else if(data.sentinelNumber) {
        return data.sentinelNumber.toLowerCase().includes(filter);
      } else if(data.name) {
        return data.name.toLowerCase().includes(filter);
      } else {
        return false
      }
    };
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.siteOperatives.filter = filterValue;
  }

  get f() { return this.siteOperativeForm.controls; }

  delete(siteOperative: SiteOperativeModel){
    siteOperative.isActive = false;
    this.accountService.apiAccountSiteOperativeIdPut(siteOperative.id, siteOperative).subscribe(deleteResponse =>{
      this.snackBar.open(deleteResponse.message, "OK", {
        duration: 3000,
      });
      if(deleteResponse.isSuccessful) {
        this.accountService.apiAccountGet(`?$filter=(role/name eq 'SiteOperative' and isActive eq ${this.isActiveValue})`).subscribe((accountResponse)=>{
          let siteOperatives = accountResponse.map((siteOperative)=>{
            return {
              id: siteOperative['id'],
              isActive: siteOperative['isActive'],
              name: siteOperative['firstName'],
              lastname: siteOperative['lastName'],
              email: siteOperative['email'],
              phoneNumber: siteOperative['phoneNumber'],
              sentinelNumber: siteOperative['siteOperativeDetail'] ? siteOperative['siteOperativeDetail']['sentinelNumber'] : "",
              homePostCode: siteOperative['siteOperativeDetail'] ? siteOperative['siteOperativeDetail']['homePostCode'] : "",
              businessDeliveryUnit: siteOperative['siteOperativeDetail'] ? siteOperative['siteOperativeDetail']['businessDeliveryUnit'] : ""
            }
          })
          this.siteOperatives.data = siteOperatives;
        });
      }
    })
  }

  undelete(siteOperative: SiteOperativeModel){
    siteOperative.isActive = true;
    this.accountService.apiAccountSiteOperativeIdPut(siteOperative.id, siteOperative).subscribe(deleteResponse =>{
      this.snackBar.open(deleteResponse.message, "OK", {
        duration: 3000,
      });
      if(deleteResponse.isSuccessful) {
        this.accountService.apiAccountGet(`?$filter=(role/name eq 'SiteOperative' and isActive eq ${this.isActiveValue})`).subscribe((accountResponse)=>{
          let siteOperatives = accountResponse.map((siteOperative)=>{
            return {
              id: siteOperative['id'],
              isActive: siteOperative['isActive'],
              name: siteOperative['firstName'],
              lastname: siteOperative['lastName'],
              email: siteOperative['email'],
              phoneNumber: siteOperative['phoneNumber'],
              sentinelNumber: siteOperative['siteOperativeDetail'] ? siteOperative['siteOperativeDetail']['sentinelNumber'] : "",
              homePostCode: siteOperative['siteOperativeDetail'] ? siteOperative['siteOperativeDetail']['homePostCode'] : "",
              businessDeliveryUnit: siteOperative['siteOperativeDetail'] ? siteOperative['siteOperativeDetail']['businessDeliveryUnit'] : ""
            }
          })
          this.siteOperatives.data = siteOperatives;
        });
      }
    });
  }

  edit(siteOperative: User){
    debugger
    this.router.navigate([`/admin/site-operative/edit/${siteOperative.id}`]);
  }

  resetPassword(siteOperative: User){
    this.router.navigate([`/admin/site-operative/reset-password/${siteOperative.id}`]);
  }

  exportTableAsExcel() {
    let exportArray = [];
    this.siteOperatives.data.forEach((siteOperative: any) => {
        let siteOperativeToExport = {
          "First Name": siteOperative.name,
          "Last Name": siteOperative.lastname,
          "Email": siteOperative.email,
          "Phone Number": siteOperative.phoneNumber,
          "Sentinel Number": siteOperative.sentinelNumber,
          "Home PostCode": siteOperative.homePostCode,
          "Business Delivery Unit": siteOperative.businessDeliveryUnit,
          "IsActive": siteOperative.isActive,
        }
        exportArray.push(siteOperativeToExport);
      });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportArray)
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    let currentTimestamp = new Date().toString();
    currentTimestamp = this.datePipe.transform(Date.now(),'yyyyMMdd_mmss');
    XLSX.writeFile(wb,  'closecalls_' + currentTimestamp + '.xlsx');
  }

  // handlePageChange(event: PageEvent) {
  //   // stop here if form is invalid
  //   this.accountService.apiAccountGet(`?$filter=(role/name eq 'SiteOperative')&$top=${event.pageSize}&$skip=${event.pageSize*(event.pageIndex)}`).subscribe((accountResponse)=>{
  //     let siteOperatives = accountResponse.map((siteOperative)=>{
  //       return {
  //         name: siteOperative.name,
  //         lastname: siteOperative.name,
  //         email: siteOperative.email,
  //         phoneNumber: siteOperative.phoneNumber,
  //         sentinelNumber: siteOperative['siteOperativeDetail'].sentinelNumber,
  //         homePostCode: siteOperative['siteOperativeDetail'].homePostCode,
  //         businessDeliveryUnit: siteOperative['siteOperativeDetail'].businessDeliveryUnit,
  //       }
  //     })
  //     this.siteOperatives.data = siteOperatives;
  //     this.paginator.pageIndex = 0;
  //     this.paginator.length = 30;
  //   });
  // }

}
