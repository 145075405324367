import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SiteApiService } from 'src/services-generated/api/site-api.service';
import { SiteModelPost } from 'src/services-generated/model/SiteModelPost';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-new-site',
  templateUrl: './add-new-site.component.html',
  styleUrls: ['./add-new-site.component.scss']
})
export class AddNewSiteComponent implements OnInit {
  loading = false;
  SiteName = new FormControl("", Validators.required);
  PrimaryLocation = new FormControl("", Validators.required);
  SecondaryLocation = new FormControl("", Validators.required);
  InfrastructureId = new FormControl("", Validators.required);
  PostalCode = new FormControl("", Validators.required);
  snackBar: any;
  constructor(private SiteApiService: SiteApiService, private router: Router) { }

  ngOnInit() {
  }
  onSubmit() {
    let Details: SiteModelPost = {
      name: this.SiteName.value,
      location: this.PrimaryLocation.value,
      secondaryLocation: this.SecondaryLocation.value,
      infrastructureId: this.InfrastructureId.value,
      postalCode: this.PostalCode.value

    };
    this.loading = true;
    this.SiteApiService.apiSitePost(Details).subscribe((siteResponse) => {
      console.log('postDetails', siteResponse)
      this.loading = false;
      if (siteResponse.isSuccessful = true) {

        Swal.fire({
          icon: 'success',
          title: 'Site Add successfully',
          text: siteResponse["Message"],
        }).then((res) => {
          // Fetch the updated table data and update the table
          this.router.navigate(['../supervisor/SiteList']);
        });

      } else {
        Swal.fire({
          icon: 'error',
          title: 'failed!',
          text: siteResponse["Message"],
        })
      }
    },
      (error) => {
        console.log(error);
        this.snackBar.open(error, "OK", {
          duration: 3000,
        });
        this.loading = false;

      })
  }

  goBack(){
    var data = localStorage.getItem("SelectedOperativeId");
    this.router.navigate([
      "/supervisor/sitefamiliarisationPost",
      { id: data },
    ]);

  }
 

}

