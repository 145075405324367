import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { InputPageService } from './api/inputPage.service';
import { FieldValueService } from './api/fieldValue.service';
import { FieldService } from './api/field.service';
import { DashboardService } from './api/dashboard.service';
import { GraphTypeService } from './api/graphType.service';
import { FieldTargetService } from './api/fieldTarget.service';
import { FieldCommentService } from './api/fieldComment.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
      DashboardService,
      FieldValueService,
      FieldTargetService,
      FieldService,
      GraphTypeService,
      InputPageService,
      FieldCommentService
]
})
export class ReportsApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: ReportsApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ReportsApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ReportsApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
