  import { TypeScriptEmitter } from '@angular/compiler';
  import { Component, OnInit, ViewChild } from '@angular/core';
  import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
  import { MatPaginator, PageEvent } from '@angular/material/paginator';
  import { MatTableDataSource } from '@angular/material/table';
  import { ActivatedRoute, Router } from '@angular/router';
  import { Role } from '@app/_models';
  import { AccountService, SiteOperativeService, User } from 'src/services-generated';
  import f from 'odata-filter-builder';
  import {Moment} from 'moment';
  import * as moment from 'moment';

@Component({
  selector: 'app-login-attempt-failures-list',
  templateUrl: './login-attempt-failures-list.component.html',
  styleUrls: ['./login-attempt-failures-list.component.scss']
})
export class LoginAttempFailuresListComponent implements OnInit {
  loading = false;
  // totalSiteOperatives = 30;
  pageSize = 5;
  siteOperativeForm: FormGroup;
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  dateRange = new FormControl();
  dateRangeSelected: {startDate: Moment, endDate: Moment} = {
    startDate: moment().add(-1, 'days'),
    endDate: moment()
  }
  odataQuery = "?$orderBy=createdAt desc&$filter=" + f.and().eq('isFailure', true) + ' and ' + f.and().lt('createdAt', this.dateRangeSelected.endDate.toISOString(), false)
  .gt('createdAt', this.dateRangeSelected.startDate.toISOString(), false);;
  displayedColumns: string[] = ["type", "firstName", "lastName", "email", "phoneNumber", "sentinelNumber", "createdAt", "createdBy"];
  siteOperatives: MatTableDataSource<any> = new MatTableDataSource<any>();
  error = '';
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private siteOperativeService: SiteOperativeService) { }

  ngOnInit() {
    this.siteOperativeService.apiSiteOperativeLoginAttemptGet(this.odataQuery).subscribe((proxyLoginList)=>{
      let siteOperatives = proxyLoginList.map((loginItem)=>{
        return {
          id: loginItem.id,
          firstName: loginItem.user.firstName,
          lastName: loginItem.user.lastName,
          phoneNumber: loginItem.user.phoneNumber,
          isProxyLogin: loginItem.isProxyLogin,
          email: loginItem.user.email,
          sentinelNumber: loginItem.user.siteOperativeDetail.sentinelNumber,
          createdAt: loginItem.createdAt,
          createdBy: loginItem.createdBy.email
        }
      })
      this.siteOperatives.data = siteOperatives;
      this.paginator.pageIndex = 0;
      // this.paginator.length = 30;
      this.siteOperatives.paginator = this.paginator;
    });
  }

  handleFilterChange(event){
    let filterString = f.and()
      .lt('createdAt', this.dateRangeSelected.endDate.toISOString(), false)
      .gt('createdAt', this.dateRangeSelected.startDate.toISOString(), false);

    this.odataQuery = "?$orderBy=createdAt desc&$filter=" + f.and().eq('isFailure', true) + " and " + filterString.toString();
    if(filterString.toString() == ""){
      this.odataQuery = "";
    }
    this.ngOnInit();
  }


}
