/**
 * worksite_track
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { SiteFamiliarisationModel } from '../model/siteFamiliarisation';
import { siteFamiliarisationGetListExecutionResponse } from '../model/siteFamiliarisationGetResponseModel';
import { SiteFamiliarisationPostModel } from '../model/siteFamiliarisationPostModel';
import { SiteFamiliarisationPostModelResponse } from '../model/siteFamiliarisationPostResultResponse';
import { SiteFamiliarisationQuestionsssModelResponse } from '../model/siteFamiliarisationQuestionREsponseModel';
import { BASE_PATH } from '../variables';

@Injectable({
  providedIn: 'root'
})
export class SiteFamilarisationsService {
  protected basePath = environment.basePath;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) { }

/**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
public apiSiteFamiliarisationGet(observe?: 'body', reportProgress?: boolean): Observable<Array<SiteFamiliarisationPostModelResponse>>;
public apiSiteFamiliarisationGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SiteFamiliarisationPostModelResponse>>>;
public apiSiteFamiliarisationGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SiteFamiliarisationPostModelResponse>>>;
public apiSiteFamiliarisationGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    let headers = this.defaultHeaders;

    if (this.configuration.accessToken) {
        const accessToken = typeof this.configuration.accessToken === 'function'
            ? this.configuration.accessToken()
            : this.configuration.accessToken;
        headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    let httpHeaderAccepts: string[] = [
        'application/json;odata.metadata=minimal;odata.streaming=true',
        'application/json;odata.metadata=minimal;odata.streaming=false',
        'application/json;odata.metadata=minimal',
        'application/json;odata.metadata=full;odata.streaming=true',
        'application/json;odata.metadata=full;odata.streaming=false',
        'application/json;odata.metadata=full',
        'application/json;odata.metadata=none;odata.streaming=true',
        'application/json;odata.metadata=none;odata.streaming=false',
        'application/json;odata.metadata=none',
        'application/json;odata.streaming=true',
        'application/json;odata.streaming=false',
        'application/json',
        'application/xml',
        'application/prs.odatatestxx-odata',
        'text/plain',
        'text/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    const consumes: string[] = [
    ];

    return this.httpClient.request<Array<SiteFamiliarisationPostModelResponse>>('get',`${this.basePath}/api/Site`,
    
        {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        }
    );
}

     /**
      * 
      * 
      * @param siteOperativeUserId 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiSiteFamiliarisationGetID(siteOperativeUserId: number, observe?: 'body', reportProgress?: boolean): Observable<siteFamiliarisationGetListExecutionResponse>;
     public apiSiteFamiliarisationGetID(siteOperativeUserId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<siteFamiliarisationGetListExecutionResponse>>;
     public apiSiteFamiliarisationGetID(siteOperativeUserId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<siteFamiliarisationGetListExecutionResponse>>;
     public apiSiteFamiliarisationGetID(siteOperativeUserId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
         if (siteOperativeUserId === null || siteOperativeUserId === undefined) {
             throw new Error('Required parameter siteOperativeUserId was null or undefined when calling apiSiteOperativeSiteOperativeUserIdSiteFamiliarisationSite.');
         }
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json;odata.metadata=minimal;odata.streaming=true',
             'application/json;odata.metadata=minimal;odata.streaming=false',
             'application/json;odata.metadata=minimal',
             'application/json;odata.metadata=full;odata.streaming=true',
             'application/json;odata.metadata=full;odata.streaming=false',
             'application/json;odata.metadata=full',
             'application/json;odata.metadata=none;odata.streaming=true',
             'application/json;odata.metadata=none;odata.streaming=false',
             'application/json;odata.metadata=none',
             'application/json;odata.streaming=true',
             'application/json;odata.streaming=false',
             'application/json',
             'application/xml',
             'application/prs.odatatestxx-odata',
             'text/plain',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<siteFamiliarisationGetListExecutionResponse>('get',`${this.basePath}/api/site-operative/${encodeURIComponent(String(siteOperativeUserId))}/site`,
             {
            
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     getallsitefamdata() {
        return this.httpClient.request<siteFamiliarisationGetListExecutionResponse>('get',`${this.basePath}/api/site-operative/all/sites`);
     }

 /**
      * 
      * 
      * @param siteOperativeUserId 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
 public apiSiteFamiliarisationQuestionGetID(siteOperativeUserId: number, observe?: 'body', reportProgress?: boolean): Observable<SiteFamiliarisationQuestionsssModelResponse>;
 public apiSiteFamiliarisationQuestionGetID(siteOperativeUserId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SiteFamiliarisationQuestionsssModelResponse>>;
 public apiSiteFamiliarisationQuestionGetID(siteOperativeUserId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SiteFamiliarisationQuestionsssModelResponse>>;
 public apiSiteFamiliarisationQuestionGetID(siteOperativeUserId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
     if (siteOperativeUserId === null || siteOperativeUserId === undefined) {
         throw new Error('Required parameter siteOperativeUserId was null or undefined when calling apiSiteOperativeSiteOperativeUserIdSiteFamiliarisationSite.');
     }
     let headers = this.defaultHeaders;

     // to determine the Accept header
     let httpHeaderAccepts: string[] = [
         'application/json;odata.metadata=minimal;odata.streaming=true',
         'application/json;odata.metadata=minimal;odata.streaming=false',
         'application/json;odata.metadata=minimal',
         'application/json;odata.metadata=full;odata.streaming=true',
         'application/json;odata.metadata=full;odata.streaming=false',
         'application/json;odata.metadata=full',
         'application/json;odata.metadata=none;odata.streaming=true',
         'application/json;odata.metadata=none;odata.streaming=false',
         'application/json;odata.metadata=none',
         'application/json;odata.streaming=true',
         'application/json;odata.streaming=false',
         'application/json',
         'application/xml',
         'application/prs.odatatestxx-odata',
         'text/plain',
         'text/json'
     ];
     const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
     if (httpHeaderAcceptSelected != undefined) {
         headers = headers.set('Accept', httpHeaderAcceptSelected);
     }

     // to determine the Content-Type header
     const consumes: string[] = [
     ];

     return this.httpClient.request<SiteFamiliarisationQuestionsssModelResponse>('get',`${this.basePath}/api/site/${encodeURIComponent(String(siteOperativeUserId))}`,
         {
        
             withCredentials: this.configuration.withCredentials,
             headers: headers,
             observe: observe,
             reportProgress: reportProgress
         }
     );
 }

       
    /**
     * 
     * 
     * @param siteOperativeUserId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSiteOperativeSitefamiliarisationSiteOperativeIdPost(siteOperativeUserId: number, body?: SiteFamiliarisationPostModel, observe?: 'body', reportProgress?: boolean): Observable<SiteFamiliarisationPostModelResponse>;
    public apiSiteOperativeSitefamiliarisationSiteOperativeIdPost(siteOperativeUserId: number, body?: SiteFamiliarisationPostModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SiteFamiliarisationPostModelResponse>>;
    public apiSiteOperativeSitefamiliarisationSiteOperativeIdPost(siteOperativeUserId: number, body?: SiteFamiliarisationPostModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SiteFamiliarisationPostModelResponse>>;
    public apiSiteOperativeSitefamiliarisationSiteOperativeIdPost(siteOperativeUserId: number, body?: SiteFamiliarisationPostModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (siteOperativeUserId === null || siteOperativeUserId === undefined) {
            throw new Error('Required parameter siteOperativeUserId was null or undefined when calling apiSiteOperativeProxyLogoutSiteOperativeUserIdPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/plain',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SiteFamiliarisationPostModelResponse>('post',`${this.basePath}/api/site-operative/${encodeURIComponent(String(siteOperativeUserId))}/site`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public apiSiteOperativeSiteApproval(siteOperativeSiteId: number, isApproved: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (siteOperativeSiteId === null || siteOperativeSiteId === undefined) {
            throw new Error('Required parameter siteOperativeUserId was null or undefined when calling apiSiteOperativeProxyLogoutSiteOperativeUserIdPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/plain',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json;odata.metadata=minimal;odata.streaming=true',
            'application/json;odata.metadata=minimal;odata.streaming=false',
            'application/json;odata.metadata=minimal',
            'application/json;odata.metadata=full;odata.streaming=true',
            'application/json;odata.metadata=full;odata.streaming=false',
            'application/json;odata.metadata=full',
            'application/json;odata.metadata=none;odata.streaming=true',
            'application/json;odata.metadata=none;odata.streaming=false',
            'application/json;odata.metadata=none',
            'application/json;odata.streaming=true',
            'application/json;odata.streaming=false',
            'application/json',
            'application/xml',
            'application/prs.odatatestxx-odata',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SiteFamiliarisationPostModelResponse>('patch',`${this.basePath}/api/site/${encodeURIComponent(String(siteOperativeSiteId))}/${encodeURIComponent(String(isApproved))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}


