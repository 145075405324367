import { Component, OnInit } from '@angular/core';
import {LoginResponseModel} from "../../services-generated";
import {Router} from "@angular/router";
import {AuthenticationService} from "@app/_services";

@Component({
  selector: 'app-supervisor-pages',
  templateUrl: './supervisor-pages.component.html',
  styleUrls: ['./supervisor-pages.component.scss']
})
export class SupervisorPagesComponent implements OnInit {
  loading = false;
  currentUser: LoginResponseModel;
  currentYear = new Date().getFullYear();

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit() {
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
