import { Component } from '@angular/core';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '@app/_services';
import { LoginResponseModel } from 'src/services-generated';
import { Role } from '@app/_models';
import { Router } from '@angular/router';

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent {
    loading = false;
    currentUser: LoginResponseModel;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit() {
        if(this.currentUser.role == Role.Admin){
            this.router.navigate(['/admin']);
        }else if (this.currentUser.role == Role.Supervisor){
          this.router.navigate(['/supervisor']);
        }else if (this.currentUser.role == Role.ReportOperative){
            this.router.navigate(['/report']);
        }else if (this.currentUser.role == Role.HrAdmin){
            this.router.navigate(['/hr']);
        }
    }
}
