import { TypeScriptEmitter } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from '@app/_models';
import { AccountService, User } from 'src/services-generated';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-supervisor-list',
  templateUrl: './supervisor-list.component.html',
  styleUrls: ['./supervisor-list.component.scss']
})
export class SupervisorListComponent implements OnInit {
  loading = false;
  // totalSiteOperatives = 30;
  pageSize = 5;
  supervisorForm: FormGroup;
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  displayedColumns: string[] = [ "action", "firstName", "lastName", "email", "phoneNumber"];
  isActiveValue = true;
  supervisors: MatTableDataSource<User> = new MatTableDataSource<User>();
  error = '';
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    
    this.accountService.apiAccountGet(`?$filter=(role/name eq 'Supervisor' and isActive eq ${this.isActiveValue})`).subscribe((accountResponse)=>{
      let supervisors = accountResponse.map((supervisor)=>{
        return {
          id: supervisor.id,
          isActive: supervisor.isActive,
          firstName: supervisor.firstName,
          lastName: supervisor.lastName,
          email: supervisor.email,
          phoneNumber: supervisor.phoneNumber
        }
      })
      this.supervisors.data = supervisors;
      this.paginator.pageIndex = 0;
      // this.paginator.length = 30;
      this.supervisors.paginator = this.paginator;
    });
  }

  get f() { return this.supervisorForm.controls; }

  delete(supervisor){
    supervisor.isActive = false;
    this.accountService.apiAccountIdPut(supervisor.id, supervisor).subscribe(deleteResponse =>{
      this.snackBar.open(deleteResponse.message, "OK", {
        duration: 3000,
      });
      if(deleteResponse.isSuccessful) {
        this.accountService.apiAccountGet(`?$filter=(role/name eq 'Supervisor' and isActive eq ${this.isActiveValue})`).subscribe((accountResponse)=>{
          let supervisors = accountResponse.map((supervisor)=>{
            return {
              id: supervisor.id,
              isActive: supervisor.isActive,
              firstName: supervisor.firstName,
              lastName: supervisor.lastName,
              email: supervisor.email,
              phoneNumber: supervisor.phoneNumber
            }
          })
          this.supervisors.data = supervisors;
        });
      }
    })
  }

  undelete(supervisor){
    supervisor.isActive = true;
    this.accountService.apiAccountIdPut(supervisor.id, supervisor).subscribe(deleteResponse =>{
      this.snackBar.open(deleteResponse.message, "OK", {
        duration: 3000,
      });
      if(deleteResponse.isSuccessful) {
        this.accountService.apiAccountGet(`?$filter=(role/name eq 'Supervisor' and isActive eq ${this.isActiveValue})`).subscribe((accountResponse)=>{
          let supervisors = accountResponse.map((supervisor)=>{
            return {
              id: supervisor.id,
              isActive: supervisor.isActive,
              firstName: supervisor.firstName,
              lastName: supervisor.lastName,
              email: supervisor.email,
              phoneNumber: supervisor.phoneNumber
            }
          })
          this.supervisors.data = supervisors;
        });
      }
    })
  }

  edit(supervisor: User){
    this.router.navigate([`/admin/supervisor/edit/${supervisor.id}`]);
  }

  resetPassword(supervisor: User){
    this.router.navigate([`/admin/supervisor/reset-password/${supervisor.id}`]);
  }
}
