import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormControl, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { AccountService } from "src/services-generated";
import f from "odata-filter-builder";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { SiteFamilarisationsService } from "src/services-generated/api/site-familarisations.service";
import { MatSnackBar, MatTableDataSource } from "@angular/material";
import { SiteFamiliarisationPostModel } from "src/services-generated/model/siteFamiliarisationPostModel";
import { SiteFamiliarisationModel } from "src/services-generated/model/siteFamiliarisation";

@Component({
  selector: "app-site-familiarisation-post",
  templateUrl: "./site-familiarisation-post.component.html",
  styleUrls: ["./site-familiarisation-post.component.scss"],
})
export class SiteFamiliarisationPostComponent implements OnInit {
  loading = false;
  siteUserId: any;
  id: null;
  showTable = false;
  filteredSiteOperatives: Observable<string[]>;
  filteredSiteOperatives1: Observable<string[]>;

  markers = [];
  odataQuery =
    "?$filter=" +
    f
      .and()
      .contains("role/name", "siteDetails")
      .eq("isActive", true)
      .toString();

  odataQuery1 =
    "?$filter=" +
    f
      .and()
      .contains("role/name", "SiteOperative")
      .eq("isActive", true)
      .toString();

  error = "";
  siteDetailsInfo = [];
  siteOperatives = [];
  siteOperatives1 = [];
  siteDetails = new FormControl("", Validators.required);
  siteOperative = new FormControl("", Validators.required);
  siteOperative1 = new FormControl("", Validators.required);
  DatePickerData = new FormControl("", Validators.required);
  RailWeekNo = new FormControl(null, [
    Validators.required,
    Validators.min(1),
    Validators.max(53),
  ]);
  WonItemNo = new FormControl("", Validators.required);
  SiteFamiliarisationDetails: MatTableDataSource<SiteFamiliarisationModel> =
    new MatTableDataSource<SiteFamiliarisationModel>();
  SiteFamiliarisationDetailsList: SiteFamiliarisationModel[];

  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute,
    private siteFamiliarisationService: SiteFamilarisationsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const Id = params["id"];
      localStorage.setItem("SelectedOperativeId", Id);
    });
    this.siteFamiliarisationService
      .apiSiteFamiliarisationGet()
      .subscribe((SiteOoerativeResponse) => {
        let siteOperatives = SiteOoerativeResponse.map((siteOperative) => {
          return {
            id: siteOperative["id"],
            name: siteOperative["name"],
            location: siteOperative["location"],
            secondaryLocation: siteOperative["secondaryLocation"],
            infrastructureId: siteOperative["infrastructureId"],
            postalCode: siteOperative["postalCode"],
          };
        });
        this.siteOperatives = siteOperatives;
      });
    this.filteredSiteOperatives = this.siteOperative.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );

    this.accountService
      .apiAccountGet(this.odataQuery1)
      .subscribe((accountResponse) => {
        let siteOperatives1 = accountResponse.map((siteOperative1) => {
          return {
            id: siteOperative1["id"],
            sentinelNumber:
              siteOperative1["siteOperativeDetail"] != null
                ? siteOperative1["siteOperativeDetail"]["sentinelNumber"]
                : "",
            email: siteOperative1["email"],
            name:
              siteOperative1["firstName"] + " " + siteOperative1["lastName"],
          };
        });
        this.siteOperatives1 = siteOperatives1;
      });
    this.filteredSiteOperatives1 = this.siteOperative1.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterOperative(value))
    );
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.siteOperatives.filter((siteOperative) => {
      if (siteOperative.name && siteOperative.location) {
        return (
          siteOperative.name.toLowerCase().indexOf(filterValue) === 0 ||
          siteOperative.location.toLowerCase().indexOf(filterValue) === 0
        );
      } else {
        return false;
      }
    });
  }
  private _filterOperative(value: string): string[] {
    const filterValue1 = value.toLowerCase();
    return this.siteOperatives1.filter((siteOperative1) => {
      if (
        siteOperative1.email &&
        siteOperative1.sentinelNumber &&
        siteOperative1.name
      ) {
        return (
          siteOperative1.email.toLowerCase().indexOf(filterValue1) === 0 ||
          siteOperative1.sentinelNumber.toLowerCase().indexOf(filterValue1) ===
            0 ||
          siteOperative1.name.toLowerCase().indexOf(filterValue1) === 0
        );
      } else {
        return false;
      }
    });
  }

  onSubmit() {
    this.route.params.subscribe((params) => {
      const id = params["id"]; // changed 'Id' to 'id' to match route parameter name
      let date = new Date(this.DatePickerData.value);
      let RailWeekNo = this.RailWeekNo.value;
      let WonItemNo = this.WonItemNo.value;
      this.siteUserId = this.siteOperative.value.split(" | ")[0];
      let SiteFamiliarisationPost: SiteFamiliarisationPostModel = {
        siteId: parseInt(this.siteUserId),
        familiarisationDate: date,
        siteOperativeUserId: this.siteOperative1.value.split(" | ")[0],
        weekNo: RailWeekNo,
        wonItemNo: WonItemNo,
      };
      this.loading = true;
      this.siteFamiliarisationService
        .apiSiteOperativeSitefamiliarisationSiteOperativeIdPost(
          parseInt(id),
          SiteFamiliarisationPost
        )
        .subscribe(
          (siteOperativeResponse) => {
            this.loading = false;
            if (siteOperativeResponse.isSuccessful) {
              this.snackBar.open(siteOperativeResponse.message, "OK", {
                duration: 3000,
              });
              // this.router.navigate(['/supervisor/dashboard/proxy-authentication/list']);
            } else {
              this.snackBar.open(siteOperativeResponse.message, "OK", {
                duration: 3000,
              });
            }
          },
          (error) => {
            console.log(error);
            this.snackBar.open(error, "OK", {
              duration: 3000,
            });
            this.loading = false;
          }
        );
    });
  }
}
