import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Packs } from 'src/enums/packs';
import { ApplicantService, FormService } from 'src/umbrella-pack-services-generated';
import { HelperService } from 'src/umbrella-pack-services-generated/api/helper.service';

@Component({
  selector: 'app-applicant-detail',
  templateUrl: './applicant-detail.component.html',
  styleUrls: ['./applicant-detail.component.scss']
})
export class ApplicantDetailComponent implements OnInit {
  loading = false;
  packName = "";
  viewMode = false;
  applicantId = null;
  companies = [];
  newFormUrls = [];
  applicantForm: FormGroup;
  packForm: FormGroup;
  error = '';
  packNameMapping = {
    umbrella_pack: "Umbrella Pack",
    supply_chain_pack: "Supply Chain Pack",
    paye_worker_pack: "PAYE Worker Pack",
    paye_employee_pack: "PAYE Employee Pack"
  }
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private formService: FormService,
    private applicantService: ApplicantService,
    private helperService: HelperService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.packName = this.route.snapshot.paramMap.get('packName') || "";
    this.packForm = this.formBuilder.group({
      umbrella_pack: false,
      supply_chain_pack: false,
      paye_worker_pack: false,
      paye_employee_pack: false
    });
    this.applicantForm = this.formBuilder.group({
      email: [''],
      mobileNo: [''],
      firstName: [''],
      lastName: [''],
      formId: [''],
      formStatus: [''],
      hasAgreedCompanyTerms: [''],
      hasAgreedContractOfSponsorship: [''],
      hasAgreedSafetyHandbook: [''],
      company: ['0'],
    });

    this.helperService.apiHelperGetUmbrellaCompaniesGet()
    .subscribe(response => {
      let umbrellaCompanies = response['result'];
      umbrellaCompanies.forEach(company => {
        this.companies.push(company);
      });
    })

    if(this.route.snapshot.paramMap.get('id')){
      this.applicantId = this.route.snapshot.paramMap.get('id');
      this.applicantService.apiApplicantIdGet(parseInt(this.applicantId), `?$select=Id,FormId&$expand=FormStatusDetails($select=Id,PackId,HasAgreedSafetyHandbook,FormStatus,HasAgreedCompanyTerms,HasAgreedContractOfSponsorship,UmbrellaCompanyId)&$expand=PersonalDatas($select=FirstName,LastName,Email,MobileNo,HomePhone,PackId)`)
      .subscribe(applicantResponse => {
        let personalDataForPack = applicantResponse.PersonalDatas.find(personalData => {
          return personalData.PackId == Packs[this.packName];
        });
        let formStatusDetailForPack = applicantResponse.FormStatusDetails.find(formStatusDetail => {
          return formStatusDetail.PackId == Packs[this.packName];
        })
        
        let company = this.companies.find(company => {
          if(formStatusDetailForPack){
            return company.id == formStatusDetailForPack.UmbrellaCompanyId;
          } else {
            return false;
          }
        });
        this.applicantForm.setValue({
          formId: applicantResponse['FormId'],
          formStatus: formStatusDetailForPack ? formStatusDetailForPack['FormStatus'] : '',
          hasAgreedCompanyTerms: formStatusDetailForPack ? formStatusDetailForPack['HasAgreedCompanyTerms'] : '',
          hasAgreedContractOfSponsorship: formStatusDetailForPack ? formStatusDetailForPack['HasAgreedContractOfSponsorship'] : '',
          hasAgreedSafetyHandbook: formStatusDetailForPack ? formStatusDetailForPack['HasAgreedSafetyHandbook'] : '',
          company: company? company.name : '',
          firstName: personalDataForPack ? personalDataForPack['FirstName'] : '',
          lastName: personalDataForPack ? personalDataForPack['LastName'] : '',
          email: personalDataForPack ? personalDataForPack['Email'] : '',
          mobileNo: personalDataForPack ? personalDataForPack['MobileNo'] : ''
        })
      })
      this.viewMode = true;
      this.applicantForm.disable();
    }
  }

  get f() { return this.applicantForm.controls; }

  onSubmit() {

    // stop here if form is invalid or already processed
    if (this.applicantForm.invalid || this.newFormUrls.length != 0) {
      console.log(this.applicantForm.value);
      return;
    }
    this.loading = true;
    let selectedPackIds: number[] = [];
    selectedPackIds.push(Packs[this.packName])
    this.formService.apiFormUmbrellaCompanyIdPost(selectedPackIds).subscribe(formResponse => {
      this.loading = false;
      if(formResponse.isSuccessful){
          this.snackBar.open(formResponse.message, "OK", {
            duration: 3000,
          });
          this.newFormUrls = [];
          let urlParts = formResponse.result.split('applicant/');
          this.newFormUrls.push(urlParts[0]+ 'applicant/' + this.packName + '/' + urlParts[1]);
      } else{
        this.snackBar.open(formResponse.message, "OK", {
          duration: 3000,
        });
      }
    },
    error => {
        console.log(error);
        this.snackBar.open(error, "OK", {
          duration: 3000,
        });
        this.loading = false;
    });
  }

  copyLink(newFormUrl) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = newFormUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.snackBar.open("Form link copied to clipboard", "OK", {
      duration: 3000,
    });
}

}
