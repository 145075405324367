import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TagService } from 'src/services-generated/api/tag.service';
import { Tag } from 'src/services-generated/model/tag';
import { TagModel } from 'src/services-generated/model/tagModel';

@Component({
  selector: 'app-tag-form-modal',
  templateUrl: './tag-form-modal.component.html',
  styleUrls: ['./tag-form-modal.component.scss']
})
export class TagFormModalComponent implements OnInit {
  tagName: string = '';
  isActive: boolean = false;
  nameFormControl = new FormControl('', [Validators.required]);
  isEdit: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public tagData: Tag, private tagSerive: TagService, public dialogRef: MatDialogRef<TagFormModalComponent>) { }

  ngOnInit() {
    if (this.tagData) {
      this.isEdit = true;
      this.tagName = this.tagData.name;
      this.isActive = this.tagData.isActive;
    }
  }

  getButtonTitle() {
    return this.isEdit ? "Edit" : "Add";
  }
  getModalTitle() {
    return this.isEdit ? "Edit Tag" : "Create Tag";
  }

  submit() {
    if (this.isEdit) {
      this.editTag()
    }
    else {
      this.createTag();
    }
  }
  editTag() {
    if (this.nameFormControl.hasError('required')) {
      return
    }
    const body: TagModel = {
      name: this.tagName,
      isActive: this.isActive,
    }
    this.tagSerive.apiTagIdPut(this.tagData.id, body).subscribe(res => {
      this.dialogRef.close(res);
    })
  }
  createTag() {
    if (this.nameFormControl.hasError('required')) {
      return
    }
    const body: TagModel = {
      name: this.tagName,
      isActive: this.isActive
    }
    this.tagSerive.apiTagPost(body).subscribe(res => {
      this.dialogRef.close(res);
    })
  }

}
