import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {CloseCall, CloseCallService, LocationService, LoginAttempt, SiteOperativeService} from '../../../services-generated';
import {FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {environment} from '@environments/environment';
import {MatSelectChange} from '@angular/material/select';
import f from 'odata-filter-builder';
import * as XLSX from 'xlsx';
import {Subscription} from 'rxjs';
import {Moment} from 'moment';
import * as moment from 'moment';
import { AuthenticationService } from '@app/_services';
import { UserModule } from 'src/services-generated/model/UserModule.Model';

@Component({
  selector: 'app-history-login',
  templateUrl: './history-login.component.html',
  styleUrls: ['./history-login.component.scss']
})
export class HistoryLoginComponent implements OnInit, OnDestroy{
  loading = false;
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  displayedColumns: string[] = ["type", "firstName", "lastName", "email", "phoneNumber", "sentinelNumber", "createdAt", "createdBy"];
  siteOperatives: MatTableDataSource<any> = new MatTableDataSource<any>();
  apiCloseCallGetCountSubs: Subscription;
  closeCallCount:Number;
  country = new FormControl();
  state = new FormControl([]);
  city = new FormControl();
  dateRange = new FormControl();
  time = new FormControl();
  name = new FormControl();
  sentinelNumber = new FormControl();
  reportedBy = new FormControl();
  createdBy = new FormControl();
  dateRangeSelected: {startDate: Moment, endDate: Moment} = {
    startDate: moment().add(-1, 'days'),
    endDate: moment()
  }
  countries = ['UK'];
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  states = [];
  cities = [];
  odataQuery = "?$filter=" + f.and().lt('createdAt', this.dateRangeSelected.endDate.toISOString(), false)
  .gt('createdAt', this.dateRangeSelected.startDate.toISOString(), false);
  markers:any[] = [];
  userRights: UserModule;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private siteOperativeService: SiteOperativeService,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.country.setValue('UK');
    this.userRights = this.authenticationService.getUserRights("history");
    this.loading = true;
    this.siteOperativeService.apiSiteOperativeProxyHistoryGet(this.odataQuery).subscribe((proxyLoginList)=>{
      this.loading = false;
      let siteOperatives = proxyLoginList.map((loginItem)=>{
        return {
          id: loginItem.id,
          firstName: loginItem.user.firstName,
          lastName: loginItem.user.lastName,
          phoneNumber: loginItem.user.phoneNumber,
          isProxyLogin: loginItem.isProxyLogin,
          email: loginItem.user.email,
          sentinelNumber: loginItem.user.siteOperativeDetail.sentinelNumber,
          createdAt: loginItem.createdAt,
          createdBy: loginItem.createdBy.email
        }
      })
      this.siteOperatives.data = siteOperatives;
      this.paginator.pageIndex = 0;
      this.siteOperatives.paginator = this.paginator;
    });
  }

  ngOnDestroy() {
  }

  handleStateChange(event: MatSelectChange){
    console.log(this.state.value);
    let filterString = f('and')
      .in('location/state', this.state.value)
      .in('location/city', this.city.value)
      .toString();
    this.odataQuery = '?$filter=' + filterString
    if(filterString == ""){
      this.odataQuery = "";
    }

    this.ngOnInit();
  }

  handleCityChange(event: MatSelectChange){
    console.log(this.city.value);
    let filterString = f('and')
      .in('location/state', this.state.value)
      .in('location/city', this.city.value)
      .toString();
    this.odataQuery = '?$filter=' + filterString
    if(filterString == ""){
      this.odataQuery = "";
    }
    this.ngOnInit();
  }

  handleFilterChange(event: MatSelectChange){
    let filterString = f.and()
      .in('location/state', this.state.value)
      .in('location/city', this.city.value)
      .and(f.or()
        .contains('user/firstName' , this.name.value? this.name.value : "")
        .contains('user/lastName' , this.name.value? this.name.value : "")
      ).contains('user/siteOperativeDetail/sentinelNumber' , this.sentinelNumber.value? this.sentinelNumber.value : "")
      .lt('createdAt', this.dateRangeSelected.endDate.toISOString(), false)
      .gt('createdAt', this.dateRangeSelected.startDate.toISOString(), false);
      if(this.reportedBy.value){
        filterString.contains('reportedBy/email' , this.reportedBy.value ? this.reportedBy.value : "")
      }
      if(this.createdBy.value){
        filterString.contains('createdBy/email' , this.createdBy.value ? this.createdBy.value : "")
      }

    this.odataQuery = '?$filter=' + filterString.toString()
    if(filterString.toString() == ""){
      this.odataQuery = "";
    }
    this.ngOnInit();
  }

  // mapClicked($event: MouseEvent) {
  //   this.markers.push({
  //     lat: $event.coords.lat,
  //     lng: $event.coords.lng,
  //     label: 'C',
  //     draggable: true
  //   });
  // }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }

  exportTableAsExcel() {
    let exportArray = [];
      this.siteOperatives.data.forEach(siteOperative => {
        let siteOperativeToExport = {
          "Type": siteOperative.isProxyLogin ? 'Login': 'Logout',
          "First Name": siteOperative.firstName,
          "Last Name": siteOperative.lastName,
          "Email": siteOperative.email,
          "Phone Number": siteOperative.phoneNumber,
          "Sentinel Number": siteOperative.sentinelNumber,
          "Created At": siteOperative.createdAt,
          "Created By": siteOperative.createdBy
        }
        exportArray.push(siteOperativeToExport);
      });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportArray)
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    let currentTimestamp = new Date().toString();
    currentTimestamp = this.datePipe.transform(Date.now(),'yyyyMMdd_mmss');
    XLSX.writeFile(wb,  'logins_' + currentTimestamp + '.xlsx');
  }


}
