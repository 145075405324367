import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthenticationService } from '@app/_services';
import {Role} from "@app/_models";
import {AccountService, PasswordResetRequestModel} from '../../services-generated';
import { MatSnackBar } from '@angular/material';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
  })
export class ResetPasswordComponent implements OnInit {
    passwordResetForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    email = "";
    token = "";
    roles = [
      {value: Role.Supervisor, viewValue: Role.Supervisor},
      {value: Role.SiteOperative, viewValue: Role.SiteOperative},
      {value: Role.Admin, viewValue: Role.Admin}
    ];

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private snackBar: MatSnackBar
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
        this.route.params.forEach(param => {
            this.email = param.email;
            this.token = param.token;
        });
    }

    ngOnInit() {
        this.passwordResetForm = this.formBuilder.group({
            password: ['', [Validators.required]],
            confirmPassword: ['', [Validators.required]]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.passwordResetForm.controls; }

    clearEmail(){
        this.f.email.setValue('');
    }

    keyDownFunction(event) {
        if (event.keyCode === 13) {
            this.onSubmit();
        }
      }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.passwordResetForm.invalid) {
            return;
        }
        if (this.passwordResetForm.get('password').value != this.passwordResetForm.get('confirmPassword').value) {
            return;
        }
        var request: PasswordResetRequestModel = {
            email: this.email,
            password: this.passwordResetForm.get('password').value,
            passwordResetToken: this.token
        }
        this.loading = true;
        this.authenticationService.resetPassword(request)
            .subscribe(loginResponse => {
                    if(loginResponse.isSuccessful){
                        this.router.navigate([this.returnUrl]);
                        this.snackBar.open("Please login using the new password.", "OK", {
                            duration: 3000,
                        });
                    } else{
                        this.error = loginResponse.message;
                        this.snackBar.open(this.error, "OK", {
                            duration: 3000,
                        });
                    }
                    this.loading = false;
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });
    }
}
