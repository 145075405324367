import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AccountService } from './api/account.service';
import { AdministratorService } from './api/administrator.service';
import { CloseCallService } from './api/closeCall.service';
import { DocumentService } from './api/document.service';
import { LocationService } from './api/location.service';
import { LoginQuestionService } from './api/loginQuestion.service';
import { MobileNotificationService } from './api/mobileNotification.service';
import { SiteOperativeService } from './api/siteOperative.service';
import { SupervisorService } from './api/supervisor.service';
import { UserLocationService } from './api/userLocation.service';
import { ReportService } from './api/report.service';
import { CertificateService } from './api/certificate.service';
import { OccurenceLocationIdentifierService } from './api/occurenceLocationIdentifier.service';
import { RuleBookService } from './api/ruleBook.service';
import { VehicleLogService } from './api/vehicleLog.service';
import { TagService } from './api/tag.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AccountService,
    AdministratorService,
    CloseCallService,
    DocumentService,
    LocationService,
    LoginQuestionService,
    MobileNotificationService,
    OccurenceLocationIdentifierService,
    SiteOperativeService,
    SupervisorService,
    UserLocationService,
    ReportService,
    CertificateService,
    RuleBookService,
    VehicleLogService,
    TagService
]
})
export class WorksiteTrackApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: WorksiteTrackApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: WorksiteTrackApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('WorksiteTrackApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
