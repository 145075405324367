/**
 * worksite_track
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

 import { Inject, Injectable, Optional }                      from '@angular/core';
 import { HttpClient, HttpHeaders, HttpParams,
          HttpResponse, HttpEvent }                           from '@angular/common/http';
 import { CustomHttpUrlEncodingCodec }                        from '../encoder';
 
 import { Observable }                                        from 'rxjs';
 
 import { Certificate } from '../model/certificate';
 import { CertificateExecutionResponse } from '../model/certificateExecutionResponse';
 
 import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
 import { Configuration }                                     from '../configuration';
 import { environment } from '../../environments/environment'
 
 
 @Injectable()
 export class CertificateService {
 
     public basePath = environment.basePath;
     public defaultHeaders = new HttpHeaders();
     public configuration = new Configuration();
 
     constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
         if (basePath) {
             this.basePath = basePath;
         }
         if (configuration) {
             this.configuration = configuration;
             this.basePath = basePath || configuration.basePath || this.basePath;
         }
     }
 
     /**
      * @param consumes string[] mime-types
      * @return true: consumes contains 'multipart/form-data', false: otherwise
      */
     private canConsumeForm(consumes: string[]): boolean {
         const form = 'multipart/form-data';
         for (const consume of consumes) {
             if (form === consume) {
                 return true;
             }
         }
         return false;
     }
 
 
     /**
      * 
      * 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiCertificateGet(observe?: 'body', reportProgress?: boolean): Observable<Array<Certificate>>;
     public apiCertificateGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Certificate>>>;
     public apiCertificateGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Certificate>>>;
     public apiCertificateGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json;odata.metadata=minimal;odata.streaming=true',
             'application/json;odata.metadata=minimal;odata.streaming=false',
             'application/json;odata.metadata=minimal',
             'application/json;odata.metadata=full;odata.streaming=true',
             'application/json;odata.metadata=full;odata.streaming=false',
             'application/json;odata.metadata=full',
             'application/json;odata.metadata=none;odata.streaming=true',
             'application/json;odata.metadata=none;odata.streaming=false',
             'application/json;odata.metadata=none',
             'application/json;odata.streaming=true',
             'application/json;odata.streaming=false',
             'application/json',
             'application/xml',
             'application/prs.odatatestxx-odata',
             'text/plain',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<Array<Certificate>>('get',`${this.basePath}/api/Certificate`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param id 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiCertificateIdContentGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiCertificateIdContentGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiCertificateIdContentGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiCertificateIdContentGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (id === null || id === undefined) {
             throw new Error('Required parameter id was null or undefined when calling apiCertificateIdContentGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.get(`${this.basePath}/api/Certificate/${encodeURIComponent(String(id))}/content`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress,
                 responseType: 'blob'
             }
         );
     }
 
     /**
      * 
      * 
      * @param id 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiCertificateIdDelete(id: number, observe?: 'body', reportProgress?: boolean): Observable<CertificateExecutionResponse>;
     public apiCertificateIdDelete(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CertificateExecutionResponse>>;
     public apiCertificateIdDelete(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CertificateExecutionResponse>>;
     public apiCertificateIdDelete(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (id === null || id === undefined) {
             throw new Error('Required parameter id was null or undefined when calling apiCertificateIdDelete.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json;odata.metadata=minimal;odata.streaming=true',
             'application/json;odata.metadata=minimal;odata.streaming=false',
             'application/json;odata.metadata=minimal',
             'application/json;odata.metadata=full;odata.streaming=true',
             'application/json;odata.metadata=full;odata.streaming=false',
             'application/json;odata.metadata=full',
             'application/json;odata.metadata=none;odata.streaming=true',
             'application/json;odata.metadata=none;odata.streaming=false',
             'application/json;odata.metadata=none',
             'application/json;odata.streaming=true',
             'application/json;odata.streaming=false',
             'application/json',
             'application/xml',
             'application/prs.odatatestxx-odata',
             'text/plain',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<CertificateExecutionResponse>('delete',`${this.basePath}/api/Certificate/${encodeURIComponent(String(id))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param id 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiCertificateIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Certificate>;
     public apiCertificateIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Certificate>>;
     public apiCertificateIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Certificate>>;
     public apiCertificateIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (id === null || id === undefined) {
             throw new Error('Required parameter id was null or undefined when calling apiCertificateIdGet.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json;odata.metadata=minimal;odata.streaming=true',
             'application/json;odata.metadata=minimal;odata.streaming=false',
             'application/json;odata.metadata=minimal',
             'application/json;odata.metadata=full;odata.streaming=true',
             'application/json;odata.metadata=full;odata.streaming=false',
             'application/json;odata.metadata=full',
             'application/json;odata.metadata=none;odata.streaming=true',
             'application/json;odata.metadata=none;odata.streaming=false',
             'application/json;odata.metadata=none',
             'application/json;odata.streaming=true',
             'application/json;odata.streaming=false',
             'application/json',
             'application/xml',
             'application/prs.odatatestxx-odata',
             'text/plain',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<Certificate>('get',`${this.basePath}/api/Certificate/${encodeURIComponent(String(id))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param id 
      * @param formFile 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiCertificateIdPutForm(id: number, formFile?: Blob, observe?: 'body', reportProgress?: boolean): Observable<CertificateExecutionResponse>;
     public apiCertificateIdPutForm(id: number, formFile?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CertificateExecutionResponse>>;
     public apiCertificateIdPutForm(id: number, formFile?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CertificateExecutionResponse>>;
     public apiCertificateIdPutForm(id: number, formFile?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (id === null || id === undefined) {
             throw new Error('Required parameter id was null or undefined when calling apiCertificateIdPut.');
         }
 
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json;odata.metadata=minimal;odata.streaming=true',
             'application/json;odata.metadata=minimal;odata.streaming=false',
             'application/json;odata.metadata=minimal',
             'application/json;odata.metadata=full;odata.streaming=true',
             'application/json;odata.metadata=full;odata.streaming=false',
             'application/json;odata.metadata=full',
             'application/json;odata.metadata=none;odata.streaming=true',
             'application/json;odata.metadata=none;odata.streaming=false',
             'application/json;odata.metadata=none',
             'application/json;odata.streaming=true',
             'application/json;odata.streaming=false',
             'application/json',
             'application/xml',
             'application/prs.odatatestxx-odata',
             'text/plain',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'multipart/form-data'
         ];
 
         const canConsumeForm = this.canConsumeForm(consumes);
 
         let formParams: { append(param: string, value: any): void; };
         let useForm = false;
         let convertFormParamsToString = false;
         // use FormData to transmit files using content-type "multipart/form-data"
         // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
         useForm = canConsumeForm;
         if (useForm) {
             formParams = new FormData();
         } else {
             formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         }
 
         if (formFile !== undefined) {
             formParams = formParams.append('formFile', <any>formFile) as any || formParams;
         }
 
         return this.httpClient.request<CertificateExecutionResponse>('put',`${this.basePath}/api/Certificate/${encodeURIComponent(String(id))}`,
             {
                 body: convertFormParamsToString ? formParams.toString() : formParams,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     /**
      * 
      * 
      * @param formFile 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiCertificatePostForm(formFile?: Blob, observe?: 'body', reportProgress?: boolean): Observable<CertificateExecutionResponse>;
     public apiCertificatePostForm(formFile?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CertificateExecutionResponse>>;
     public apiCertificatePostForm(formFile?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CertificateExecutionResponse>>;
     public apiCertificatePostForm(formFile?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json;odata.metadata=minimal;odata.streaming=true',
             'application/json;odata.metadata=minimal;odata.streaming=false',
             'application/json;odata.metadata=minimal',
             'application/json;odata.metadata=full;odata.streaming=true',
             'application/json;odata.metadata=full;odata.streaming=false',
             'application/json;odata.metadata=full',
             'application/json;odata.metadata=none;odata.streaming=true',
             'application/json;odata.metadata=none;odata.streaming=false',
             'application/json;odata.metadata=none',
             'application/json;odata.streaming=true',
             'application/json;odata.streaming=false',
             'application/json',
             'application/xml',
             'application/prs.odatatestxx-odata',
             'text/plain',
             'text/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'multipart/form-data'
         ];
 
         const canConsumeForm = this.canConsumeForm(consumes);
 
         let formParams: { append(param: string, value: any): void; };
         let useForm = false;
         let convertFormParamsToString = false;
         // use FormData to transmit files using content-type "multipart/form-data"
         // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
         useForm = canConsumeForm;
         if (useForm) {
             formParams = new FormData();
         } else {
             formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         }
 
         if (formFile !== undefined) {
             formParams = formParams.append('formFile', <any>formFile) as any || formParams;
         }
 
         return this.httpClient.request<CertificateExecutionResponse>('post',`${this.basePath}/api/Certificate`,
             {
                 body: convertFormParamsToString ? formParams.toString() : formParams,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
 }
 