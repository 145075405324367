import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthenticationService } from '@app/_services';
import {Role} from "@app/_models";
import {AccountService} from '../../services-generated';
import { MatSnackBar } from '@angular/material';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
  })
export class ForgotPasswordComponent implements OnInit {
    passwordResetForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    roles = [
      {value: Role.Supervisor, viewValue: Role.Supervisor},
      {value: Role.SiteOperative, viewValue: Role.SiteOperative},
      {value: Role.Admin, viewValue: Role.Admin}
    ];

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private snackBar: MatSnackBar
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.passwordResetForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.passwordResetForm.controls; }

    clearEmail(){
        this.f.email.setValue('');
    }

    keyDownFunction(event) {
        if (event.keyCode === 13) {
            this.onSubmit();
        }
      }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.passwordResetForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.forgetPassword(this.f.email.value)
            .subscribe(response => {
                    if(response.isSuccessful){
                        this.router.navigate([this.returnUrl]);
                        this.snackBar.open("Please check your email.", "OK", {
                            duration: 3000,
                        });
                    } else{
                        this.error = response.message;
                        this.snackBar.open(this.error, "OK", {
                            duration: 3000,
                        });
                    }
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });
    }
}
